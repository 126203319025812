var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { NgxIndexedDB } from 'ngx-indexed-db/dist/ngx-indexed-db';
import { ApiService } from '../_interceptors/api.service';
import { BehaviorSubject } from 'rxjs/Rx';
import { FileInput } from 'ngx-material-file-input';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviousUrlService } from './previous-url.service';
import { SnackbarService } from './snackbar.service';
import { AuthenticationService } from './authentication.service';
import { LoadingOverlayService } from './loading-overlay.service';
import { environment } from '../../environments/environment';
import { dbVersion } from '../../environments/version';
var IndexedDBService = /** @class */ (function () {
    function IndexedDBService(apiService, loadingOverlayService, authenticationService, activatedRoute, snackbarService, previousUrlService, router) {
        this.apiService = apiService;
        this.loadingOverlayService = loadingOverlayService;
        this.authenticationService = authenticationService;
        this.activatedRoute = activatedRoute;
        this.snackbarService = snackbarService;
        this.previousUrlService = previousUrlService;
        this.router = router;
        this.syncStart = 0;
        this.database = null;
        this.databaseReady = new BehaviorSubject(false);
        this.syncComplete = new BehaviorSubject(false);
        this.tableDataSync = 'data_sync';
        this.tableLanguages = 'languages';
        this.tableCountries = 'countries';
        this.tableClients = 'clients';
        this.tableClientsPush = 'clients_push';
        this.tableClientsAttachments = 'clients_attachments';
        this.tableClientsAttachmentsPush = 'clients_attachments_push';
        this.tableClientsContactPersons = 'clients_contact_persons';
        this.tableClientsContactPersonsPush = 'clients_contact_persons_push';
        this.tableClientsBuildingsMaps = 'clients_buildings_maps';
        this.tableClientsBuildingsMapsPush = 'clients_buildings_maps_push';
        this.tableInspectorsInventory = 'inspectors_inventory';
        this.tableInspectorsInventoryPush = 'inspectors_inventory_push';
        this.tableInspectorsInventorySendToClient = 'inspectors_inventory_send_to_client';
        this.tableInspectorsCalibrations = 'inspectors_calibrations';
        this.tableInspectorsCalibrationsPush = 'inspectors_calibrations_push';
        this.tableInspectorsInspections = 'inspectors_inspections';
        this.tableInspectors = 'inspectors';
        this.tableReportsBuildingParticulars = 'reports_building_particulars';
        this.tableReportsWarehouseCategories = 'reports_warehouse_categories';
        this.tableReportsWarehouseSubcategories = 'reports_warehouse_subcategories';
        this.tableReportsWarehouseActions = 'reports_warehouse_actions';
        this.tableReportsWarehouseTypes = 'reports_warehouse_types';
        this.tableReportsWarehouseQuestionnaire = 'reports_warehouse_questionnaire';
        this.tableReportsWarehouseQuestionnaireAnswers = 'reports_warehouse_questionnaire_answers';
        this.tableReportsLadderCategories = 'reports_ladder_categories';
        this.tableReportsLadderSubcategories = 'reports_ladder_subcategories';
        this.tableReportsLadderMaterials = 'reports_ladder_materials';
        this.tableReportsLadderQuestionnaire = 'reports_ladder_questionnaire';
        this.tableReportsLadderQuestionnaireAnswers = 'reports_ladder_questionnaire_answers';
        this.tableReportsTentCategories = 'reports_tent_categories';
        this.tableReportsTentSubcategories = 'reports_tent_subcategories';
        this.tableReportsTentMaterials = 'reports_tent_materials';
        this.tableReportsTentQuestionnaire = 'reports_tent_questionnaire';
        this.tableReportsTentQuestionnaireAnswers = 'reports_tent_questionnaire_answers';
        this.tableReportsAttachments = 'reports_attachments';
        this.tableReportsAttachmentsPush = 'reports_attachments_push';
        this.tableReports = 'reports';
        this.tableReportsPush = 'reports_push';
        this.tableReportsComments = 'reports_comments';
        this.tableReportsCommentsPush = 'reports_comments_push';
        this.tableReportsBuildings = 'reports_buildings';
        this.tableReportsBuildingsPush = 'reports_buildings_push';
        this.tableReportsBuildingsMaps = 'reports_buildings_maps';
        this.tableReportsBuildingsMapsPush = 'reports_buildings_maps_push';
        this.tableReportsBuildingsReports = 'reports_buildings_reports';
        this.tableReportsBuildingsReportsPush = 'reports_buildings_reports_push';
        this.tableReportsBuildingsReportsPhotos = 'reports_buildings_reports_photos';
        this.tableReportsSendToClient = 'reports_send_to_client';
        this.tableReportsReinspections = 'reports_reinspections';
        this.tableReportsVerifyInspectorStatus = 'reports_verify_inspector_status';
        this.tableSettings = 'settings';
        this.dbVersion = dbVersion;
        this.dbName = 'KVVM';
        this.debug = !environment.production;
        this.tryToCreateDatabase = 10;
        this.databaseCreationTries = 0;
        // can be removed in future
        this.wasUpdated = false;
        this._authenticationService = null;
        this._authenticationServiceForClients = null;
        this.openIndexedDatabase();
    }
    IndexedDBService.prototype.openIndexedDatabase = function () {
        var _this = this;
        this.database = new NgxIndexedDB(this.dbName, this.dbVersion);
        this.database.openDatabase(this.dbVersion, function (evt) { return _this.updateDatabase(evt); })
            .then(function () {
            if (_this.validateDatabase()) {
                _this.databaseReady.next(true);
                if (_this.wasUpdated) {
                    _this.database.delete('data_sync', 'repors_ladder_questionnaire');
                }
                _this.syncStart = new Date().getTime();
                _this.syncAll();
                _this.registerToEvents(_this.apiService);
                // if online, sync data once per hour
                setInterval(function () {
                    if (_this.apiService.isOnline) {
                        _this.syncAll();
                    }
                }, (60 * 60) * 1000);
                // sync clients more frequently
                setInterval(function () {
                    if (_this.apiService.isOnline) {
                        _this.syncClientsData();
                    }
                }, (60 * 5) * 1000);
            }
        }, function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.updateDatabase = function (evt) {
        var db = evt.target.result;
        if (db.objectStoreNames.contains(this.tableDataSync) === false) {
            this.createDataSyncTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableLanguages) === false) {
            this.createLanguagesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableCountries) === false) {
            this.createCountriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClients) === false) {
            this.createClientsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClientsPush) === false) {
            this.createClientsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClientsAttachments) === false) {
            this.createClientsAttachmentsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClientsAttachmentsPush) === false) {
            this.createClientsAttachmentsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClientsContactPersons) === false) {
            this.createClientsContactPersonsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClientsContactPersonsPush) === false) {
            this.createClientsContactPersonsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClientsBuildingsMaps) === false) {
            this.createClientsBuildingMapsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableClientsBuildingsMapsPush) === false) {
            this.createClientsBuildingMapsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableInspectorsInventory) === false) {
            this.createInspectorsInventoryTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableInspectorsInventoryPush) === false) {
            this.createInspectorsInventoryPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableInspectorsInventorySendToClient) === false) {
            this.createInspectorsInventorySendToClientTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableInspectorsCalibrations) === false) {
            this.createInspectorsCalibrationsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableInspectorsCalibrationsPush) === false) {
            this.createInspectorsCalibrationsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableInspectorsInspections) === false) {
            this.createInspectorsInspectionsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableInspectors) === false) {
            this.createInspectorsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingParticulars) === false) {
            this.createReportsBuildingParticularsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsWarehouseCategories) === false) {
            this.createReportsWarehouseCategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsWarehouseSubcategories) === false) {
            this.createReportsWarehouseSubcategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsLadderCategories) === false) {
            this.createReportsLadderCategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsLadderSubcategories) === false) {
            this.createReportsLadderSubcategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsWarehouseActions) === false) {
            this.createReportsWarehouseActionsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsWarehouseTypes) === false) {
            this.createReportsWarehouseTypesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaire) === false) {
            this.createReportsWarehouseQuestionnaireTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaireAnswers) === false) {
            this.createReportsWarehouseQuestionnaireAnswersTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsLadderMaterials) === false) {
            this.createReportsLadderMaterialsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsLadderQuestionnaire) === false) {
            this.createReportsLadderQuestionnaireTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsLadderQuestionnaireAnswers) === false) {
            this.createReportsLadderQuestionnaireAnswersTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsTentCategories) === false) {
            this.createReportsTentCategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsTentSubcategories) === false) {
            this.createReportsTentSubcategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsTentMaterials) === false) {
            this.createReportsTentMaterialsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsTentQuestionnaire) === false) {
            this.createReportsTentQuestionnaireTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsTentQuestionnaireAnswers) === false) {
            this.createReportsTentQuestionnaireAnswersTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsAttachments) === false) {
            this.createReportsAttachmentsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false) {
            this.createReportsAttachmentsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReports) === false) {
            this.createReportsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsPush) === false) {
            this.createReportsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsComments) === false) {
            this.createReportsCommentsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsCommentsPush) === false) {
            this.createReportsCommentsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildings) === false) {
            this.createReportsBuildingsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false) {
            this.createReportsBuildingsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false) {
            this.createReportsBuildingsReportsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false) {
            this.createReportsBuildingsReportsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false) {
            this.createReportsBuildingsReportsPhotosTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsSendToClient) === false) {
            this.createReportsSendToClientTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsReinspections) === false) {
            this.createReportsReinspectionsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsMaps) === false) {
            this.createReportsBuildingsMapsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsMapsPush) === false) {
            this.createReportsBuildingsMapsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsVerifyInspectorStatus) === false) {
            this.createReportsVerifyInspectorStatusTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableSettings) === false) {
            this.createSettingsTable(evt);
        }
        this.updateReportsLadderQuestionnaireTable(evt);
        return true;
    };
    IndexedDBService.prototype.validateDatabase = function () {
        var db = this.database.dbWrapper.db;
        if (this.databaseCreationTries > this.tryToCreateDatabase) {
            this.loadingOverlayService.show();
            this.snackbarService.error('A serious error occurred with the database. Please reload the app.');
            this.database.deleteDatabase(this.dbName);
            return false;
        }
        this.databaseCreationTries++;
        if (db.objectStoreNames.contains(this.tableDataSync) === false ||
            db.objectStoreNames.contains(this.tableLanguages) === false ||
            db.objectStoreNames.contains(this.tableCountries) === false ||
            db.objectStoreNames.contains(this.tableClients) === false ||
            db.objectStoreNames.contains(this.tableClientsPush) === false ||
            db.objectStoreNames.contains(this.tableClientsAttachments) === false ||
            db.objectStoreNames.contains(this.tableClientsAttachmentsPush) === false ||
            db.objectStoreNames.contains(this.tableClientsContactPersons) === false ||
            db.objectStoreNames.contains(this.tableClientsContactPersonsPush) === false ||
            db.objectStoreNames.contains(this.tableClientsBuildingsMaps) === false ||
            db.objectStoreNames.contains(this.tableClientsBuildingsMapsPush) === false ||
            db.objectStoreNames.contains(this.tableInspectorsInventory) === false ||
            db.objectStoreNames.contains(this.tableInspectorsInventoryPush) === false ||
            db.objectStoreNames.contains(this.tableInspectorsInventorySendToClient) === false ||
            db.objectStoreNames.contains(this.tableInspectorsCalibrations) === false ||
            db.objectStoreNames.contains(this.tableInspectorsCalibrationsPush) === false ||
            db.objectStoreNames.contains(this.tableInspectorsInspections) === false ||
            db.objectStoreNames.contains(this.tableInspectors) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingParticulars) === false ||
            db.objectStoreNames.contains(this.tableReportsWarehouseCategories) === false ||
            db.objectStoreNames.contains(this.tableReportsWarehouseSubcategories) === false ||
            db.objectStoreNames.contains(this.tableReportsLadderCategories) === false ||
            db.objectStoreNames.contains(this.tableReportsLadderSubcategories) === false ||
            db.objectStoreNames.contains(this.tableReportsWarehouseActions) === false ||
            db.objectStoreNames.contains(this.tableReportsWarehouseTypes) === false ||
            db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaire) === false ||
            db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaireAnswers) === false ||
            db.objectStoreNames.contains(this.tableReportsLadderMaterials) === false ||
            db.objectStoreNames.contains(this.tableReportsLadderQuestionnaire) === false ||
            db.objectStoreNames.contains(this.tableReportsLadderQuestionnaireAnswers) === false ||
            db.objectStoreNames.contains(this.tableReportsTentCategories) === false ||
            db.objectStoreNames.contains(this.tableReportsTentSubcategories) === false ||
            db.objectStoreNames.contains(this.tableReportsTentMaterials) === false ||
            db.objectStoreNames.contains(this.tableReportsTentQuestionnaire) === false ||
            db.objectStoreNames.contains(this.tableReportsTentQuestionnaireAnswers) === false ||
            db.objectStoreNames.contains(this.tableReportsAttachments) === false ||
            db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false ||
            db.objectStoreNames.contains(this.tableReports) === false ||
            db.objectStoreNames.contains(this.tableReportsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsComments) === false ||
            db.objectStoreNames.contains(this.tableReportsCommentsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildings) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false ||
            db.objectStoreNames.contains(this.tableReportsSendToClient) === false ||
            db.objectStoreNames.contains(this.tableReportsReinspections) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsMaps) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsMapsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsVerifyInspectorStatus) === false ||
            db.objectStoreNames.contains(this.tableSettings) === false) {
            console.log('There\'s an issue with the database, we\'ll try to repair it ...');
            this.database.deleteDatabase(this.dbName);
            this.openIndexedDatabase();
            return false;
        }
        return true;
    };
    IndexedDBService.prototype.registerToEvents = function (apiService) {
        var _this = this;
        apiService.connectionChanged.subscribe(function (online) {
            if (online) {
                _this.databaseReady.subscribe(function (event) {
                    if (event) {
                        console.log('user went online, push any and all changes to the server');
                        _this.apiService.offlineError.emit(false);
                        _this.syncStart = new Date().getTime();
                        _this.startUpdating();
                    }
                    else {
                        console.log('user went online but database is not yet ready');
                    }
                });
            }
        });
    };
    IndexedDBService.prototype.createDataSyncTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableDataSync, {
            keyPath: 'name',
            autoIncrement: false
        });
        object.createIndex('name', 'name', { unique: true });
    };
    IndexedDBService.prototype.createLanguagesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableLanguages, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('language_name', 'language_name', { unique: true });
    };
    IndexedDBService.prototype.createCountriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableCountries, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('country_name', 'country_name', { unique: true });
    };
    IndexedDBService.prototype.createClientsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClients, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('company_name', 'company_name', { unique: false });
    };
    IndexedDBService.prototype.createClientsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClientsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId');
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createClientsAttachmentsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClientsAttachments, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('client_id', 'client_id', { unique: false });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createClientsAttachmentsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClientsAttachmentsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createClientsContactPersonsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClientsContactPersons, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('client_id', 'client_id', { unique: false });
        object.createIndex('firstname', 'firstname', { unique: false });
    };
    IndexedDBService.prototype.createClientsContactPersonsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClientsContactPersonsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId');
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createClientsBuildingMapsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClientsBuildingsMaps, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('client_id', 'client_id', { unique: false });
        object.createIndex('created', 'created', { unique: false });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createClientsBuildingMapsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableClientsBuildingsMapsPush, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createInspectorsInventoryTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInventory, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createInspectorsInventoryPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInventoryPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createInspectorsInventorySendToClientTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInventorySendToClient, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createInspectorsCalibrationsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableInspectorsCalibrations, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('next_calibration_date', 'next_calibration_date', { unique: false });
    };
    IndexedDBService.prototype.createInspectorsCalibrationsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableInspectorsCalibrationsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId');
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createInspectorsInspectionsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInspections, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('next_inspection_date', 'next_inspection_date', { unique: false });
    };
    IndexedDBService.prototype.createInspectorsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableInspectors, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('firstname', 'firstname', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingParticularsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingParticulars, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('building_name', 'building_name', { unique: false });
    };
    IndexedDBService.prototype.createReportsWarehouseCategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseCategories, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('category_name', 'category_name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsWarehouseSubcategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseSubcategories, {
            keyPath: ['id', 'category_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id'], { unique: true });
        object.createIndex('subcategory_name', 'subcategory_name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsLadderCategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderCategories, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('category_name', 'category_name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsLadderSubcategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderSubcategories, {
            keyPath: ['id', 'category_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id'], { unique: true });
        object.createIndex('subcategory_name', 'subcategory_name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsWarehouseActionsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseActions, {
            keyPath: ['id', 'category_id', 'subcategory_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], { unique: true });
        object.createIndex('action_name', 'action_name', { unique: false });
    };
    IndexedDBService.prototype.createReportsWarehouseTypesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseTypes, {
            keyPath: ['id', 'category_id', 'subcategory_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], { unique: true });
        object.createIndex('type_name', 'type_name', { unique: false });
    };
    IndexedDBService.prototype.createReportsWarehouseQuestionnaireTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseQuestionnaire, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: false });
        object.createIndex('name', 'name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsWarehouseQuestionnaireAnswersTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseQuestionnaireAnswers, {
            keyPath: 'report_id',
            autoIncrement: true
        });
        object.createIndex('report_id', 'report_id', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsLadderMaterialsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderMaterials, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('material_name', 'material_name', { unique: false });
    };
    IndexedDBService.prototype.createReportsLadderQuestionnaireTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderQuestionnaire, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: false });
        object.createIndex('name', 'name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.updateReportsLadderQuestionnaireTable = function (evt) {
        evt.currentTarget.result.deleteObjectStore(this.tableReportsLadderQuestionnaire);
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderQuestionnaire, {
            keyPath: ['id', 'category_id', 'subcategory_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], { unique: true });
        object.createIndex('name', 'name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
        this.wasUpdated = true;
    };
    IndexedDBService.prototype.createReportsLadderQuestionnaireAnswersTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderQuestionnaireAnswers, {
            keyPath: ['report_id', 'building_report_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['report_id', 'building_report_id'], { unique: true });
        object.createIndex('building_report_id', 'building_report_id', { unique: true });
    };
    IndexedDBService.prototype.createReportsTentCategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsTentCategories, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('category_name', 'category_name', { unique: false });
        object.createIndex('star_rating', 'star_rating', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsTentSubcategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsTentSubcategories, {
            keyPath: ['id', 'category_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id'], { unique: true });
        object.createIndex('subcategory_name', 'subcategory_name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsTentMaterialsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsTentMaterials, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('material_name', 'material_name', { unique: false });
    };
    IndexedDBService.prototype.createReportsTentQuestionnaireTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsTentQuestionnaire, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: false });
        object.createIndex('name', 'name', { unique: false });
        object.createIndex('sequence', 'sequence', { unique: false });
    };
    IndexedDBService.prototype.createReportsTentQuestionnaireAnswersTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsTentQuestionnaireAnswers, {
            keyPath: ['report_id', 'building_report_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['report_id', 'building_report_id'], { unique: true });
        object.createIndex('building_report_id', 'building_report_id', { unique: true });
    };
    IndexedDBService.prototype.createReportsAttachmentsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachments, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createReportsAttachmentsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachmentsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReports, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('client_id', 'client_id', { unique: false });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createReportsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsCommentsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsComments, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createReportsCommentsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsCommentsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildings, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
        object.createIndex('name', 'name', { unique: false });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsReportsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReports, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
        object.createIndex('location_nr', 'location_nr', { unique: false });
        object.createIndex('created', 'created', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsReportsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsReportsPhotosTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPhotos, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('building_report_id', 'building_report_id', { unique: false });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsSendToClientTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsSendToClient, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createReportsReinspectionsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsReinspections, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
    };
    IndexedDBService.prototype.createReportsBuildingsMapsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsMaps, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
        object.createIndex('client_id', 'client_id', { unique: false });
        object.createIndex('created', 'created', { unique: false });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsMapsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsMapsPush, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsVerifyInspectorStatusTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsVerifyInspectorStatus, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
    };
    IndexedDBService.prototype.createSettingsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableSettings, {
            keyPath: 'name',
            autoIncrement: false
        });
        object.createIndex('name', 'name', { unique: true });
        object.createIndex('value', 'value', { unique: true });
    };
    IndexedDBService.prototype.startUpdating = function () {
        var _this = this;
        if (this._authenticationService !== null) {
            this._authenticationService.unsubscribe();
        }
        this._authenticationService = this.authenticationService.isAuthenticated().subscribe(function (event) {
            if (event) {
                _this.loadingOverlayService.show();
                if (_this.debug) {
                    console.log((new Date().getTime() - _this.syncStart), 'Starting of pushing of data to server ...');
                }
                _this.snackbarService.info('Synchronisatie is gestart, dit een moment duren ...');
                var promises = [];
                promises.push(_this.pushToServer_ClientsCreate().then(function () {
                    return Promise.all([
                        _this.pushToServer_ClientsUpdate().then(function () {
                            return _this.pushToServer_ClientsDelete().then(function () { return true; });
                        }),
                    ]).then(function () {
                        return Promise.all([
                            _this.pushToServer_ClientsAttachmentsCreate().then(function () {
                                return _this.pushToServer_ClientsAttachmentsUpdate().then(function () {
                                    return _this.pushToServer_ClientsAttachmentsDelete().then(function () { return true; });
                                });
                            }),
                            _this.pushToServer_ClientsContactPersonsCreate().then(function () {
                                return _this.pushToServer_ClientsContactPersonsUpdate().then(function () {
                                    return _this.pushToServer_ClientsContactPersonsDelete().then(function () { return true; });
                                });
                            }),
                            _this.pushToServer_InspectorsInventoryCreate().then(function () {
                                return _this.pushToServer_InspectorsInventoryDelete().then(function () {
                                    return _this.pushToServer_InspectorsInventorySendToClient().then(function () { return true; });
                                });
                            }),
                            _this.pushToServer_ReportsCreate().then(function () {
                                return Promise.all([
                                    _this.pushToServer_ReportsUpdate().then(function () {
                                        return _this.pushToServer_ReportsDelete().then(function () { return true; });
                                    })
                                ]).then(function () {
                                    return Promise.all([
                                        _this.pushToServer_ReportsAttachmentsCopy().then(function () {
                                            return _this.pushToServer_ReportsAttachmentsCreate().then(function () {
                                                return _this.pushToServer_ReportsAttachmentsUpdate().then(function () {
                                                    return _this.pushToServer_ReportsAttachmentsDelete().then(function () { return true; });
                                                });
                                            });
                                        }),
                                        _this.pushToServer_ReportsCommentsCreate().then(function () {
                                            return _this.pushToServer_ReportsCommentsUpdate().then(function () {
                                                return _this.pushToServer_ReportsCommentsDelete().then(function () { return true; });
                                            });
                                        }),
                                        _this.pushToServer_ReportsWarehouseQuestionnaires().then(function () { return true; }),
                                        _this.pushToServer_ReportsBuildingsCreate().then(function () {
                                            return _this.pushToServer_ReportsBuildingsUpdate().then(function () {
                                                return _this.pushToServer_ReportsBuildingsDelete().then(function () {
                                                    return _this.pushToServer_ReportsBuildingsReportsCreate().then(function () {
                                                        return _this.pushToServer_ReportsBuildingsReportsUpdate().then(function () {
                                                            return _this.pushToServer_ReportsBuildingsReportsDelete().then(function () {
                                                                return _this.pushToServer_ReportsBuildingsReportsPhotosCreate().then(function () {
                                                                    return _this.pushToServer_ReportsBuildingsReportsPhotosDelete().then(function () {
                                                                        return _this.pushToServer_ReportsSendToClient().then(function () {
                                                                            return _this.pushToServer_ReportsVerifyInspectorStatus().then(function () {
                                                                                return _this.pushToServer_ReportsBuildingsMapsCreate().then(function () {
                                                                                    return _this.pushToServer_ReportsBuildingsMapsUpdate().then(function () {
                                                                                        return _this.pushToServer_ReportsBuildingsMapsDelete().then(function () { return true; });
                                                                                    });
                                                                                });
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        })
                                    ]);
                                });
                            })
                        ]).then(function () {
                            return true;
                        }).catch(function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }));
                promises.push(_this.pushToServer_InspectorsCalibrationsCreate().then(function () {
                    return _this.pushToServer_InspectorsCalibrationsUpdate().then(function () {
                        return _this.pushToServer_InspectorsCalibrationsDelete().then(function () { return true; });
                    });
                }));
                Promise.all(promises).then(function () {
                    if (_this.debug) {
                        console.log((new Date().getTime() - _this.syncStart), 'pushToServer all done, starting sync ....');
                    }
                    _this.syncAll(true);
                    return true;
                }).catch(function (error) {
                    _this.loadingOverlayService.hide();
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
        });
    };
    IndexedDBService.prototype.syncClientsData = function () {
        var _this = this;
        if (this._authenticationServiceForClients !== null) {
            this._authenticationServiceForClients.unsubscribe();
        }
        this._authenticationServiceForClients = this.authenticationService.isAuthenticated().subscribe(function (event) {
            if (event) {
                if (_this.debug) {
                    console.log((new Date().getTime() - _this.syncStart), 'start of syncing of client data ...');
                }
                return Promise.all([
                    _this.syncClients(),
                    _this.syncClientsAttachments(),
                    _this.syncClientsBuildingMaps(),
                    _this.syncClientsContactPersons()
                ]).then(function () {
                    if (_this.debug) {
                        console.log((new Date().getTime() - _this.syncStart), 'sync of client data done');
                    }
                    _this.loadingOverlayService.hide();
                });
            }
        });
    };
    IndexedDBService.prototype.syncAll = function (showMessage) {
        var _this = this;
        if (showMessage === void 0) { showMessage = false; }
        if (this._authenticationService !== null) {
            this._authenticationService.unsubscribe();
        }
        this._authenticationService = this.authenticationService.isAuthenticated().subscribe(function (event) {
            if (event) {
                if (_this.debug) {
                    console.log((new Date().getTime() - _this.syncStart), 'starting of syncing of data ...');
                }
                return Promise.all([
                    _this.syncDeletedItems().then(function () {
                        return Promise.all([
                            _this.syncClosedReports(),
                            _this.syncLanguages(),
                            _this.syncCountries(),
                            _this.syncClients(),
                            _this.syncClientsAttachments(),
                            _this.syncClientsBuildingMaps(),
                            _this.syncClientsContactPersons(),
                            _this.syncInspectorCalibrations(),
                            _this.syncInspectorInventory(),
                            _this.syncInspectorInspections(),
                            _this.syncInspectors(),
                            _this.syncBuildingParticulars(),
                            _this.syncWarehouseCategories(),
                            _this.syncWarehouseSubcategories(),
                            _this.syncWarehouseQuestionnaires(),
                            _this.syncWarehouseQuestionnaireAnswers(),
                            _this.syncTypes(),
                            _this.syncActions(),
                            _this.syncLadderMaterials(),
                            _this.syncLadderCategories(),
                            _this.syncLadderSubcategories(),
                            _this.syncLadderQuestionnaires(),
                            _this.syncLadderQustionnaireAnswers(),
                            _this.syncTentCategories(),
                            _this.syncTentSubcategories(),
                            _this.syncTentQuestionnaires(),
                            _this.syncTentQustionnaireAnswers(),
                            _this.syncReinspectionReports(),
                            _this.syncReports(),
                            _this.syncReportsAttachments(),
                            _this.syncReportsComments(),
                            _this.syncReportsBuildings(),
                            _this.syncReportsBuildingsReports(),
                            _this.syncReportsBuildingsMaps(),
                            _this.syncSettings(),
                        ]);
                    })
                ]).then(function () {
                    if (_this.debug) {
                        console.log((new Date().getTime() - _this.syncStart), 'sync done');
                    }
                    _this.loadingOverlayService.hide();
                    if (showMessage) {
                        _this.snackbarService.info('Synchronisatie voltooid!');
                    }
                    _this.syncComplete.next(true);
                }).catch(function () {
                    _this.loadingOverlayService.hide();
                });
            }
        });
    };
    IndexedDBService.prototype.syncSettings = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_1 = [];
                _this.apiService.get(_this.tableSettings).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    var start_1 = new Date().getTime(), settings_1 = [];
                                    Object.keys(data.data).map(function (key) {
                                        settings_1.push({
                                            name: key,
                                            value: data.data[key]
                                        });
                                    });
                                    promises_1.push(_this.database.updateBulk(_this.tableSettings, settings_1)
                                        .then(function () { return true; })
                                        .catch(function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    }));
                                    return Promise.all(promises_1).then(function () {
                                        if (_this.debug) {
                                            console.log((new Date().getTime() - start_1), 'syncSettings done');
                                        }
                                        resolve();
                                    });
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }
                    else {
                        resolve();
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncDeletedItems = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_2 = [];
                _this.database.getByKey(_this.tableDataSync, 'deleted_items').then(function (user_device_date) {
                    _this.apiService.get('deleted-items/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('deleted-items', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_2 = new Date().getTime(), inspector_inventories_1 = [], inspector_calibrations_1 = [], clients_1 = [], client_attachments_1 = [], client_contact_persons_1 = [], client_building_maps_1 = [], reports_1 = [], reports_attachments_1 = [], reports_buildings_1 = [], reports_buildings_reports_1 = [], reports_buildings_reports_photos_1 = [], reports_buildings_maps_1 = [], countries_1 = [], languages_1 = [], inspectors_1 = [], building_particulars_1 = [], warehouse_categories_1 = [], warehouse_subcategories_1 = [], warehouse_types_1 = [], warehouse_actions_1 = [], warehouse_category_subcategory_type_1 = [], warehouse_category_subcategory_action_1 = [], warehouse_questionnaires_1 = [], ladder_categories_1 = [], ladder_subcategories_1 = [], ladder_materials_1 = [], ladder_questionnaires_1 = [], ladder_category_subcategory_questionnaire_1 = [], tent_categories_1 = [], tent_subcategories_1 = [], tent_questionnaires_1 = [], tent_category_subcategory_questionnaire_1 = [];
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_2), 'delete: started ...');
                                                                }
                                                                data.data.forEach(function (data2) {
                                                                    if (data2.iiid !== null) {
                                                                        inspector_inventories_1.push({ iiid: data2.iiid });
                                                                    }
                                                                    if (data2.icid !== null) {
                                                                        inspector_calibrations_1.push({ icid: data2.icid });
                                                                    }
                                                                    if (data2.cid !== null &&
                                                                        data2.caid === null &&
                                                                        data2.ccpid === null &&
                                                                        data2.cbmid === null) {
                                                                        clients_1.push({ cid: data2.cid });
                                                                    }
                                                                    if (data2.caid !== null &&
                                                                        data2.ccpid === null &&
                                                                        data2.cbmid === null) {
                                                                        client_attachments_1.push({ caid: data2.caid });
                                                                    }
                                                                    if (data2.cid !== null &&
                                                                        data2.caid === null &&
                                                                        data2.ccpid !== null &&
                                                                        data2.cbmid === null) {
                                                                        client_contact_persons_1.push({ cid: data2.cid, ccpid: data2.ccpid });
                                                                    }
                                                                    if (data2.cid !== null &&
                                                                        data2.caid === null &&
                                                                        data2.ccpid === null &&
                                                                        data2.cbmid !== null) {
                                                                        client_building_maps_1.push({ cid: data2.cid, cbmid: data2.cbmid });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid === null &&
                                                                        data2.rbrid === null &&
                                                                        data2.rbrpid === null &&
                                                                        data2.rbmid === null) {
                                                                        reports_1.push({ rid: data2.rid });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid !== null &&
                                                                        data2.rbid === null &&
                                                                        data2.rbrid === null &&
                                                                        data2.rbrpid === null &&
                                                                        data2.rbmid === null) {
                                                                        reports_attachments_1.push({ rid: data2.rid, raid: data2.raid });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid !== null &&
                                                                        data2.rbrid === null &&
                                                                        data2.rbrpid === null &&
                                                                        data2.rbmid === null) {
                                                                        reports_buildings_1.push({ rid: data2.rid, rbid: data2.rbid });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid !== null &&
                                                                        data2.rbrid !== null &&
                                                                        data2.rbrpid === null &&
                                                                        data2.rbmid === null) {
                                                                        reports_buildings_reports_1.push({
                                                                            rid: data2.rid,
                                                                            rbid: data2.rbid,
                                                                            rbrid: data2.rbrid
                                                                        });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid !== null &&
                                                                        data2.rbrid !== null &&
                                                                        data2.rbrpid !== null &&
                                                                        data2.rbmid === null) {
                                                                        reports_buildings_reports_photos_1.push({
                                                                            rid: data2.rid,
                                                                            rbid: data2.rbid,
                                                                            rbrid: data2.rbrid,
                                                                            rbrpid: data2.rbrpid
                                                                        });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid === null &&
                                                                        data2.rbrid === null &&
                                                                        data2.rbrpid === null &&
                                                                        data2.rbmid !== null) {
                                                                        reports_buildings_maps_1.push({
                                                                            rid: data2.rid,
                                                                            rbmid: data2.rbmid
                                                                        });
                                                                    }
                                                                    if (data2.ctid !== null) {
                                                                        countries_1.push({ ctid: data2.ctid });
                                                                    }
                                                                    if (data2.aid !== null) {
                                                                        inspectors_1.push({ aid: data2.aid });
                                                                    }
                                                                    if (data2.lid !== null) {
                                                                        languages_1.push({ lid: data2.lid });
                                                                    }
                                                                    if (data2.bpid !== null) {
                                                                        building_particulars_1.push({ bpid: data2.bpid });
                                                                    }
                                                                    if (data2.lcid !== null &&
                                                                        data2.lsid === null &&
                                                                        data2.lqid === null) {
                                                                        ladder_categories_1.push({ lcid: data2.lcid });
                                                                    }
                                                                    if (data2.lcid !== null &&
                                                                        data2.lsid !== null &&
                                                                        data2.lqid === null) {
                                                                        ladder_subcategories_1.push({ lcid: data2.lcid, lsid: data2.lsid });
                                                                    }
                                                                    if (data2.lmid !== null) {
                                                                        ladder_materials_1.push({ lmid: data2.lmid });
                                                                    }
                                                                    if (data2.lcid === null &&
                                                                        data2.lsid === null &&
                                                                        data2.lqid !== null) {
                                                                        ladder_questionnaires_1.push({ lqid: data2.lqid });
                                                                    }
                                                                    if (data2.lcid !== null &&
                                                                        data2.lsid !== null &&
                                                                        data2.lqid !== null) {
                                                                        ladder_category_subcategory_questionnaire_1.push({
                                                                            lcid: data2.lcid,
                                                                            lsid: data2.lsid,
                                                                            lqid: data2.lqid
                                                                        });
                                                                    }
                                                                    if (data2.wcid === null &&
                                                                        data2.wsid === null &&
                                                                        data2.waid !== null) {
                                                                        warehouse_actions_1.push({ waid: data2.waid });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid === null &&
                                                                        data2.waid === null &&
                                                                        data2.wtid === null) {
                                                                        warehouse_categories_1.push({ wcid: data2.wcid });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid !== null &&
                                                                        data2.waid === null &&
                                                                        data2.wtid === null) {
                                                                        warehouse_subcategories_1.push({ wcid: data2.wcid, wsid: data2.wsid });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid !== null &&
                                                                        data2.waid !== null &&
                                                                        data2.wtid === null) {
                                                                        warehouse_category_subcategory_action_1.push({
                                                                            wcid: data2.wcid,
                                                                            wsid: data2.wsid,
                                                                            waid: data2.waid
                                                                        });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid !== null &&
                                                                        data2.waid === null &&
                                                                        data2.wtid !== null) {
                                                                        warehouse_category_subcategory_type_1.push({
                                                                            wcid: data2.wcid,
                                                                            wsid: data2.wsid,
                                                                            wtid: data2.wtid
                                                                        });
                                                                    }
                                                                    if (data2.wqid !== null) {
                                                                        warehouse_questionnaires_1.push({ wqid: data2.wqid });
                                                                    }
                                                                    if (data2.wcid === null &&
                                                                        data2.wsid === null &&
                                                                        data2.wtid !== null) {
                                                                        warehouse_types_1.push({ wtid: data2.wtid });
                                                                    }
                                                                    if (data2.tcid !== null &&
                                                                        data2.tsid === null &&
                                                                        data2.tqid === null) {
                                                                        tent_categories_1.push({ tcid: data2.tcid });
                                                                    }
                                                                    if (data2.tcid !== null &&
                                                                        data2.tsid !== null &&
                                                                        data2.tqid === null) {
                                                                        tent_subcategories_1.push({ tcid: data2.tcid, tsid: data2.tsid });
                                                                    }
                                                                    if (data2.tcid === null &&
                                                                        data2.tsid === null &&
                                                                        data2.tqid !== null) {
                                                                        tent_questionnaires_1.push({ tqid: data2.tqid });
                                                                    }
                                                                    if (data2.tcid !== null &&
                                                                        data2.tsid !== null &&
                                                                        data2.tqid !== null) {
                                                                        tent_category_subcategory_questionnaire_1.push({
                                                                            tcid: data2.tcid,
                                                                            tsid: data2.tsid,
                                                                            tqid: data2.tqid
                                                                        });
                                                                    }
                                                                });
                                                                if (inspector_inventories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: inspector_inventories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteInspectorInventory(inspector_inventories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: inspector_inventories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (inspector_calibrations_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: inspector_calibrations start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteInspectorCalibration(inspector_calibrations_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: inspector_calibrations done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (clients_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: clients start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteClient(clients_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: clients done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (client_attachments_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: client_attachments start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteClientAttachment(null, client_attachments_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: client_attachments done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (client_building_maps_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: client_building_maps start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteClientBuildingMaps(null, client_building_maps_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: client_building_maps done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (client_contact_persons_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: client_contact_persons start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteClientContactPerson(null, client_contact_persons_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: client_contact_persons done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReport(null, reports_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_attachments_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_attachments start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportAttachment(null, reports_attachments_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_attachments done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_buildings_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_buildings start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportBuilding(null, reports_buildings_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_buildings done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_buildings_reports_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportBuildingReport(reports_buildings_reports_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_buildings_reports_photos_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports_photos start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportBuildingReportPhoto(null, reports_buildings_reports_photos_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports_photos done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_buildings_maps_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_buildings_maps start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportBuildingMap(null, reports_buildings_maps_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_buildings_maps done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (countries_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: countries start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteCountry(countries_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: countries done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (languages_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: languages start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteLanguage(languages_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: languages done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (inspectors_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: inspectors start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteInspector(inspectors_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: inspectors done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (building_particulars_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: building_particulars start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteBuildingParticulars(building_particulars_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: building_particulars done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (ladder_categories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: ladder_categories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteLadderCategory(ladder_categories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: ladder_categories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (ladder_subcategories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: ladder_subcategories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteLadderSubcategory(ladder_subcategories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: ladder_subcategories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (ladder_materials_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: ladder_materials start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteLadderMaterial(ladder_materials_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: ladder_materials done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (ladder_questionnaires_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: ladder_questionnaires start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteLadderQuestionnaire(ladder_questionnaires_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: ladder_questionnaires done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (ladder_category_subcategory_questionnaire_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: ladder_category_subcategory_questionnaire start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteLadderCategorySubcategoryQuestionnaire(ladder_category_subcategory_questionnaire_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: ladder_category_subcategory_questionnaire done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (warehouse_categories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: warehouse_categories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteWarehouseCategory(warehouse_categories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: warehouse_categories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (warehouse_subcategories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: warehouse_subcategories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteWarehouseSubcategory(null, warehouse_subcategories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: warehouse_subcategories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (warehouse_types_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: warehouse_types start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteWarehouseType(warehouse_types_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: warehouse_types done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (warehouse_actions_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: warehouse_actions start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteWarehouseAction(warehouse_actions_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: warehouse_actions done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (warehouse_category_subcategory_type_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: warehouse_category_subcategory_type start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteWarehouseCategorySubcategoryType(warehouse_category_subcategory_type_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: warehouse_category_subcategory_type done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (warehouse_category_subcategory_action_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: warehouse_category_subcategory_action start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteWarehouseCategorySubcategoryAction(warehouse_category_subcategory_action_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: warehouse_category_subcategory_action done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (warehouse_questionnaires_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: warehouse_questionnaires start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteWarehouseQuestionnaire(warehouse_questionnaires_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: warehouse_questionnaires done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (tent_categories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: tent_categories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteTentCategory(tent_categories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: tent_categories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (tent_subcategories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: tent_subcategories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteTentSubcategory(tent_subcategories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: tent_subcategories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (tent_questionnaires_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: tent_questionnaires start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteTentQuestionnaire(tent_questionnaires_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: tent_questionnaires done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (tent_category_subcategory_questionnaire_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: tent_category_subcategory_questionnaire start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteTentCategorySubcategoryQuestionnaire(tent_category_subcategory_questionnaire_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: tent_category_subcategory_questionnaire done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                promises_2.push(new Promise(function (resolve2) {
                                                                    _this.database.update(_this.tableDataSync, {
                                                                        name: 'deleted_items',
                                                                        updated: server_date.data.updated
                                                                    })
                                                                        .then(function () {
                                                                        resolve2();
                                                                    })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }));
                                                                return Promise.all(promises_2).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: finished');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncClosedReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_3 = [], report_ids_1 = [];
                _this.database.getAllFast(_this.tableReports).then(function (reports) {
                    reports.forEach(function (report) {
                        report_ids_1.push(report.id);
                    });
                    if (report_ids_1.length) {
                        _this.apiService.post('reports/get-statuses', { report_ids: report_ids_1 }).then(function (data) {
                            if (typeof data !== 'undefined') {
                                if (typeof data.success !== 'undefined') {
                                    if (data.success === true) {
                                        if (typeof data.data !== 'undefined') {
                                            data.data.forEach(function (report_status) {
                                                if (report_status.status === 'closed') {
                                                    promises_3.push(_this.database.delete(_this.tableReports, parseInt(report_status.id, 10)).then(function () { return true; }));
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    }
                    return Promise.all(promises_3).then(function () { return resolve(); });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncLanguages = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_4 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableLanguages).then(function (user_device_date) {
                    _this.apiService.get('general/languages/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('general/languages', {
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_3 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_3), 'syncLanguages start ...');
                                                                }
                                                                promises_4.push(_this.database.updateBulk(_this.tableLanguages, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_4.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableLanguages,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_4).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_3), 'syncLanguages done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncCountries = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_5 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableCountries).then(function (user_device_date) {
                    _this.apiService.get('general/countries/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('general/countries', {
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_4 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_4), 'syncCountries start ...');
                                                                }
                                                                promises_5.push(_this.database.updateBulk(_this.tableCountries, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_5.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableCountries,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_5).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_4), 'syncCountries done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncClients = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_6 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableClients).then(function (user_device_date) {
                    _this.apiService.get('clients/clients/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('clients/clients', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_5 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_5), 'syncClients start ...');
                                                                }
                                                                promises_6.push(_this.database.updateBulk(_this.tableClients, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_6.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableClients,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_6).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_5), 'syncClients done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncClientsAttachments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_7 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableClientsAttachments).then(function (user_device_date) {
                    _this.apiService.get('clients/clients/attachments/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('clients/clients/attachments', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_6 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_6), 'syncClientsAttachments start ...');
                                                                }
                                                                promises_7.push(_this.database.updateBulk(_this.tableClientsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_7.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableClientsAttachments,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_7).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_6), 'syncClientsAttachments done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncClientsContactPersons = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_8 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableClientsContactPersons).then(function (user_device_date) {
                    _this.apiService.get('clients/clients/contacts-persons/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('clients/clients/contacts-persons', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_7 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_7), 'syncClientsContactPersons start ...');
                                                                }
                                                                promises_8.push(_this.database.updateBulk(_this.tableClientsContactPersons, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_8.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableClientsContactPersons,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_8).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_7), 'syncClientsContactPersons done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncClientsBuildingMaps = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_9 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableClientsBuildingsMaps).then(function (user_device_date) {
                    _this.apiService.get('clients/clients/building-maps/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('clients/clients/building-maps', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_8 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_8), 'syncClientsBuildingMaps start ...');
                                                                }
                                                                promises_9.push(_this.database.updateBulk(_this.tableClientsBuildingsMaps, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_9.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableClientsBuildingsMaps,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_9).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_8), 'syncClientsBuildingMaps done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncInspectorInventory = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_10 = [];
                _this.database.getByKey(_this.tableDataSync, 'inspectors_inventories').then(function (user_device_date) {
                    _this.apiService.get('inventory/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('inventory', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_9 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_9), 'syncInspectorInventory start ...');
                                                                }
                                                                promises_10.push(_this.database.updateBulk(_this.tableInspectorsInventory, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_10.push(_this.database.update(_this.tableDataSync, {
                                                                    name: 'inspectors_inventories',
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_10).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_9), 'syncInspectorInventory done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncInspectorCalibrations = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_11 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableInspectorsCalibrations).then(function (user_device_date) {
                    _this.apiService.get('calibration/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('calibration', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_10 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_10), 'syncInspectorCalibrations start ...');
                                                                }
                                                                promises_11.push(_this.database.updateBulk(_this.tableInspectorsCalibrations, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_11.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableInspectorsCalibrations,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_11).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_10), 'syncInspectorCalibrations done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncInspectorInspections = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_12 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableInspectorsInspections).then(function (user_device_date) {
                    _this.apiService.get('inspections/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('inspections', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_11 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_11), 'syncInspectorInspections start ...');
                                                                }
                                                                promises_12.push(_this.database.updateBulk(_this.tableInspectorsInspections, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_12.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableInspectorsInspections,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_12).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_11), 'syncInspectorInspections done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate start ... ');
            }
            return _this.database.getAllFast(_this.tableClientsPush).then(function (clients) {
                if (typeof clients !== 'undefined') {
                    if (clients.length) {
                        clients.forEach(function (client) {
                            if (client.indexedDBMethod === '1-create') {
                                clientsData.push(client);
                            }
                        });
                        if (clientsData.length) {
                            clientsData.forEach(function (client) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('clients/clients', client, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableClientsPush, client.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            if (data.data.id !== client.id) {
                                                                var newId_1 = parseInt(data.data.id, 10);
                                                                promises2.push(_this.database.delete(_this.tableClients, client.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.add(_this.tableClients, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableClientsPush)
                                                                    .then(function (clients2) {
                                                                    var filtered = [];
                                                                    clients2.forEach(function (client2) {
                                                                        if (client2.id === client.id) {
                                                                            client2.id = newId_1;
                                                                            filtered.push(client2);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableClientsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableClientsAttachmentsPush)
                                                                    .then(function (client_attachments) {
                                                                    var filtered = [];
                                                                    client_attachments.forEach(function (client_attachment) {
                                                                        if (client_attachment.client_id === client.id) {
                                                                            client_attachment.client_id = newId_1;
                                                                            filtered.push(client_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableClientsAttachmentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableClientsAttachments)
                                                                    .then(function (client_attachments) {
                                                                    var filtered = [];
                                                                    client_attachments.forEach(function (client_attachment) {
                                                                        if (client_attachment.client_id === client.id) {
                                                                            client_attachment.client_id = newId_1;
                                                                            filtered.push(client_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableClientsAttachments, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableClientsContactPersons)
                                                                    .then(function (contact_persons) {
                                                                    var filtered = [];
                                                                    contact_persons.forEach(function (contact_person) {
                                                                        if (contact_person.client_id === client.id) {
                                                                            contact_person.client_id = newId_1;
                                                                            filtered.push(contact_person);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableClientsContactPersons, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableClientsContactPersonsPush)
                                                                    .then(function (contact_persons) {
                                                                    var filtered = [];
                                                                    contact_persons.forEach(function (contact_person) {
                                                                        if (contact_person.client_id === client.id) {
                                                                            contact_person.client_id = newId_1;
                                                                            filtered.push(contact_person);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableClientsContactPersonsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableInspectorsInventorySendToClient)
                                                                    .then(function (inspector_inventories) {
                                                                    var filtered = [];
                                                                    inspector_inventories.forEach(function (inspector_inventory) {
                                                                        if (inspector_inventory.client_id === client.id) {
                                                                            inspector_inventory.client_id = newId_1;
                                                                            filtered.push(inspector_inventory);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableInspectorsInventorySendToClient, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReports)
                                                                    .then(function (reports) {
                                                                    var filtered = [];
                                                                    reports.forEach(function (report) {
                                                                        if (report.client_id === client.id) {
                                                                            report.client_id = newId_1;
                                                                            filtered.push(report);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReports, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsPush)
                                                                    .then(function (reports) {
                                                                    var filtered = [];
                                                                    reports.forEach(function (report) {
                                                                        if (report.client_id === client.id) {
                                                                            report.client_id = newId_1;
                                                                            filtered.push(report);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsSendToClient)
                                                                    .then(function (reports_send_to_client) {
                                                                    var filtered = [];
                                                                    reports_send_to_client.forEach(function (report_send_to_client) {
                                                                        if (report_send_to_client.client_id === client.id) {
                                                                            report_send_to_client.client_id = newId_1;
                                                                            filtered.push(report_send_to_client);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsSendToClient, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises2).then(function () {
                                                                    _this.redirectUser({
                                                                        client_id: newId_1,
                                                                        old_client_id: client.id
                                                                    });
                                                                    resolve2();
                                                                });
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableClients, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableClientsPush).then(function (clients) {
                if (typeof clients !== 'undefined') {
                    if (clients.length) {
                        clients.forEach(function (client) {
                            if (client.indexedDBMethod === '2-update') {
                                clientsData.push(client);
                            }
                        });
                        if (clientsData.length) {
                            clientsData.forEach(function (client) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('clients/clients/' + client.id, client, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.update(_this.tableClients, data.data)
                                                            .then(function () {
                                                            return _this.database.delete(_this.tableClientsPush, client.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            });
                                                        }).catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableClientsPush).then(function (clients) {
                if (typeof clients !== 'undefined') {
                    if (clients.length) {
                        clients.forEach(function (client) {
                            if (client.indexedDBMethod === '3-delete') {
                                clientsData.push(client);
                            }
                        });
                        if (clientsData.length) {
                            clientsData.forEach(function (client) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var promises2_1 = [];
                                        _this.apiService.delete('clients/clients/' + client.id, false).then(function () {
                                            promises2_1.push(_this.database.delete(_this.tableClients, client.id)
                                                .then(function () {
                                                return _this.database.delete(_this.tableClientsPush, client.entryId)
                                                    .then(function () { return true; })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2_1).then(function () { return resolve2(); });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsAttachmentsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableClientsAttachmentsPush).then(function (clients_attachments) {
                if (typeof clients_attachments !== 'undefined') {
                    if (clients_attachments.length) {
                        clients_attachments.forEach(function (client_attachment) {
                            if (client_attachment.indexedDBMethod === '1-create') {
                                clientsAttachments.push(client_attachment);
                            }
                        });
                        if (clientsAttachments.length) {
                            clientsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var newAttachment = {
                                            filename: new FileInput(attachment.filename['_files']),
                                            title: attachment.title,
                                            description: attachment.description,
                                            append_warehouse: attachment.append_warehouse,
                                            append_ladder: attachment.append_ladder,
                                            append_tent: attachment.append_tent,
                                        }, promises2_2 = [];
                                        _this.apiService.post('clients/clients/' + attachment.client_id + '/attachments', newAttachment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            promises2_2.push(_this.database.delete(_this.tableClientsAttachmentsPush, attachment.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            if (data.data.id !== attachment.id) {
                                                                promises2_2.push(_this.database.delete(_this.tableClientsAttachments, attachment.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2_2.push(_this.database.add(_this.tableClientsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2_2.push(_this.database.getAllFast(_this.tableClientsAttachmentsPush)
                                                                    .then(function (client_attachments) {
                                                                    var filtered = [];
                                                                    client_attachments.forEach(function (client_attachment) {
                                                                        if (client_attachment.id === attachment.id) {
                                                                            client_attachment.id = parseInt(data.data.id, 10);
                                                                            filtered.push(client_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableClientsAttachmentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                }).catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            else {
                                                                promises2_2.push(_this.database.update(_this.tableClientsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            return Promise.all(promises2_2).then(function () {
                                                                _this.redirectUser({
                                                                    client_id: data.data.client_id,
                                                                    attachment_id: data.data.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsAttachmentsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableClientsAttachmentsPush).then(function (clients_attachments) {
                if (typeof clients_attachments !== 'undefined') {
                    if (clients_attachments.length) {
                        clients_attachments.forEach(function (client_attachment) {
                            if (client_attachment.indexedDBMethod === '2-update') {
                                clientsAttachments.push(client_attachment);
                            }
                        });
                        if (clientsAttachments.length) {
                            clientsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('clients/clients/' + attachment.client_id + '/attachments/' + attachment.id, attachment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.update(_this.tableClientsAttachments, data.data)
                                                            .then(function () {
                                                            return _this.database.delete(_this.tableClientsAttachmentsPush, attachment.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            });
                                                        }).catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsAttachmentsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableClientsAttachmentsPush).then(function (clients_attachments) {
                if (typeof clients_attachments !== 'undefined') {
                    if (clients_attachments.length) {
                        clients_attachments.forEach(function (client_attachment) {
                            if (client_attachment.indexedDBMethod === '3-delete') {
                                clientsAttachments.push(client_attachment);
                            }
                        });
                        if (clientsAttachments.length) {
                            clientsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('clients/clients/' + attachment.client_id + '/attachments/' + attachment.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableClientsAttachments, attachment.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableClientsAttachmentsPush, attachment.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsContactPersonsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsContactPersons = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableClientsContactPersonsPush).then(function (clients_contact_persons) {
                if (typeof clients_contact_persons !== 'undefined') {
                    if (clients_contact_persons.length) {
                        clients_contact_persons.forEach(function (client_contact_person) {
                            if (client_contact_person.indexedDBMethod === '1-create') {
                                clientsContactPersons.push(client_contact_person);
                            }
                        });
                        if (clientsContactPersons.length) {
                            clientsContactPersons.forEach(function (clientContactPerson) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('clients/clients/' + clientContactPerson.client_id + '/contacts-persons', clientContactPerson, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableClientsContactPersonsPush, clientContactPerson.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            if (data.data.id !== clientContactPerson.id) {
                                                                promises2.push(_this.database.delete(_this.tableClientsContactPersons, clientContactPerson.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.add(_this.tableClientsContactPersons, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableClientsContactPersonsPush)
                                                                    .then(function (contact_persons) {
                                                                    var filtered = [];
                                                                    contact_persons.forEach(function (contact_person) {
                                                                        if (contact_person.id === clientContactPerson.id) {
                                                                            contact_person.id = parseInt(data.data.id, 10);
                                                                            filtered.push(contact_person);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableClientsContactPersonsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                }).catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableInspectorsInventorySendToClient)
                                                                    .then(function (inventories_send_to_client) {
                                                                    var filtered = [];
                                                                    inventories_send_to_client.forEach(function (inventory_send_to_client) {
                                                                        var updated = false;
                                                                        if (inventory_send_to_client.contact_persons_ids.length) {
                                                                            inventory_send_to_client.contact_persons_ids.forEach(function (contact_person, index) {
                                                                                if (contact_person === clientContactPerson.id) {
                                                                                    inventory_send_to_client.contact_persons_ids[index] = data.data.id;
                                                                                    updated = true;
                                                                                }
                                                                            });
                                                                            if (updated) {
                                                                                filtered.push(inventory_send_to_client);
                                                                            }
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableInspectorsInventorySendToClient, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableClientsContactPersons, clientContactPerson)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                _this.redirectUser({
                                                                    client_id: data.data.client_id,
                                                                    contact_person_id: data.data.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsContactPersonsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsContactPersons = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableClientsContactPersonsPush).then(function (clients_contact_persons) {
                if (typeof clients_contact_persons !== 'undefined') {
                    if (clients_contact_persons.length) {
                        clients_contact_persons.forEach(function (client_contact_person) {
                            if (client_contact_person.indexedDBMethod === '2-update') {
                                clientsContactPersons.push(client_contact_person);
                            }
                        });
                        if (clientsContactPersons.length) {
                            clientsContactPersons.forEach(function (clientContactPerson) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('clients/clients/' + clientContactPerson.client_id + '/contacts-persons/' + clientContactPerson.id, clientContactPerson, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableClientsContactPersonsPush, clientContactPerson.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.update(_this.tableClientsContactPersons, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ClientsContactPersonsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var clientsContactPersons = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableClientsContactPersonsPush).then(function (clients_contact_persons) {
                if (typeof clients_contact_persons !== 'undefined') {
                    if (clients_contact_persons.length) {
                        clients_contact_persons.forEach(function (client_contact_person) {
                            if (client_contact_person.indexedDBMethod === '3-delete') {
                                clientsContactPersons.push(client_contact_person);
                            }
                        });
                        if (clientsContactPersons.length) {
                            clientsContactPersons.forEach(function (clientContactPerson) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('clients/clients/' + clientContactPerson.client_id + '/contacts-persons/' + clientContactPerson.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableClientsContactPersonsPush, clientContactPerson.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableClientsContactPersons, clientContactPerson.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_InspectorsCalibrationsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var calibrationsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableInspectorsCalibrationsPush).then(function (inspectors_calibrations) {
                if (typeof inspectors_calibrations !== 'undefined') {
                    if (inspectors_calibrations.length) {
                        inspectors_calibrations.forEach(function (inspector_calibration) {
                            if (inspector_calibration.indexedDBMethod === '1-create') {
                                calibrationsData.push(inspector_calibration);
                            }
                        });
                        if (calibrationsData.length) {
                            calibrationsData.forEach(function (calibration) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var pushCalibration = __assign({}, calibration);
                                        pushCalibration['date_calibrated'] = moment(calibration['date_calibrated']).format('YYYY-MM-DD');
                                        pushCalibration['next_calibration_date'] = moment(calibration['next_calibration_date']).format('YYYY-MM-DD');
                                        _this.apiService.post('calibration', pushCalibration, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableInspectorsCalibrationsPush, calibration.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            if (data.data.id !== calibration['id']) {
                                                                promises2.push(_this.database.delete(_this.tableInspectorsCalibrations, calibration.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.add(_this.tableInspectorsCalibrations, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableInspectorsCalibrationsPush)
                                                                    .then(function (inspector_calibrations) {
                                                                    var filtered = [];
                                                                    inspector_calibrations.forEach(function (inspector_calibration) {
                                                                        if (inspector_calibration.id === calibration.id) {
                                                                            inspector_calibration.id = parseInt(data.data.id, 10);
                                                                            filtered.push(inspector_calibration);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableInspectorsCalibrationsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableInspectorsCalibrations, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                _this.redirectUser({
                                                                    calibration_id: data.data.id,
                                                                    old_calibration_id: calibration.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_InspectorsCalibrationsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var calibrationsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableInspectorsCalibrationsPush).then(function (inspectors_calibrations) {
                if (typeof inspectors_calibrations !== 'undefined') {
                    if (inspectors_calibrations.length) {
                        inspectors_calibrations.forEach(function (inspector_calibration) {
                            if (inspector_calibration.indexedDBMethod === '2-update') {
                                calibrationsData.push(inspector_calibration);
                            }
                        });
                        if (calibrationsData.length) {
                            calibrationsData.forEach(function (calibration) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var pushCalibration = __assign({}, calibration);
                                        pushCalibration['date_calibrated'] = moment(calibration['date_calibrated']).format('YYYY-MM-DD');
                                        pushCalibration['next_calibration_date'] = moment(calibration['next_calibration_date']).format('YYYY-MM-DD');
                                        _this.apiService.post('calibration/' + calibration.id, pushCalibration, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableInspectorsCalibrationsPush, calibration.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.update(_this.tableInspectorsCalibrations, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }
                                            else {
                                                resolve();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                start = new Date().getTime();
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_InspectorsCalibrationsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var calibrationsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableInspectorsCalibrationsPush).then(function (inspectors_calibrations) {
                if (typeof inspectors_calibrations !== 'undefined') {
                    if (inspectors_calibrations.length) {
                        inspectors_calibrations.forEach(function (inspector_calibration) {
                            if (inspector_calibration.indexedDBMethod === '3-delete') {
                                calibrationsData.push(inspector_calibration);
                            }
                        });
                        if (calibrationsData.length) {
                            calibrationsData.forEach(function (calibration) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('calibration/' + calibration.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableInspectorsCalibrationsPush, calibration.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableInspectorsCalibrations, calibration.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_InspectorsInventoryCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var inspectorInventory = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate start ...');
            }
            return _this.database.getAllFast(_this.tableInspectorsInventoryPush).then(function (inspectors_inventories) {
                if (typeof inspectors_inventories !== 'undefined') {
                    if (inspectors_inventories.length) {
                        inspectors_inventories.forEach(function (inspector_inventory) {
                            if (inspector_inventory.indexedDBMethod === '1-create') {
                                inspectorInventory.push(inspector_inventory);
                            }
                        });
                        if (inspectorInventory.length) {
                            inspectorInventory.forEach(function (inventory) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var newInventory = {
                                            user_id: inventory.user_id,
                                            filename: new FileInput(inventory.filename['_files'])
                                        };
                                        _this.apiService.post('inventory', newInventory, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableInspectorsInventoryPush, inventory.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            if (data.data.id !== inventory.id) {
                                                                promises2.push(_this.database.delete(_this.tableInspectorsInventory, inventory.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.add(_this.tableInspectorsInventory, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableInspectorsInventoryPush).then(function (inspector_inventories) {
                                                                    var filtered = [];
                                                                    inspector_inventories.forEach(function (inspector_inventory) {
                                                                        if (inspector_inventory.id === inventory.id) {
                                                                            inspector_inventory.id = parseInt(data.data.id, 10);
                                                                            filtered.push(inspector_inventory);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableInspectorsInventoryPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableInspectorsInventorySendToClient)
                                                                    .then(function (inventories_send_to_client) {
                                                                    var filtered = [];
                                                                    inventories_send_to_client.forEach(function (inventory_send_to_client) {
                                                                        if (inventory_send_to_client.id === inventory.id) {
                                                                            inventory_send_to_client.id = parseInt(data.data.id, 10);
                                                                            filtered.push(inventory_send_to_client);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableInspectorsInventorySendToClient, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableInspectorsInventory, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                _this.redirectUser({
                                                                    inventory_id: data.data.id,
                                                                    old_inventory_id: inventory.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_InspectorsInventoryDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var inspectorInventory = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete start ...');
            }
            return _this.database.getAllFast(_this.tableInspectorsInventoryPush).then(function (inspectors_inventories) {
                if (typeof inspectors_inventories !== 'undefined') {
                    if (inspectors_inventories.length) {
                        inspectors_inventories.forEach(function (inspector_inventory) {
                            if (inspector_inventory.indexedDBMethod === '3-delete') {
                                inspectorInventory.push(inspector_inventory);
                            }
                        });
                        if (inspectorInventory.length) {
                            inspectorInventory.forEach(function (inventory) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('inventory/' + inventory.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableInspectorsInventory, inventory.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableInspectorsInventoryPush, inventory.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_InspectorsInventorySendToClient = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var inspectorInventorySendToClient = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventorySendToClient start ...');
            }
            return _this.database.getAllFast(_this.tableInspectorsInventorySendToClient).then(function (inspectors_inventories) {
                if (typeof inspectors_inventories !== 'undefined') {
                    if (inspectors_inventories.length) {
                        inspectors_inventories.forEach(function (inspector_inventory) {
                            if (inspector_inventory.indexedDBMethod === '1-create') {
                                inspectorInventorySendToClient.push(inspector_inventory);
                            }
                        });
                        if (inspectorInventorySendToClient.length) {
                            inspectorInventorySendToClient.forEach(function (inventory) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('inventory/' + inventory.id + '/send-to-client', inventory, false)
                                            .then(function () {
                                            return _this.database.delete(_this.tableInspectorsInventorySendToClient, inventory.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        })
                                            .catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventorySendToClient done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.syncInspectors = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_13 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableInspectors).then(function (user_device_date) {
                    _this.apiService.get('reports/inspectors/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/inspectors', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_12 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_12), 'syncInspectors start ...');
                                                                }
                                                                promises_13.push(_this.database.updateBulk(_this.tableInspectors, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_13.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableInspectors,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_13).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_12), 'syncInspectors done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncBuildingParticulars = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_14 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsBuildingParticulars).then(function (user_device_date) {
                    _this.apiService.get('reports/building-particulars/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/building-particulars', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_13 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_13), 'syncBuildingParticulars start ...');
                                                                }
                                                                promises_14.push(_this.database.updateBulk(_this.tableReportsBuildingParticulars, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_14.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsBuildingParticulars,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_14).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_13), 'syncBuildingParticulars done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncWarehouseCategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_15 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsWarehouseCategories).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/elements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/elements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_14 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_14), 'syncWarehouseCategories start ...');
                                                                }
                                                                promises_15.push(_this.database.updateBulk(_this.tableReportsWarehouseCategories, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_15.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsWarehouseCategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_15).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_14), 'syncWarehouseCategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncWarehouseSubcategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_16 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsWarehouseSubcategories).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/elements/subelements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/elements/subelements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_15 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_15), 'syncWarehouseSubcategories start ...');
                                                                }
                                                                promises_16.push(_this.database.updateBulk(_this.tableReportsWarehouseSubcategories, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_16.push(_this.database.getAllFast(_this.tableReportsWarehouseTypes)
                                                                    .then(function (types) {
                                                                    if (typeof types !== 'undefined') {
                                                                        if (types.length) {
                                                                            var filtered_1 = [];
                                                                            types.forEach(function (type) {
                                                                                data.data.forEach(function (server_type) {
                                                                                    if (type.category_id === server_type.category_id && type.subcategory_id === server_type.id) {
                                                                                        type.amounts = server_type.amounts;
                                                                                        filtered_1.push(type);
                                                                                    }
                                                                                });
                                                                            });
                                                                            if (filtered_1.length) {
                                                                                return _this.database.updateBulk(_this.tableReportsWarehouseTypes, filtered_1)
                                                                                    .then(function () { return true; })
                                                                                    .catch(function (error) {
                                                                                    if (_this.debug) {
                                                                                        console.log(error);
                                                                                    }
                                                                                    throw new Error(error);
                                                                                });
                                                                            }
                                                                        }
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_16.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsWarehouseSubcategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_16).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_15), 'syncWarehouseSubcategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncTypes = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_17 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsWarehouseTypes).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/elements/subelements/types/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/elements/subelements/types', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_16 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_16), 'syncTypes start ...');
                                                                }
                                                                var filtered_2 = [];
                                                                data.data.forEach(function (type) {
                                                                    type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                                    filtered_2.push(type);
                                                                });
                                                                if (filtered_2.length) {
                                                                    promises_17.push(_this.database.updateBulk(_this.tableReportsWarehouseTypes, filtered_2)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    }));
                                                                }
                                                                promises_17.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsWarehouseTypes,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_17).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_16), 'syncTypes done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncActions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_18 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsWarehouseActions).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/elements/subelements/actions/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/elements/subelements/actions', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_17 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_17), 'syncActions start ...');
                                                                }
                                                                var filtered_3 = [];
                                                                data.data.forEach(function (action) {
                                                                    action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                                    filtered_3.push(action);
                                                                });
                                                                if (filtered_3.length) {
                                                                    promises_18.push(_this.database.updateBulk(_this.tableReportsWarehouseActions, filtered_3)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    }));
                                                                }
                                                                promises_18.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsWarehouseActions,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_18).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_17), 'syncActions done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncWarehouseQuestionnaires = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_19 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsWarehouseQuestionnaire).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/questionnaire/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/questionnaire', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_18 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_18), 'syncWarehouseQuestionnaires start ...');
                                                                }
                                                                promises_19.push(_this.database.updateBulk(_this.tableReportsWarehouseQuestionnaire, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_19.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsWarehouseQuestionnaire,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_19).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_18), 'syncWarehouseQuestionnaires done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncWarehouseQuestionnaireAnswers = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_20 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsWarehouseQuestionnaireAnswers).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/questionnaire/answers/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/questionnaire/answers', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_19 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_19), 'syncWarehouseQuestionnaireAnswers start ...');
                                                                }
                                                                promises_20.push(_this.database.updateBulk(_this.tableReportsWarehouseQuestionnaireAnswers, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_20.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsWarehouseQuestionnaireAnswers,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_20).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_19), 'syncWarehouseQuestionnaireAnswers done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncLadderCategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_21 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsLadderCategories).then(function (user_device_date) {
                    _this.apiService.get('reports/ladders/elements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/ladders/elements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_20 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_20), 'syncLadderCategories start ...');
                                                                }
                                                                promises_21.push(_this.database.updateBulk(_this.tableReportsLadderCategories, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_21.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsLadderCategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_21).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_20), 'syncLadderCategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncLadderSubcategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_22 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsLadderSubcategories).then(function (user_device_date) {
                    _this.apiService.get('reports/ladders/elements/subelements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/ladders/elements/subelements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_21 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_21), 'syncLadderSubcategories start ...');
                                                                }
                                                                promises_22.push(_this.database.updateBulk(_this.tableReportsLadderSubcategories, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_22.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsLadderSubcategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_22).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_21), 'syncLadderSubcategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncLadderQuestionnaires = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_23 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsLadderQuestionnaire).then(function (user_device_date) {
                    _this.apiService.get('reports/ladders/elements/subelements/questionnaires/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/ladders/elements/subelements/questionnaires', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_22 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_22), 'syncLadderQuestionnaires start ...');
                                                                }
                                                                promises_23.push(_this.database.updateBulk(_this.tableReportsLadderQuestionnaire, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_23.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsLadderQuestionnaire,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_23).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_22), 'syncLadderQuestionnaires done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncLadderQustionnaireAnswers = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_24 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsLadderQuestionnaireAnswers).then(function (user_device_date) {
                    _this.apiService.get('reports/ladders/elements/subelements/questionnaires/answers/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/ladders/elements/subelements/questionnaires/answers', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_23 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_23), 'syncLadderQustionnaireAnswers start ...');
                                                                }
                                                                promises_24.push(_this.database.updateBulk(_this.tableReportsLadderQuestionnaireAnswers, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_24.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsLadderQuestionnaireAnswers,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_24).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_23), 'syncLadderQustionnaireAnswers done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncLadderMaterials = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_25 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsLadderMaterials).then(function (user_device_date) {
                    _this.apiService.get('reports/ladders/materials/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/ladders/materials', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_24 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_24), 'syncLadderMaterials start ...');
                                                                }
                                                                var filtered_4 = [];
                                                                data.data.forEach(function (material) {
                                                                    material.material_name = material.material_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                                    filtered_4.push(material);
                                                                });
                                                                if (filtered_4.length) {
                                                                    promises_25.push(_this.database.updateBulk(_this.tableReportsLadderMaterials, filtered_4)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    }));
                                                                }
                                                                promises_25.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsLadderMaterials,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_25).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_24), 'syncLadderMaterials done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncTentCategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_26 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsTentCategories).then(function (user_device_date) {
                    _this.apiService.get('reports/tent/elements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/tent/elements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_25 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_25), 'syncTentCategories start ...');
                                                                }
                                                                promises_26.push(_this.database.updateBulk(_this.tableReportsTentCategories, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_26.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsTentCategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_26).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_25), 'syncTentCategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncTentSubcategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_27 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsTentSubcategories).then(function (user_device_date) {
                    _this.apiService.get('reports/tent/elements/subelements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/tent/elements/subelements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_26 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_26), 'syncTentSubcategories start ...');
                                                                }
                                                                promises_27.push(_this.database.updateBulk(_this.tableReportsTentSubcategories, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_27.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsTentSubcategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_27).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_26), 'syncTentSubcategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncTentQuestionnaires = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_28 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsTentQuestionnaire).then(function (user_device_date) {
                    _this.apiService.get('reports/tent/elements/subelements/questionnaires/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/tent/elements/subelements/questionnaires', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_27 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_27), 'syncTentQuestionnaires start ...');
                                                                }
                                                                promises_28.push(_this.database.updateBulk(_this.tableReportsTentQuestionnaire, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_28.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsTentQuestionnaire,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_28).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_27), 'syncTentQuestionnaires done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncTentQustionnaireAnswers = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_29 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsTentQuestionnaireAnswers).then(function (user_device_date) {
                    _this.apiService.get('reports/tent/elements/subelements/questionnaires/answers/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/tent/elements/subelements/questionnaires/answers', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_28 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_28), 'syncTentQustionnaireAnswers start ...');
                                                                }
                                                                promises_29.push(_this.database.updateBulk(_this.tableReportsTentQuestionnaireAnswers, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_29.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsTentQuestionnaireAnswers,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_29).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_28), 'syncTentQustionnaireAnswers done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_30 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReports).then(function (user_device_date) {
                    _this.apiService.get('reports/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_29 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_29), 'syncReports start ...');
                                                                }
                                                                promises_30.push(_this.database.updateBulk(_this.tableReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_30.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReports,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_30).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_29), 'syncReports done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReinspectionReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                return _this.database.clear(_this.tableReportsReinspections).then(function () {
                    _this.apiService.get('reports/reinspections', null, false).then(function (data) {
                        if (typeof data !== 'undefined') {
                            if (typeof data.success !== 'undefined') {
                                if (data.success === true) {
                                    if (typeof data.data !== 'undefined') {
                                        return _this.database.updateBulk(_this.tableReportsReinspections, data.data)
                                            .then(function () { return resolve(); })
                                            .catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            resolve();
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        resolve();
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    resolve();
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsAttachments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_31 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsAttachments).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/attachments/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/attachments', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_30 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_30), 'syncReportsAttachments start ...');
                                                                }
                                                                promises_31.push(_this.database.updateBulk(_this.tableReportsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_31.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsAttachments,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_31).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_30), 'syncReportsAttachments done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsComments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_32 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsComments).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/comments/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/comments', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_31 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_31), 'syncReportsComments start ...');
                                                                }
                                                                promises_32.push(_this.database.updateBulk(_this.tableReportsComments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_32.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsComments,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_32).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_31), 'syncReportsComments done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsBuildings = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_33 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsBuildings).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/buildings/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/buildings', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_32 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_32), 'syncReportsBuildings start ...');
                                                                }
                                                                promises_33.push(_this.database.updateBulk(_this.tableReportsBuildings, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_33.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsBuildings,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_33).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_32), 'syncReportsBuildings done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsBuildingsReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_34 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsBuildingsReports).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/buildings/reports/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/buildings/reports', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_33 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_33), 'syncReportsBuildingsReports start ...');
                                                                }
                                                                promises_34.push(_this.database.updateBulk(_this.tableReportsBuildingsReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_34.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsBuildingsReports,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_34).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_33), 'syncReportsBuildingsReports done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsBuildingsMaps = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_35 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsBuildingsMaps).then(function (user_device_date) {
                    _this.apiService.get('reports/warehouse/buildings-maps/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/warehouse/buildings-maps', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_34 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_34), 'syncReportsBuildingsMaps start ...');
                                                                }
                                                                promises_35.push(_this.database.updateBulk(_this.tableReportsBuildingsMaps, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises_35.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsBuildingsMaps,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                return Promise.all(promises_35).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_34), 'syncReportsBuildingsMaps done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsPush).then(function (reports) {
                if (typeof reports !== 'undefined') {
                    if (reports.length) {
                        reports.forEach(function (report) {
                            if (report.indexedDBMethod === '1-create') {
                                collectionData.push(report);
                            }
                        });
                        if (collectionData.length) {
                            collectionData.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + report.type, report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableReportsPush, report.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            if (data.data.id !== report.id) {
                                                                var newId_2 = parseInt(data.data.id, 10);
                                                                promises2.push(_this.database.delete(_this.tableReports, report.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.add(_this.tableReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsPush)
                                                                    .then(function (reports2) {
                                                                    var filtered = [];
                                                                    reports2.forEach(function (report2) {
                                                                        if (report2.id === report.id) {
                                                                            report2.id = newId_2;
                                                                            filtered.push(report2);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsAttachments)
                                                                    .then(function (report_attachments) {
                                                                    var filtered = [];
                                                                    report_attachments.forEach(function (report_attachment) {
                                                                        if (report_attachment.report_id === report.id) {
                                                                            report_attachment.report_id = newId_2;
                                                                            filtered.push(report_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsAttachments, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                                                                    .then(function (report_attachments) {
                                                                    var filtered = [];
                                                                    report_attachments.forEach(function (report_attachment) {
                                                                        if (report_attachment.report_id === report.id) {
                                                                            report_attachment.report_id = newId_2;
                                                                            filtered.push(report_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsAttachmentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsComments)
                                                                    .then(function (report_comments) {
                                                                    var filtered = [];
                                                                    report_comments.forEach(function (report_comment) {
                                                                        if (report_comment.report_id === report.id) {
                                                                            report_comment.report_id = newId_2;
                                                                            filtered.push(report_comment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsComments, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                                                                    .then(function (report_comments) {
                                                                    var filtered = [];
                                                                    report_comments.forEach(function (report_comment) {
                                                                        if (report_comment.report_id === report.id) {
                                                                            report_comment.report_id = newId_2;
                                                                            filtered.push(report_comment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsCommentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildings)
                                                                    .then(function (report_buildings) {
                                                                    var filtered = [];
                                                                    report_buildings.forEach(function (report_building) {
                                                                        if (report_building.report_id === report.id) {
                                                                            report_building.report_id = newId_2;
                                                                            filtered.push(report_building);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildings, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                                                                    .then(function (report_buildings) {
                                                                    var filtered = [];
                                                                    report_buildings.forEach(function (report_building) {
                                                                        if (report_building.report_id === report.id) {
                                                                            report_building.report_id = newId_2;
                                                                            filtered.push(report_building);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                                                                    .then(function (report_buildings_reports) {
                                                                    var filtered = [];
                                                                    report_buildings_reports.forEach(function (report_building_report) {
                                                                        if (report_building_report.report_id === report.id) {
                                                                            report_building_report.report_id = newId_2;
                                                                            filtered.push(report_building_report);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsReports, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                                                                    .then(function (report_buildings_reports) {
                                                                    var filtered = [];
                                                                    report_buildings_reports.forEach(function (report_building_report) {
                                                                        if (report_building_report.report_id === report.id) {
                                                                            report_building_report.report_id = newId_2;
                                                                            filtered.push(report_building_report);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsReportsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                                                                    .then(function (report_buildings_reports_photos) {
                                                                    var filtered = [];
                                                                    report_buildings_reports_photos.forEach(function (report_building_report_photo) {
                                                                        if (report_building_report_photo.report_id === report.id) {
                                                                            report_building_report_photo.report_id = newId_2;
                                                                            filtered.push(report_building_report_photo);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsReportsPhotos, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsSendToClient)
                                                                    .then(function (reports_send_to_client) {
                                                                    var filtered = [];
                                                                    reports_send_to_client.forEach(function (report_send_to_client) {
                                                                        if (report_send_to_client.report_id === report.id) {
                                                                            report_send_to_client.report_id = newId_2;
                                                                            filtered.push(report_send_to_client);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsSendToClient, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsTentQuestionnaireAnswers)
                                                                    .then(function (reports_tent_questionnaire_answers) {
                                                                    var filtered = [], forDeletion = [];
                                                                    reports_tent_questionnaire_answers.forEach(function (report_tent_questionnaire_answer) {
                                                                        if (report_tent_questionnaire_answer.report_id === report.id) {
                                                                            forDeletion.push({
                                                                                report_id: report_tent_questionnaire_answer.report_id,
                                                                                building_report_id: report_tent_questionnaire_answer.building_report_id
                                                                            });
                                                                            report_tent_questionnaire_answer.report_id = newId_2;
                                                                            filtered.push(report_tent_questionnaire_answer);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.deleteBulk(_this.tableReportsTentQuestionnaireAnswers, forDeletion.map(function (ids) {
                                                                            return [ids.report_id, ids.building_report_id];
                                                                        }))
                                                                            .then(function () {
                                                                            return _this.database.updateBulk(_this.tableReportsTentQuestionnaireAnswers, filtered)
                                                                                .then(function () { return true; })
                                                                                .catch(function (error) {
                                                                                if (_this.debug) {
                                                                                    console.log(error);
                                                                                }
                                                                                throw new Error(error);
                                                                            });
                                                                        })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsWarehouseQuestionnaireAnswers)
                                                                    .then(function (reports_warehouse_questionnaire_answers) {
                                                                    var filtered = [];
                                                                    reports_warehouse_questionnaire_answers.forEach(function (report_warehouse_questionnaire_answer) {
                                                                        if (report_warehouse_questionnaire_answer.report_id === report.id) {
                                                                            report_warehouse_questionnaire_answer.report_id = newId_2;
                                                                            filtered.push(report_warehouse_questionnaire_answer);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.delete(_this.tableReportsWarehouseQuestionnaireAnswers, report.id)
                                                                            .then(function () {
                                                                            return _this.database.updateBulk(_this.tableReportsWarehouseQuestionnaireAnswers, filtered)
                                                                                .then(function () { return true; })
                                                                                .catch(function (error) {
                                                                                if (_this.debug) {
                                                                                    console.log(error);
                                                                                }
                                                                                throw new Error(error);
                                                                            });
                                                                        })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                _this.redirectUser({
                                                                    report_id: data.data.id,
                                                                    old_report_id: report.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsPush).then(function (reports) {
                if (typeof reports !== 'undefined') {
                    if (reports.length) {
                        reports.forEach(function (report) {
                            if (report.indexedDBMethod === '2-update') {
                                collectionData.push(report);
                            }
                        });
                        if (collectionData.length) {
                            collectionData.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + report.type + '/' + report.id, report, false).then(function (data) {
                                            if (data.success === true) {
                                                var promises2 = [];
                                                promises2.push(_this.database.update(_this.tableReports, data.data)
                                                    .then(function () { return true; })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                }));
                                                promises2.push(_this.database.delete(_this.tableReportsPush, report.entryId)
                                                    .then(function () { return true; })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                }));
                                                return Promise.all(promises2).then(function () {
                                                    resolve2();
                                                });
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsPush).then(function (reports) {
                if (typeof reports !== 'undefined') {
                    if (reports.length) {
                        reports.forEach(function (report) {
                            if (report.indexedDBMethod === '3-delete') {
                                collectionData.push(report);
                            }
                        });
                        if (collectionData.length) {
                            collectionData.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + report.type + '/' + report.id, false).then(function (data) {
                                            if (data.success === true) {
                                                var promises2 = [];
                                                promises2.push(_this.database.delete(_this.tableReports, report.id)
                                                    .then(function () { return true; })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                }));
                                                promises2.push(_this.database.delete(_this.tableReportsPush, report.entryId)
                                                    .then(function () { return true; })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                }));
                                                return Promise.all(promises2).then(function () {
                                                    resolve2();
                                                });
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsCopy = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '0-copy') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.get('reports/' + attachment.report_type + '/' + attachment.copy_from_report_id + '/attachments/' + attachment.copy_from_attachment_id + '/copy/' + attachment.report_id, null, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.delete(_this.tableReportsAttachments, attachment.id)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.add(_this.tableReportsAttachments, data.data)
                                                            .then(function () { return true; }));
                                                        var newId_3 = parseInt(data.data.id, 10);
                                                        promises2.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                                                            .then(function (reports_attachments2) {
                                                            var filtered = [];
                                                            reports_attachments2.forEach(function (report_attachment) {
                                                                if (report_attachment.id === attachment.id) {
                                                                    report_attachment.id = newId_3;
                                                                    filtered.push(report_attachment);
                                                                }
                                                            });
                                                            if (filtered.length) {
                                                                return _this.database.updateBulk(_this.tableReportsAttachmentsPush, filtered)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                });
                                                            }
                                                            return true;
                                                        })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                attachment_id: data.data.id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '1-create') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var newAttachment = {
                                            filename: new FileInput(attachment.filename['_files']),
                                            title: attachment.title,
                                            description: attachment.description,
                                            append_warehouse: attachment.append_warehouse,
                                            append_ladder: attachment.append_ladder,
                                            append_tent: attachment.append_tent,
                                        };
                                        _this.apiService.post('reports/' + attachment.report_type + '/' + attachment.report_id + '/attachments', newAttachment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            if (data.data.id !== attachment.id) {
                                                                promises2.push(_this.database.delete(_this.tableReportsAttachments, attachment.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                promises2.push(_this.database.add(_this.tableReportsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                                var newId_4 = parseInt(data.data.id, 10);
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                                                                    .then(function (reports_attachments2) {
                                                                    var filtered = [];
                                                                    reports_attachments2.forEach(function (report_attachment) {
                                                                        if (report_attachment.id === attachment.id) {
                                                                            report_attachment.id = newId_4;
                                                                            filtered.push(report_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsAttachmentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) {
                                                                            if (_this.debug) {
                                                                                console.log(error);
                                                                            }
                                                                            throw new Error(error);
                                                                        });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableReportsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) {
                                                                    if (_this.debug) {
                                                                        console.log(error);
                                                                    }
                                                                    throw new Error(error);
                                                                }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                _this.redirectUser({
                                                                    report_id: data.data.report_id,
                                                                    attachment_id: data.data.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '2-update') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + attachment.report_type + '/' + attachment.report_id + '/attachments/' + attachment.id, attachment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.update(_this.tableReportsAttachments, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '3-delete') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + attachment.report_type + '/' + attachment.report_id + '/attachments/' + attachment.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsAttachments, attachment.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCommentsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var commentsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsCommentsPush).then(function (reports_comments) {
                if (typeof reports_comments !== 'undefined') {
                    if (reports_comments.length) {
                        reports_comments.forEach(function (report_comment) {
                            if (report_comment.indexedDBMethod === '1-create') {
                                commentsData.push(report_comment);
                            }
                        });
                        if (commentsData.length) {
                            commentsData.forEach(function (comment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + comment.report_type + '/' + comment.report_id + '/comments', comment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsCommentsPush, comment.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        if (data.data.id !== comment['id']) {
                                                            promises2.push(_this.database.delete(_this.tableReportsComments, comment.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.add(_this.tableReportsComments, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            var newId_5 = parseInt(data.data.id, 10);
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                                                                .then(function (reports_comments2) {
                                                                var filtered = [];
                                                                reports_comments2.forEach(function (report_comment) {
                                                                    if (report_comment.id === comment.id) {
                                                                        report_comment.id = newId_5;
                                                                        filtered.push(report_comment);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsCommentsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        else {
                                                            promises2.push(_this.database.update(_this.tableReportsComments, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                comment_id: data.data.id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCommentsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var commentsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsCommentsPush).then(function (reports_comments) {
                if (typeof reports_comments !== 'undefined') {
                    if (reports_comments.length) {
                        reports_comments.forEach(function (report_comment) {
                            if (report_comment.indexedDBMethod === '2-update') {
                                commentsData.push(report_comment);
                            }
                        });
                        if (commentsData.length) {
                            commentsData.forEach(function (comment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + comment.report_type + '/' + comment.report_id + '/comments/' + comment.id, comment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    var promises2 = [];
                                                    promises2.push(_this.database.update(_this.tableReportsComments, data.data)
                                                        .then(function () { return true; })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    }));
                                                    promises2.push(_this.database.delete(_this.tableReportsCommentsPush, comment.entryId)
                                                        .then(function () { return true; })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    }));
                                                    return Promise.all(promises2).then(function () {
                                                        resolve2();
                                                    });
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCommentsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var commentsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsCommentsPush).then(function (reports_comments) {
                if (typeof reports_comments !== 'undefined') {
                    if (reports_comments.length) {
                        reports_comments.forEach(function (report_comment) {
                            if (report_comment.indexedDBMethod === '3-delete') {
                                commentsData.push(report_comment);
                            }
                        });
                        if (commentsData.length) {
                            commentsData.forEach(function (comment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + comment.report_type + '/' + comment.report_id + '/comments/' + comment.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsComments, comment.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableReportsCommentsPush, comment.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsWarehouseQuestionnaires = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var questionnaireData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires start ...');
            }
            return _this.database.getAllFast(_this.tableReportsWarehouseQuestionnaireAnswers).then(function (reports_questionnaire_answers) {
                if (typeof reports_questionnaire_answers !== 'undefined') {
                    if (reports_questionnaire_answers.length) {
                        reports_questionnaire_answers.forEach(function (report_questionnaire_answer) {
                            if (report_questionnaire_answer.indexedDBMethod === '2-update') {
                                questionnaireData.push(report_questionnaire_answer);
                            }
                        });
                        if (questionnaireData.length) {
                            questionnaireData.forEach(function (questionnaire) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + questionnaire.report_type + '/' + questionnaire.report_id + '/questionnaire', questionnaire, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        return _this.database.update(_this.tableReportsWarehouseQuestionnaireAnswers, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        });
                                                    }
                                                }
                                                else if (data.success === false) {
                                                    if (typeof data.error_type !== 'undefined') {
                                                        if (data.error_type === 'report_not_found') {
                                                            return _this.database.delete(_this.tableReportsWarehouseQuestionnaireAnswers, questionnaire.report_id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            });
                                                        }
                                                    }
                                                }
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsPush).then(function (reports_buildings) {
                if (typeof reports_buildings !== 'undefined') {
                    if (reports_buildings.length) {
                        reports_buildings.forEach(function (report_building) {
                            if (report_building.indexedDBMethod === '1-create') {
                                buildingsData.push(report_building);
                            }
                        });
                        if (buildingsData.length) {
                            buildingsData.forEach(function (building) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + building.report_type + '/' + building.report_id + '/buildings/', building, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [], newId_6 = parseInt(data.data.id, 10);
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsPush, building.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        if (data.data.id !== building.id) {
                                                            promises2.push(_this.database.delete(_this.tableReportsBuildings, building.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.add(_this.tableReportsBuildings, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                                                                .then(function (reports_buildings2) {
                                                                var filtered = [];
                                                                reports_buildings2.forEach(function (report_building) {
                                                                    if (report_building.id === building.id) {
                                                                        report_building.id = newId_6;
                                                                        filtered.push(report_building);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                                                                .then(function (reports_buildings_reports) {
                                                                var filtered = [];
                                                                reports_buildings_reports.forEach(function (report_buildings_report) {
                                                                    if (report_buildings_report.building_id === building.id) {
                                                                        report_buildings_report.building_id = newId_6;
                                                                        filtered.push(report_buildings_report);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReports, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                                                                .then(function (reports_buildings_reports) {
                                                                var filtered = [];
                                                                reports_buildings_reports.forEach(function (report_buildings_report) {
                                                                    if (report_buildings_report.building_id === building.id) {
                                                                        report_buildings_report.building_id = newId_6;
                                                                        filtered.push(report_buildings_report);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                                                                .then(function (reports_buildings_reports_photos) {
                                                                var filtered = [];
                                                                reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                                                                    if (report_building_report_photo.building_id === building.id) {
                                                                        report_building_report_photo.building_id = newId_6;
                                                                        filtered.push(report_building_report_photo);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPhotos, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        else {
                                                            promises2.push(_this.database.update(_this.tableReportsBuildings, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                building_id: data.data.id,
                                                                old_building_id: building.id,
                                                                old_report_id: building.report_id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsPush).then(function (reports_buildings) {
                if (typeof reports_buildings !== 'undefined') {
                    if (reports_buildings.length) {
                        reports_buildings.forEach(function (report_building) {
                            if (report_building.indexedDBMethod === '2-update') {
                                buildingsData.push(report_building);
                            }
                        });
                        if (buildingsData.length) {
                            buildingsData.forEach(function (building) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + building.report_type + '/' + building.report_id + '/buildings/' + building.id, building, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsPush, building.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.update(_this.tableReportsBuildings, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsPush).then(function (reports_buildings) {
                if (typeof reports_buildings !== 'undefined') {
                    if (reports_buildings.length) {
                        reports_buildings.forEach(function (report_building) {
                            if (report_building.indexedDBMethod === '3-delete') {
                                buildingsData.push(report_building);
                            }
                        });
                        if (buildingsData.length) {
                            buildingsData.forEach(function (building) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + building.report_type + '/' + building.report_id + '/buildings/' + building.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsBuildings, building.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsPush, building.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPush).then(function (reports_buildings_reports) {
                if (typeof reports_buildings_reports !== 'undefined') {
                    if (reports_buildings_reports.length) {
                        reports_buildings_reports.forEach(function (report_building_report) {
                            if (report_building_report.indexedDBMethod === '1-create') {
                                buildingReport.push(report_building_report);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        delete report.photos;
                                        _this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports', report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [], newId_7 = parseInt(data.data.id, 10);
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPush, report.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        if (data.data.id !== report.id) {
                                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsReports, report.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.add(_this.tableReportsBuildingsReports, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                                                                .then(function (reports_building_reports2) {
                                                                var filtered = [];
                                                                reports_building_reports2.forEach(function (report_building_report) {
                                                                    if (report_building_report.id === report.id) {
                                                                        report_building_report.id = newId_7;
                                                                        filtered.push(report_building_report);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                                                                .then(function (reports_buildings_reports_photos) {
                                                                var filtered = [];
                                                                reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                                                                    if (report_building_report_photo.building_report_id === report.id) {
                                                                        report_building_report_photo.building_report_id = newId_7;
                                                                        filtered.push(report_building_report_photo);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPhotos, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsLadderQuestionnaireAnswers)
                                                                .then(function (reports_ladder_questionnaire_answers) {
                                                                var filtered = [];
                                                                reports_ladder_questionnaire_answers.forEach(function (report_ladder_questionnaire_answer) {
                                                                    if (report_ladder_questionnaire_answer.building_report_id === report.id) {
                                                                        report_ladder_questionnaire_answer.building_report_id = newId_7;
                                                                        filtered.push(report_ladder_questionnaire_answer);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsLadderQuestionnaireAnswers, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsTentQuestionnaireAnswers)
                                                                .then(function (reports_tent_questionnaire_answers) {
                                                                var filtered = [];
                                                                reports_tent_questionnaire_answers.forEach(function (report_tent_questionnaire_answer) {
                                                                    if (report_tent_questionnaire_answer.building_report_id === report.id) {
                                                                        report_tent_questionnaire_answer.building_report_id = newId_7;
                                                                        filtered.push(report_tent_questionnaire_answer);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsTentQuestionnaireAnswers, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        else {
                                                            promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                building_id: data.data.building_id,
                                                                building_report_id: data.data.id,
                                                                old_report_id: report.report_id,
                                                                old_building_id: report.building_id,
                                                                old_building_report_id: report.id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPush).then(function (reports_buildings_reports) {
                if (typeof reports_buildings_reports !== 'undefined') {
                    if (reports_buildings_reports.length) {
                        reports_buildings_reports.forEach(function (report_building_report) {
                            if (report_building_report.indexedDBMethod === '2-update') {
                                buildingReport.push(report_building_report);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        delete report.photos;
                                        _this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPush, report.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPush).then(function (reports_buildings_reports) {
                if (typeof reports_buildings_reports !== 'undefined') {
                    if (reports_buildings_reports.length) {
                        reports_buildings_reports.forEach(function (report_building_report) {
                            if (report_building_report.indexedDBMethod === '3-delete') {
                                buildingReport.push(report_building_report);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsReports, report.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPush, report.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsPhotosCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos).then(function (reports_buildings_reports_photos) {
                if (typeof reports_buildings_reports_photos !== 'undefined') {
                    if (reports_buildings_reports_photos.length) {
                        reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                            if (report_building_report_photo.indexedDBMethod === '1-create') {
                                buildingReport.push(report_building_report_photo);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var photos_1 = {
                                            photos: []
                                        };
                                        report.photos.forEach(function (photo) {
                                            photos_1.photos.push(photo.file);
                                        });
                                        _this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/photos', photos_1, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPhotos, report.id)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    // was building removed already?
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.building_missing !== 'undefined') {
                                                            _this.database.delete(_this.tableReportsBuildingsReportsPhotos, report.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            });
                                                        }
                                                    }
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsPhotosDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos).then(function (reports_buildings_reports_photos) {
                if (typeof reports_buildings_reports_photos !== 'undefined') {
                    if (reports_buildings_reports_photos.length) {
                        reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                            if (report_building_report_photo.indexedDBMethod === '3-delete') {
                                buildingReport.push(report_building_report_photo);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/delete-image/' + report.id, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPhotos, report.id)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsMapsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingMap = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsMapsPush).then(function (reports_buildings_maps) {
                if (typeof reports_buildings_maps !== 'undefined') {
                    if (reports_buildings_maps.length) {
                        reports_buildings_maps.forEach(function (reports_building_map) {
                            if (reports_building_map.indexedDBMethod === '1-create') {
                                buildingMap.push(reports_building_map);
                            }
                        });
                        if (buildingMap.length) {
                            buildingMap.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/maps', report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [], newId_8 = parseInt(data.data.id, 10);
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsMapsPush, report.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        if (data.data.id !== report.id) {
                                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsMaps, report.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.add(_this.tableReportsBuildingsMaps, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsMapsPush)
                                                                .then(function (reports_building_maps2) {
                                                                var filtered = [];
                                                                reports_building_maps2.forEach(function (report_building_map) {
                                                                    if (report_building_map.id === report.id) {
                                                                        report_building_map.id = newId_8;
                                                                        filtered.push(report_building_map);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsMapsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) {
                                                                        if (_this.debug) {
                                                                            console.log(error);
                                                                        }
                                                                        throw new Error(error);
                                                                    });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        else {
                                                            promises2.push(_this.database.update(_this.tableReportsBuildingsMaps, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) {
                                                                if (_this.debug) {
                                                                    console.log(error);
                                                                }
                                                                throw new Error(error);
                                                            }));
                                                        }
                                                        return Promise.all(promises2).then(function () { return resolve2(); });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsMapsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingMap = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsMapsPush).then(function (reports_buildings_maps) {
                if (typeof reports_buildings_maps !== 'undefined') {
                    if (reports_buildings_maps.length) {
                        reports_buildings_maps.forEach(function (report_building_map) {
                            if (report_building_map.indexedDBMethod === '2-update') {
                                buildingMap.push(report_building_map);
                            }
                        });
                        if (buildingMap.length) {
                            buildingMap.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/maps/' + report.id, report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.update(_this.tableReportsBuildingsMaps, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsMapsPush, report.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsMapsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingMap = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsMapsPush).then(function (reports_buildings_maps) {
                if (typeof reports_buildings_maps !== 'undefined') {
                    if (reports_buildings_maps.length) {
                        reports_buildings_maps.forEach(function (reports_building_map) {
                            if (reports_building_map.indexedDBMethod === '3-delete') {
                                buildingMap.push(reports_building_map);
                            }
                        });
                        if (buildingMap.length) {
                            buildingMap.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/maps/' + report.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsMaps, report.id)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsMapsPush, report.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) {
                                                if (_this.debug) {
                                                    console.log(error);
                                                }
                                                throw new Error(error);
                                            }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsSendToClient = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient start ...');
            }
            return _this.database.getAllFast(_this.tableReportsSendToClient).then(function (reports_send_to_client) {
                if (typeof reports_send_to_client !== 'undefined') {
                    if (reports_send_to_client.length) {
                        reports_send_to_client.forEach(function (report) {
                            promises.push(new Promise(function (resolve2) {
                                if (_this.apiService.isOnline) {
                                    return _this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/send-to-client', report, false)
                                        .then(function () {
                                        return _this.database.delete(_this.tableReportsSendToClient, report.id)
                                            .then(function () { return resolve2(); })
                                            .catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    })
                                        .catch(function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    });
                                }
                                else {
                                    resolve2();
                                }
                            }));
                        });
                        return Promise.all(promises).then(function () {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient done');
                            }
                            resolve();
                        });
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsVerifyInspectorStatus = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus start ...');
            }
            return _this.database.getAllFast(_this.tableReportsVerifyInspectorStatus).then(function (reports_verify_inspector_status) {
                if (typeof reports_verify_inspector_status !== 'undefined') {
                    if (reports_verify_inspector_status.length) {
                        reports_verify_inspector_status.forEach(function (report) {
                            promises.push(new Promise(function (resolve2) {
                                if (_this.apiService.isOnline) {
                                    return _this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/verify-inspector-status', report, false).then(function (data) {
                                        var promises2 = [];
                                        promises2.push(_this.database.delete(_this.tableReportsVerifyInspectorStatus, report.entryId)
                                            .then(function () { return resolve2(); })
                                            .catch(function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        }));
                                        if (typeof data !== 'undefined') {
                                            if (data.success === true) {
                                                if (typeof data.data !== 'undefined') {
                                                    promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                        .then(function () { return true; })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    }));
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }
                                        else {
                                            resolve2();
                                        }
                                        return Promise.all(promises2).then(function () {
                                            resolve2();
                                        });
                                    }).catch(function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    });
                                }
                                else {
                                    resolve2();
                                }
                            }));
                        });
                        return Promise.all(promises).then(function () {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus done');
                            }
                            resolve();
                        });
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    IndexedDBService.prototype.deleteInspectorInventory = function (inventory_ids) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.database.deleteBulk(_this.tableInspectorsInventory, inventory_ids.map(function (ids) { return ids.iiid; }))
                .then(function () { return true; })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableInspectorsInventoryPush)
                .then(function (inventories) {
                if (typeof inventories !== 'undefined') {
                    if (inventories.length) {
                        var filtered_5 = [];
                        inventories.forEach(function (inventory) {
                            inventory_ids.forEach(function (inventory_id) {
                                if (inventory.id === inventory_id.iiid) {
                                    filtered_5.push(inventory.entryId);
                                }
                            });
                        });
                        if (filtered_5.length) {
                            filtered_5 = __spread(new Set(filtered_5));
                            return _this.database.deleteBulk(_this.tableInspectorsInventoryPush, filtered_5)
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableInspectorsInventorySendToClient)
                .then(function (inventories) {
                if (typeof inventories !== 'undefined') {
                    if (inventories.length) {
                        var filtered_6 = [];
                        inventories.forEach(function (inventory) {
                            inventory_ids.forEach(function (inventory_id) {
                                if (inventory.inventory_id === inventory_id.iiid) {
                                    filtered_6.push(inventory.id);
                                }
                            });
                        });
                        if (filtered_6.length) {
                            filtered_6 = __spread(new Set(filtered_6));
                            return _this.database.deleteBulk(_this.tableInspectorsInventorySendToClient, filtered_6)
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteInspectorCalibration = function (calibration_ids) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.database.deleteBulk(_this.tableInspectorsCalibrations, calibration_ids.map(function (ids) { return ids.icid; }))
                .then(function () { return true; })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableInspectorsCalibrationsPush)
                .then(function (calibrations) {
                if (typeof calibrations !== 'undefined') {
                    if (calibrations.length) {
                        var filtered_7 = [];
                        calibrations.forEach(function (calibration) {
                            calibration_ids.forEach(function (calibration_id) {
                                if (calibration.id === calibration_id.icid) {
                                    filtered_7.push(calibration.entryId);
                                }
                            });
                        });
                        if (filtered_7.length) {
                            filtered_7 = __spread(new Set(filtered_7));
                            return _this.database.deleteBulk(_this.tableInspectorsCalibrationsPush, filtered_7)
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteClient = function (client_ids) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.database.deleteBulk(_this.tableClients, client_ids.map(function (ids) { return ids.cid; }))
                .then(function () { return true; })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableClientsPush)
                .then(function (clients) {
                if (typeof clients !== 'undefined') {
                    if (clients.length) {
                        var filtered_8 = [];
                        clients.forEach(function (client) {
                            client_ids.forEach(function (client_id) {
                                if (client.client_id === client_id.cid) {
                                    filtered_8.push(client.entryId);
                                }
                            });
                        });
                        if (filtered_8.length) {
                            filtered_8 = __spread(new Set(filtered_8));
                            return _this.database.deleteBulk(_this.tableClientsPush, filtered_8)
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableInspectorsInspections)
                .then(function (inspections) {
                if (typeof inspections !== 'undefined') {
                    if (inspections.length) {
                        var filtered_9 = [];
                        inspections.forEach(function (inspection) {
                            client_ids.forEach(function (client_id) {
                                if (inspection.client_id === client_id.cid) {
                                    filtered_9.push(inspection.id);
                                }
                            });
                        });
                        if (filtered_9.length) {
                            filtered_9 = __spread(new Set(filtered_9));
                            return _this.database.deleteBulk(_this.tableInspectorsInspections, filtered_9)
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.deleteClientAttachment(client_ids));
            promises.push(_this.deleteClientContactPerson(client_ids));
            promises.push(_this.deleteClientBuildingMaps(client_ids));
            promises.push(_this.deleteReport(client_ids));
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteClientAttachment = function (client_ids, attachment_ids) {
        var _this = this;
        if (client_ids === void 0) { client_ids = null; }
        if (attachment_ids === void 0) { attachment_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (attachment_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableClientsAttachments, attachment_ids.map(function (ids) { return ids.caid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableClientsAttachmentsPush)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_10 = [];
                            attachments.forEach(function (attachment) {
                                attachment_ids.forEach(function (attachment_id) {
                                    if (attachment.id === attachment_id.caid) {
                                        filtered_10.push(attachment.entryId);
                                    }
                                });
                            });
                            if (filtered_10.length) {
                                filtered_10 = __spread(new Set(filtered_10));
                                return _this.database.deleteBulk(_this.tableClientsAttachmentsPush, filtered_10)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (client_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableClientsAttachments)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_11 = [];
                            attachments.forEach(function (attachment) {
                                client_ids.forEach(function (client_id) {
                                    if (attachment.client_id === client_id.cid) {
                                        filtered_11.push(attachment.id);
                                    }
                                });
                            });
                            if (filtered_11.length) {
                                filtered_11 = __spread(new Set(filtered_11));
                                return _this.database.deleteBulk(_this.tableClientsAttachments, filtered_11)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableClientsAttachmentsPush)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_12 = [];
                            attachments.forEach(function (attachment) {
                                client_ids.forEach(function (client_id) {
                                    if (attachment.client_id === client_id.cid) {
                                        filtered_12.push(attachment.entryId);
                                    }
                                });
                            });
                            if (filtered_12.length) {
                                filtered_12 = __spread(new Set(filtered_12));
                                return _this.database.deleteBulk(_this.tableClientsAttachmentsPush, filtered_12)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteClientContactPerson = function (client_ids, contact_person_ids) {
        var _this = this;
        if (client_ids === void 0) { client_ids = null; }
        if (contact_person_ids === void 0) { contact_person_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (contact_person_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableClientsContactPersons, contact_person_ids.map(function (ids) { return ids.ccpid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableClientsContactPersonsPush)
                    .then(function (contact_persons) {
                    if (typeof contact_persons !== 'undefined') {
                        if (contact_persons.length) {
                            var filtered_13 = [];
                            contact_persons.forEach(function (contact_person) {
                                contact_person_ids.forEach(function (contact_person_id) {
                                    if (contact_person.client_id === contact_person_id.cid) {
                                        filtered_13.push(contact_person.entryId);
                                    }
                                });
                            });
                            if (filtered_13.length) {
                                filtered_13 = __spread(new Set(filtered_13));
                                return _this.database.deleteBulk(_this.tableClientsAttachmentsPush, filtered_13)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (client_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableClientsContactPersons)
                    .then(function (contact_persons) {
                    if (typeof contact_persons !== 'undefined') {
                        if (contact_persons.length) {
                            var filtered_14 = [];
                            contact_persons.forEach(function (contact_person) {
                                client_ids.forEach(function (client_id) {
                                    if (contact_person.client_id === client_id.cid) {
                                        filtered_14.push(contact_person.id);
                                    }
                                });
                            });
                            if (filtered_14.length) {
                                filtered_14 = __spread(new Set(filtered_14));
                                return _this.database.deleteBulk(_this.tableClientsContactPersons, filtered_14)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableClientsContactPersonsPush)
                    .then(function (contact_persons) {
                    if (typeof contact_persons !== 'undefined') {
                        if (contact_persons.length) {
                            var filtered_15 = [];
                            contact_persons.forEach(function (contact_person) {
                                client_ids.forEach(function (client_id) {
                                    if (contact_person.client_id === client_id.cid) {
                                        filtered_15.push(contact_person.entryId);
                                    }
                                });
                            });
                            if (filtered_15.length) {
                                filtered_15 = __spread(new Set(filtered_15));
                                return _this.database.deleteBulk(_this.tableClientsContactPersonsPush, filtered_15)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteClientBuildingMaps = function (client_ids, building_map_ids) {
        var _this = this;
        if (client_ids === void 0) { client_ids = null; }
        if (building_map_ids === void 0) { building_map_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (building_map_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableClientsBuildingsMaps, building_map_ids.map(function (ids) { return ids.cbmid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableClientsBuildingsMapsPush)
                    .then(function (buildingMaps) {
                    if (typeof buildingMaps !== 'undefined') {
                        if (buildingMaps.length) {
                            var filtered_16 = [];
                            buildingMaps.forEach(function (buildingMap) {
                                building_map_ids.forEach(function (building_map_id) {
                                    if (buildingMap.id === building_map_id.cbmid) {
                                        filtered_16.push(buildingMap.entryId);
                                    }
                                });
                            });
                            if (filtered_16.length) {
                                filtered_16 = __spread(new Set(filtered_16));
                                return _this.database.deleteBulk(_this.tableClientsBuildingsMapsPush, filtered_16)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (client_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableClientsBuildingsMaps)
                    .then(function (buildingMaps) {
                    if (typeof buildingMaps !== 'undefined') {
                        if (buildingMaps.length) {
                            var filtered_17 = [];
                            buildingMaps.forEach(function (buildingMap) {
                                client_ids.forEach(function (client_id) {
                                    if (buildingMap.client_id === client_id.cid) {
                                        filtered_17.push(buildingMap.id);
                                    }
                                });
                            });
                            if (filtered_17.length) {
                                filtered_17 = __spread(new Set(filtered_17));
                                return _this.database.deleteBulk(_this.tableClientsBuildingsMaps, filtered_17)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReport = function (client_ids, report_ids) {
        var _this = this;
        if (client_ids === void 0) { client_ids = null; }
        if (report_ids === void 0) { report_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (client_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReports)
                    .then(function (reports) {
                    if (typeof reports !== 'undefined') {
                        if (reports.length) {
                            var filtered_18 = [];
                            reports.forEach(function (report) {
                                client_ids.forEach(function (client_id) {
                                    if (report.client_id === client_id.cid) {
                                        filtered_18.push(report.id);
                                    }
                                });
                            });
                            if (filtered_18.length) {
                                filtered_18 = __spread(new Set(filtered_18));
                                return _this.database.deleteBulk(_this.tableReports, filtered_18)
                                    .then(function () {
                                    var promises2 = [], rids = filtered_18.map(function (report) {
                                        return { rid: report.id };
                                    });
                                    promises2.push(_this.deleteReportAttachment(rids));
                                    promises2.push(_this.deleteReportComment(rids));
                                    promises2.push(_this.deleteReportSendToClient(client_ids));
                                    promises2.push(_this.deleteReportBuilding(rids));
                                    promises2.push(_this.deleteReportBuildingReport(rids));
                                    promises2.push(_this.deleteReportBuildingReportPhoto(rids));
                                    promises2.push(_this.deleteReportBuildingReportLadderAndTentQuestionnaire(rids));
                                    promises2.push(_this.deleteReportBuildingReportWarehouseQuestionnaire(rids));
                                    return Promise.all(promises2).then(function () { return true; });
                                })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsPush)
                    .then(function (reports) {
                    if (typeof reports !== 'undefined') {
                        if (reports.length) {
                            var filtered_19 = [];
                            reports.forEach(function (report) {
                                client_ids.forEach(function (client_id) {
                                    if (report.client_id === client_id.cid) {
                                        filtered_19.push(report.entryId);
                                    }
                                });
                            });
                            if (filtered_19.length) {
                                filtered_19 = __spread(new Set(filtered_19));
                                return _this.database.deleteBulk(_this.tableReportsPush, filtered_19)
                                    .then(function () {
                                    var promises2 = [], rids = filtered_19.map(function (report) {
                                        return { rid: report.id };
                                    });
                                    promises2.push(_this.deleteReportAttachment(rids));
                                    promises2.push(_this.deleteReportComment(rids));
                                    promises2.push(_this.deleteReportBuilding(rids));
                                    promises2.push(_this.deleteReportBuildingReport(rids));
                                    promises2.push(_this.deleteReportBuildingReportPhoto(rids));
                                    promises2.push(_this.deleteReportBuildingReportLadderAndTentQuestionnaire(rids));
                                    promises2.push(_this.deleteReportBuildingReportWarehouseQuestionnaire(rids));
                                    return Promise.all(promises2).then(function () { return true; });
                                })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReports, report_ids.map(function (ids) { return ids.rid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsPush)
                    .then(function (reports) {
                    if (typeof reports !== 'undefined') {
                        if (reports.length) {
                            var filtered_20 = [];
                            reports.forEach(function (report) {
                                report_ids.forEach(function (report_id) {
                                    if (report.id === report_id.rid) {
                                        filtered_20.push(report.entryId);
                                    }
                                });
                            });
                            if (filtered_20.length) {
                                filtered_20 = __spread(new Set(filtered_20));
                                return _this.database.deleteBulk(_this.tableReportsPush, filtered_20)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.deleteReportAttachment(report_ids));
                promises.push(_this.deleteReportComment(report_ids));
                promises.push(_this.deleteReportBuilding(report_ids));
                promises.push(_this.deleteReportBuildingReport(report_ids));
                promises.push(_this.deleteReportBuildingReportPhoto(report_ids));
                promises.push(_this.deleteReportBuildingReportLadderAndTentQuestionnaire(report_ids));
                promises.push(_this.deleteReportBuildingReportWarehouseQuestionnaire(report_ids));
                promises.push(_this.deleteReportSendToClient(null, report_ids));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportAttachment = function (report_ids, attachment_ids) {
        var _this = this;
        if (attachment_ids === void 0) { attachment_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (attachment_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsAttachments, attachment_ids.map(function (ids) { return ids.raid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_21 = [];
                            attachments.forEach(function (attachment) {
                                attachment_ids.forEach(function (attachment_id) {
                                    if (attachment.report_id === attachment_id.rid && attachment.id === attachment_id.raid) {
                                        filtered_21.push(attachment.entryId);
                                    }
                                });
                            });
                            if (filtered_21.length) {
                                filtered_21 = __spread(new Set(filtered_21));
                                return _this.database.deleteBulk(_this.tableReportsAttachmentsPush, filtered_21)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsAttachments)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_22 = [];
                            attachments.forEach(function (attachment) {
                                report_ids.forEach(function (report_id) {
                                    if (attachment.report_id === report_id.rid) {
                                        filtered_22.push(attachment.id);
                                    }
                                });
                            });
                            if (filtered_22.length) {
                                filtered_22 = __spread(new Set(filtered_22));
                                return _this.database.deleteBulk(_this.tableReportsAttachments, filtered_22)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_23 = [];
                            attachments.forEach(function (attachment) {
                                report_ids.forEach(function (report_id) {
                                    if (attachment.report_id === report_id.rid) {
                                        filtered_23.push(attachment.entryId);
                                    }
                                });
                            });
                            if (filtered_23.length) {
                                filtered_23 = __spread(new Set(filtered_23));
                                return _this.database.deleteBulk(_this.tableReportsAttachmentsPush, filtered_23)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportComment = function (report_ids, comment_ids) {
        var _this = this;
        if (comment_ids === void 0) { comment_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (comment_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsComments, comment_ids.map(function (ids) { return ids.rcid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                    .then(function (comments) {
                    if (typeof comments !== 'undefined') {
                        if (comments.length) {
                            var filtered_24 = [];
                            comments.forEach(function (comment) {
                                comment_ids.forEach(function (comment_id) {
                                    if (comment.id === comment_id.rcid) {
                                        filtered_24.push(comment.entryId);
                                    }
                                });
                            });
                            if (filtered_24.length) {
                                filtered_24 = __spread(new Set(filtered_24));
                                return _this.database.deleteBulk(_this.tableReportsCommentsPush, filtered_24)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsComments)
                    .then(function (comments) {
                    if (typeof comments !== 'undefined') {
                        if (comments.length) {
                            var filtered_25 = [];
                            comments.forEach(function (comment) {
                                report_ids.forEach(function (report_id) {
                                    if (comment.report_id === report_id.rid) {
                                        filtered_25.push(comment.id);
                                    }
                                });
                            });
                            if (filtered_25.length) {
                                filtered_25 = __spread(new Set(filtered_25));
                                return _this.database.deleteBulk(_this.tableReportsComments, filtered_25)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                    .then(function (comments) {
                    if (typeof comments !== 'undefined') {
                        if (comments.length) {
                            var filtered_26 = [];
                            comments.forEach(function (comment) {
                                report_ids.forEach(function (report_id) {
                                    if (comment.report_id === report_id.rid) {
                                        filtered_26.push(comment.entryId);
                                    }
                                });
                            });
                            if (filtered_26.length) {
                                filtered_26 = __spread(new Set(filtered_26));
                                return _this.database.deleteBulk(_this.tableReportsCommentsPush, filtered_26)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuilding = function (report_ids, building_ids) {
        var _this = this;
        if (building_ids === void 0) { building_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (building_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsBuildings, building_ids.map(function (ids) { return ids.rbid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                    .then(function (report_buildings) {
                    if (typeof report_buildings !== 'undefined') {
                        if (report_buildings.length) {
                            var filtered_27 = [];
                            report_buildings.forEach(function (report_building) {
                                building_ids.forEach(function (building_id) {
                                    if (report_building.id === building_id.rbid) {
                                        filtered_27.push(report_building.entryId);
                                    }
                                });
                            });
                            if (filtered_27.length) {
                                filtered_27 = __spread(new Set(filtered_27));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsPush, filtered_27)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                }));
                promises.push(_this.deleteReportBuildingReport(null, building_ids));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildings)
                    .then(function (report_buildings) {
                    if (typeof report_buildings !== 'undefined') {
                        if (report_buildings.length) {
                            var filtered_28 = [];
                            report_buildings.forEach(function (report_building) {
                                report_ids.forEach(function (report_id) {
                                    if (report_building.report_id === report_id.rid) {
                                        filtered_28.push(report_building.id);
                                    }
                                });
                            });
                            if (filtered_28.length) {
                                filtered_28 = __spread(new Set(filtered_28));
                                return _this.database.deleteBulk(_this.tableReportsBuildings, filtered_28)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                    .then(function (report_buildings) {
                    if (typeof report_buildings !== 'undefined') {
                        if (report_buildings.length) {
                            var filtered_29 = [];
                            report_buildings.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_29.push(building_report.entryId);
                                    }
                                });
                            });
                            if (filtered_29.length) {
                                filtered_29 = __spread(new Set(filtered_29));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsPush, filtered_29)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuildingReport = function (report_ids, building_ids, building_report_ids) {
        var _this = this;
        if (building_ids === void 0) { building_ids = null; }
        if (building_report_ids === void 0) { building_report_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (building_report_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsBuildingsReports, building_report_ids.map(function (ids) { return ids.rbrid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_30 = [];
                            building_reports.forEach(function (building_report) {
                                building_report_ids.forEach(function (building_report_id) {
                                    if (building_report.id === building_report_id.rbrid) {
                                        filtered_30.push(building_report.entryId);
                                    }
                                });
                            });
                            if (filtered_30.length) {
                                filtered_30 = __spread(new Set(filtered_30));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPush, filtered_30)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.deleteReportBuildingReportLadderAndTentQuestionnaire(null, building_report_ids));
            }
            if (building_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_31 = [];
                            building_reports.forEach(function (building_report) {
                                building_ids.forEach(function (building_id) {
                                    if (building_report.building_id === building_id.rbid) {
                                        filtered_31.push(building_report);
                                    }
                                });
                            });
                            if (filtered_31.length) {
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReports, filtered_31.map(function (ids) { return ids.id; }))
                                    .then(function () { return _this.deleteReportBuildingReportLadderAndTentQuestionnaire(null, filtered_31.map(function (ids) {
                                    return {
                                        rid: ids.report_id,
                                        rbid: ids.building_id,
                                        rbrid: ids.id
                                    };
                                })); })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_32 = [];
                            building_reports.forEach(function (building_report) {
                                building_ids.forEach(function (building_id) {
                                    if (building_report.building_id === building_id.rbid) {
                                        filtered_32.push(building_report);
                                    }
                                });
                            });
                            if (filtered_32.length) {
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPush, filtered_32.map(function (ids) { return ids.entryId; }))
                                    .then(function () { return _this.deleteReportBuildingReportLadderAndTentQuestionnaire(null, filtered_32.map(function (ids) {
                                    return {
                                        rid: ids.report_id,
                                        rbid: ids.building_id,
                                        rbrid: ids.id
                                    };
                                })); })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_33 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_33.push(building_report.id);
                                    }
                                });
                            });
                            if (filtered_33.length) {
                                filtered_33 = __spread(new Set(filtered_33));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReports, filtered_33)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.deleteReportBuildingReportLadderAndTentQuestionnaire(report_ids));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_34 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_34.push(building_report.entryId);
                                    }
                                });
                            });
                            if (filtered_34.length) {
                                filtered_34 = __spread(new Set(filtered_34));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPush, filtered_34)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuildingReportPhoto = function (report_ids, photo_ids) {
        var _this = this;
        if (photo_ids === void 0) { photo_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (photo_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_35 = [];
                            building_reports.forEach(function (building_report) {
                                photo_ids.forEach(function (photo_id) {
                                    if (building_report.id === photo_id.rbrid && building_report.building_id === photo_id.rbid && typeof building_report.photos !== 'undefined') {
                                        if (typeof building_report.photos.inspector !== 'undefined') {
                                            building_report.photos.inspector.forEach(function (photo, index) {
                                                if (parseInt(photo.id, 10) === photo_id.rbrpid) {
                                                    building_report.photos.inspector.splice(index, 1);
                                                }
                                            });
                                        }
                                        if (typeof building_report.photos.by_client !== 'undefined') {
                                            building_report.photos.inspector.forEach(function (photo, index) {
                                                if (parseInt(photo.id, 10) === photo_id.rbrpid) {
                                                    building_report.photos.by_client.splice(index, 1);
                                                }
                                            });
                                        }
                                        filtered_35.push(building_report);
                                    }
                                });
                            });
                            if (filtered_35.length) {
                                return _this.database.update(_this.tableReportsBuildingsReports, filtered_35)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                    .then(function (report_building_photos) {
                    if (typeof report_building_photos !== 'undefined') {
                        if (report_building_photos.length) {
                            var filtered_36 = [];
                            report_building_photos.forEach(function (report_building_photo) {
                                report_ids.forEach(function (report_id) {
                                    if (report_building_photo.report_id === report_id.rid) {
                                        filtered_36.push(report_building_photo.id);
                                    }
                                });
                            });
                            if (filtered_36.length) {
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPhotos, filtered_36)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuildingMap = function (report_ids, building_map_ids) {
        var _this = this;
        if (building_map_ids === void 0) { building_map_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (building_map_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsBuildingsMaps, building_map_ids.map(function (ids) { return ids.rbmid; }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsMapsPush)
                    .then(function (building_maps) {
                    if (typeof building_maps !== 'undefined') {
                        if (building_maps.length) {
                            var filtered_37 = [];
                            building_maps.forEach(function (building_map) {
                                building_map_ids.forEach(function (building_map_id) {
                                    if (building_map.id === building_map_id.rbmid) {
                                        filtered_37.push(building_map.entryId);
                                    }
                                });
                            });
                            if (filtered_37.length) {
                                filtered_37 = __spread(new Set(filtered_37));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsMapsPush, filtered_37)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_38 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_38.push(building_report.id);
                                    }
                                });
                            });
                            if (filtered_38.length) {
                                filtered_38 = __spread(new Set(filtered_38));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReports, filtered_38)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.deleteReportBuildingReportLadderAndTentQuestionnaire(report_ids));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_39 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_39.push(building_report.entryId);
                                    }
                                });
                            });
                            if (filtered_39.length) {
                                filtered_39 = __spread(new Set(filtered_39));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPush, filtered_39)
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuildingReportLadderAndTentQuestionnaire = function (report_ids, building_report_ids) {
        var _this = this;
        if (building_report_ids === void 0) { building_report_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (building_report_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsLadderQuestionnaireAnswers, building_report_ids.map(function (br) {
                    return [br.rid, br.rbrid];
                }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.deleteBulk(_this.tableReportsTentQuestionnaireAnswers, building_report_ids.map(function (br) {
                    return [br.rid, br.rbrid];
                }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsLadderQuestionnaireAnswers)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_40 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_40.push(building_report);
                                    }
                                });
                            });
                            if (filtered_40.length) {
                                return _this.database.deleteBulk(_this.tableReportsLadderQuestionnaireAnswers, filtered_40.map(function (ids) {
                                    return [ids.report_id, ids.building_report_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsTentQuestionnaireAnswers)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_41 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_41.push(building_report);
                                    }
                                });
                            });
                            if (filtered_41.length) {
                                return _this.database.deleteBulk(_this.tableReportsTentQuestionnaireAnswers, filtered_41.map(function (ids) {
                                    return [ids.report_id, ids.building_report_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuildingReportWarehouseQuestionnaire = function (report_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsWarehouseQuestionnaireAnswers, report_ids.map(function (ids) { return ids.rid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteReportSendToClient = function (client_ids, report_ids) {
        var _this = this;
        if (client_ids === void 0) { client_ids = null; }
        if (report_ids === void 0) { report_ids = null; }
        return this.database.getAllFast(this.tableReportsSendToClient)
            .then(function (reports_send_to_client) {
            if (typeof reports_send_to_client !== 'undefined') {
                if (reports_send_to_client.length) {
                    var filtered_42 = [];
                    if (client_ids !== null) {
                        reports_send_to_client.forEach(function (report_send_to_client) {
                            client_ids.forEach(function (client_id) {
                                if (report_send_to_client.client_id === client_id.cid) {
                                    filtered_42.push(report_send_to_client.id);
                                }
                            });
                        });
                    }
                    if (report_ids !== null) {
                        reports_send_to_client.forEach(function (report_send_to_client) {
                            report_ids.forEach(function (report_id) {
                                if (report_send_to_client.report_id === report_id.rid) {
                                    filtered_42.push(report_send_to_client.id);
                                }
                            });
                        });
                    }
                    if (filtered_42.length) {
                        filtered_42 = __spread(new Set(filtered_42));
                        return _this.database.deleteBulk(_this.tableReportsSendToClient, filtered_42)
                            .then(function () { return true; })
                            .catch(function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            }
            return true;
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteCountry = function (country_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableCountries, country_ids.map(function (ids) { return ids.cid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteInspector = function (inspector_ids) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.database.deleteBulk(_this.tableInspectors, inspector_ids.map(function (ids) { return ids.aid; }))
                .then(function () { return true; })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableInspectorsInspections)
                .then(function (inspections) {
                if (typeof inspections !== 'undefined') {
                    if (inspections.length) {
                        var filtered_43 = [];
                        inspections.forEach(function (inspection) {
                            inspector_ids.forEach(function (inspector_id) {
                                if (inspection.inspector_id === inspector_id.aid) {
                                    filtered_43.push(inspection.id);
                                }
                            });
                        });
                        if (filtered_43.length) {
                            filtered_43 = __spread(new Set(filtered_43));
                            return _this.database.deleteBulk(_this.tableInspectorsInspections, filtered_43)
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteLanguage = function (language_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableLanguages, language_ids.map(function (ids) { return ids.lid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteBuildingParticulars = function (particular_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsBuildingParticulars, particular_ids.map(function (ids) { return ids.bpid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteLadderCategory = function (category_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsLadderCategories, category_ids.map(function (ids) { return ids.lcid; }))
            .then(function () { return _this.deleteLadderSubcategory(category_ids); })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteLadderSubcategory = function (category_ids, subcategory_ids) {
        var _this = this;
        if (subcategory_ids === void 0) { subcategory_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (subcategory_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsLadderSubcategories, subcategory_ids.map(function (ids) {
                    return [ids.lsid, ids.lcid];
                }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (category_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsLadderSubcategories)
                    .then(function (subcategories) {
                    if (typeof subcategories !== 'undefined') {
                        if (subcategories.length) {
                            var filtered_44 = [];
                            subcategories.forEach(function (subcategory) {
                                category_ids.forEach(function (category_id) {
                                    if (subcategory.category_id === category_id.lcid) {
                                        filtered_44.push(subcategory);
                                    }
                                });
                            });
                            if (filtered_44.length) {
                                return _this.database.deleteBulk(_this.tableReportsLadderSubcategories, filtered_44.map(function (ids) {
                                    return [ids.id, ids.category_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteLadderMaterial = function (material_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsLadderMaterials, material_ids.map(function (ids) { return ids.lmid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteLadderQuestionnaire = function (questionnaire_ids) {
        var _this = this;
        return this.database.delete(this.tableReportsLadderQuestionnaire, questionnaire_ids.map(function (ids) { return ids.lqid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteLadderCategorySubcategoryQuestionnaire = function (questionnaire_ids) {
        var _this = this;
        return this.database.getAllFast(this.tableReportsLadderQuestionnaire)
            .then(function (questionnaires) {
            if (typeof questionnaires !== 'undefined') {
                if (questionnaires.length) {
                    var filtered_45 = [];
                    questionnaires.forEach(function (questionnaire) {
                        questionnaire_ids.forEach(function (questionnaire_id) {
                            if (questionnaire.category_id === questionnaire_id.lcid && questionnaire.subcategory_id === questionnaire_id.lsid && questionnaire.id === questionnaire_id.lqid) {
                                filtered_45.push(questionnaire.id);
                            }
                        });
                    });
                    if (filtered_45.length) {
                        filtered_45 = __spread(new Set(filtered_45));
                        return _this.database.deleteBulk(_this.tableReportsLadderQuestionnaire, filtered_45)
                            .then(function () { return true; })
                            .catch(function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            }
            return true;
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteWarehouseAction = function (action_ids) {
        var _this = this;
        return this.database.getAllFast(this.tableReportsWarehouseActions)
            .then(function (actions) {
            if (actions !== 'undefined') {
                if (actions.length) {
                    var filtered_46 = [];
                    actions.forEach(function (action) {
                        action_ids.forEach(function (action_id) {
                            if (action.id === action_id.waid) {
                                filtered_46.push(action);
                            }
                        });
                    });
                    if (filtered_46.length) {
                        return _this.database.deleteBulk(_this.tableReportsWarehouseActions, filtered_46.map(function (ids) {
                            return [ids.id, ids.category_id, ids.subcategory_id];
                        }))
                            .then(function () { return true; })
                            .catch(function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            }
            return true;
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteWarehouseCategory = function (category_ids) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.database.deleteBulk(_this.tableReportsWarehouseCategories, category_ids.map(function (ids) { return ids.wcid; }))
                .then(function () { return true; })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableReportsWarehouseActions)
                .then(function (actions) {
                if (typeof actions !== 'undefined') {
                    if (actions.length) {
                        var filtered_47 = [];
                        actions.forEach(function (action) {
                            category_ids.forEach(function (category_id) {
                                if (action.category_id === category_id.wcid) {
                                    filtered_47.push(action);
                                }
                            });
                        });
                        if (filtered_47.length) {
                            return _this.database.deleteBulk(_this.tableReportsWarehouseActions, filtered_47.map(function (ids) {
                                return [ids.id, ids.category_id, ids.subcategory_id];
                            }))
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.database.getAllFast(_this.tableReportsWarehouseTypes)
                .then(function (types) {
                if (typeof types !== 'undefined') {
                    if (types.length) {
                        var filtered_48 = [];
                        types.forEach(function (type) {
                            category_ids.forEach(function (category_id) {
                                if (type.category_id === category_id.wcid) {
                                    filtered_48.push(type);
                                }
                            });
                        });
                        if (filtered_48.length) {
                            return _this.database.deleteBulk(_this.tableReportsWarehouseTypes, filtered_48.map(function (ids) {
                                return [ids.id, ids.category_id, ids.subcategory_id];
                            }))
                                .then(function () { return true; })
                                .catch(function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.deleteWarehouseSubcategory(category_ids));
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteWarehouseSubcategory = function (category_ids, subcategory_ids) {
        var _this = this;
        if (category_ids === void 0) { category_ids = null; }
        if (subcategory_ids === void 0) { subcategory_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (subcategory_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsWarehouseSubcategories, subcategory_ids.map(function (ids) {
                    return [ids.wsid, ids.wcid];
                }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsWarehouseActions)
                    .then(function (actions) {
                    if (typeof actions !== 'undefined') {
                        if (actions.length) {
                            var filtered_49 = [];
                            actions.forEach(function (action) {
                                subcategory_ids.forEach(function (subcategory_id) {
                                    if (action.subcategory_id === subcategory_id.wsid) {
                                        filtered_49.push(action);
                                    }
                                });
                            });
                            if (filtered_49.length) {
                                return _this.database.deleteBulk(_this.tableReportsWarehouseActions, filtered_49.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsWarehouseTypes)
                    .then(function (types) {
                    if (typeof types !== 'undefined') {
                        if (types.length) {
                            var filtered_50 = [];
                            types.forEach(function (type) {
                                subcategory_ids.forEach(function (subcategory_id) {
                                    if (type.subcategory_id === subcategory_id.wsid) {
                                        filtered_50.push(type);
                                    }
                                });
                            });
                            if (filtered_50.length) {
                                return _this.database.deleteBulk(_this.tableReportsWarehouseTypes, filtered_50.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (category_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsWarehouseSubcategories)
                    .then(function (subcategories) {
                    if (typeof subcategories !== 'undefined') {
                        if (subcategories.length) {
                            var filtered_51 = [];
                            subcategories.forEach(function (subcategory) {
                                category_ids.forEach(function (category_id) {
                                    if (subcategory.category_id === category_id.wcid) {
                                        filtered_51.push(subcategory);
                                    }
                                });
                            });
                            if (filtered_51.length) {
                                return _this.database.deleteBulk(_this.tableReportsWarehouseSubcategories, filtered_51.map(function (ids) {
                                    return [ids.id, ids.category_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsWarehouseActions)
                    .then(function (actions) {
                    if (typeof actions !== 'undefined') {
                        if (actions.length) {
                            var filtered_52 = [];
                            actions.forEach(function (action) {
                                category_ids.forEach(function (category_id) {
                                    if (action.category_id === category_id.wcid) {
                                        filtered_52.push(action);
                                    }
                                });
                            });
                            if (filtered_52.length) {
                                return _this.database.deleteBulk(_this.tableReportsWarehouseActions, filtered_52.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
                promises.push(_this.database.getAllFast(_this.tableReportsWarehouseTypes)
                    .then(function (types) {
                    if (typeof types !== 'undefined') {
                        if (types.length) {
                            var filtered_53 = [];
                            types.forEach(function (type) {
                                category_ids.forEach(function (category_id) {
                                    if (type.category_id === category_id.wcid) {
                                        filtered_53.push(type);
                                    }
                                });
                            });
                            if (filtered_53.length) {
                                return _this.database.deleteBulk(_this.tableReportsWarehouseTypes, filtered_53.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteWarehouseQuestionnaire = function (questionnaire_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsWarehouseQuestionnaire, questionnaire_ids.map(function (ids) { return ids.wqid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteWarehouseType = function (type_ids) {
        var _this = this;
        return this.database.getAllFast(this.tableReportsWarehouseTypes)
            .then(function (types) {
            if (typeof types !== 'undefined') {
                if (types.length) {
                    var filtered_54 = [];
                    types.forEach(function (type) {
                        type_ids.forEach(function (type_id) {
                            if (type.id === type_id.wtid) {
                                filtered_54.push(type);
                            }
                        });
                    });
                    if (filtered_54.length) {
                        return _this.database.deleteBulk(_this.tableReportsWarehouseTypes, filtered_54.map(function (ids) {
                            return [ids.id, ids.category_id, ids.subcategory_id];
                        }))
                            .then(function () { return true; })
                            .catch(function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            }
            return true;
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteWarehouseCategorySubcategoryAction = function (action_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsWarehouseActions, action_ids.map(function (ids) {
            return [ids.waid, ids.wcid, ids.wsid];
        }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteWarehouseCategorySubcategoryType = function (type_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsWarehouseTypes, type_ids.map(function (ids) {
            return [ids.wtid, ids.wcid, ids.wsid];
        }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteTentCategory = function (category_ids) {
        var _this = this;
        return this.database.deleteBulk(this.tableReportsTentCategories, category_ids.map(function (ids) { return ids.tcid; }))
            .then(function () { return _this.deleteTentSubcategory(category_ids); })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteTentSubcategory = function (category_ids, subcategory_ids) {
        var _this = this;
        if (subcategory_ids === void 0) { subcategory_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (subcategory_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsTentSubcategories, subcategory_ids.map(function (ids) {
                    return [ids.tsid, ids.tcid];
                }))
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            if (category_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsTentSubcategories)
                    .then(function (subcategories) {
                    if (typeof subcategories !== 'undefined') {
                        if (subcategories.length) {
                            var filtered_55 = [];
                            subcategories.forEach(function (subcategory) {
                                category_ids.forEach(function (category_id) {
                                    if (subcategory.category_id === category_id.tcid) {
                                        filtered_55.push(subcategory);
                                    }
                                });
                            });
                            if (filtered_55.length) {
                                return _this.database.deleteBulk(_this.tableReportsTentSubcategories, filtered_55.map(function (ids) {
                                    return [ids.id, ids.category_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteTentQuestionnaire = function (questionnaire_ids) {
        var _this = this;
        return this.database.delete(this.tableReportsTentQuestionnaire, questionnaire_ids.map(function (ids) { return ids.tqid; }))
            .then(function () { return true; })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.deleteTentCategorySubcategoryQuestionnaire = function (questionnaire_ids) {
        var _this = this;
        return this.database.getAllFast(this.tableReportsTentQuestionnaire)
            .then(function (questionnaires) {
            if (typeof questionnaires !== 'undefined') {
                if (questionnaires.length) {
                    var filtered_56 = [];
                    questionnaires.forEach(function (questionnaire) {
                        questionnaire_ids.forEach(function (questionnaire_id) {
                            if (questionnaire.category_id === questionnaire_id.tcid && questionnaire.subcategory_id === questionnaire_id.tsid && questionnaire.id === questionnaire_id.tqid) {
                                filtered_56.push(questionnaire.id);
                            }
                        });
                    });
                    if (filtered_56.length) {
                        filtered_56 = __spread(new Set(filtered_56));
                        return _this.database.deleteBulk(_this.tableReportsTentQuestionnaire, filtered_56)
                            .then(function () { return true; })
                            .catch(function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            }
            return true;
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.redirectUser = function (dataParams) {
        var _this = this;
        var params = this.previousUrlService.routeData;
        setTimeout(function () {
            if (typeof params !== 'undefined' && typeof dataParams !== 'undefined') {
                if (params !== null && dataParams !== null) {
                    if (typeof params['client_id'] !== 'undefined' && typeof dataParams['client_id'] !== 'undefined' && typeof dataParams['old_client_id'] !== 'undefined') {
                        if (parseInt(dataParams['old_client_id'], 10) === parseInt(params['client_id'], 10)) {
                            _this.router.navigate(['/clients/' + dataParams['client_id'] + '/view']).then();
                            return true;
                        }
                    }
                    if (typeof params['calibration_id'] !== 'undefined' && typeof dataParams['calibration_id'] !== 'undefined' && typeof dataParams['old_calibration_id'] !== 'undefined') {
                        if (parseInt(dataParams['old_calibration_id'], 10) === parseInt(params['calibration_id'], 10)) {
                            _this.router.navigate(['/inspectors/calibrations/' + dataParams['calibration_id'] + '/view']).then();
                        }
                    }
                    if (typeof params['inventory_id'] !== 'undefined' && typeof dataParams['inventory_id'] !== 'undefined' && typeof dataParams['old_inventory_id'] !== 'undefined') {
                        if (parseInt(dataParams['old_inventory_id'], 10) === parseInt(params['inventory_id'], 10)) {
                            _this.router.navigate(['/inspectors/inventory/' + dataParams['inventory_id'] + '/view']).then();
                            return true;
                        }
                    }
                    if (typeof params['report_id'] !== 'undefined' && typeof dataParams['report_id'] !== 'undefined') {
                        if (typeof params['building_id'] !== 'undefined' && typeof dataParams['building_id'] !== 'undefined') {
                            if (typeof params['building_report_id'] !== 'undefined' && typeof dataParams['building_report_id'] !== 'undefined' && typeof dataParams['old_building_id'] !== 'undefined') {
                                if (parseInt(dataParams['old_building_report_id'], 10) === parseInt(params['building_id'], 10)) {
                                    _this.router.navigate(['/reports/' + params['report_type'] + '/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports/' + dataParams['building_report_id'] + '/edit']).then();
                                    return true;
                                }
                            }
                            if (typeof dataParams['old_building_id'] !== 'undefined') {
                                if (parseInt(dataParams['old_building_id'], 10) === parseInt(params['building_id'], 10)) {
                                    _this.router.navigate(['/reports/' + params['report_type'] + '/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports']).then();
                                    return true;
                                }
                            }
                        }
                        if (typeof dataParams['report_id'] !== 'undefined' && typeof dataParams['old_report_id'] !== 'undefined') {
                            if (parseInt(dataParams['old_report_id'], 10) === parseInt(params['report_id'], 10)) {
                                _this.router.navigate(['/reports/' + params['report_type'] + '/' + dataParams['report_id'] + '/view']).then();
                                return true;
                            }
                        }
                    }
                }
            }
        }, 1500);
    };
    return IndexedDBService;
}());
export { IndexedDBService };
