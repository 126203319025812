import {Injectable} from '@angular/core';
import {NgxIndexedDB} from 'ngx-indexed-db/dist/ngx-indexed-db';
import {ApiService} from '../_interceptors/api.service';
import {ServerResponse} from '../_interfaces/server.response';
import {Languages} from '../_interfaces/languages';
import {Countries} from '../_interfaces/countries';
import {Client} from '../_interfaces/clients/clients';
import {BehaviorSubject, Subscription} from 'rxjs/Rx';
import {ClientsContactsPersons} from '../_interfaces/clients/clients-contacts-persons';
import {ClientsAttachments} from '../_interfaces/clients/clients-attachment';
import {InspectorsInventory} from '../_interfaces/inspectors/inspectors-inventory';
import {FileInput} from 'ngx-material-file-input';
import {InspectorsCalibration} from '../_interfaces/inspectors/inspectors-calibration';
import {User} from '../_interfaces/user';
import {ReportBuildingParticulars} from '../_interfaces/reports/report-building-particulars';
import {ReportsShortcomingsCategories} from '../_interfaces/reports/reports-shortcomings-categories';
import {ReportsShortcomingsSubcategories} from '../_interfaces/reports/reports-shortcomings-subcategories';
import {ReportsShortcomingsTypes} from '../_interfaces/reports/reports-shortcomings-types';
import {ReportsShortcomingsActions} from '../_interfaces/reports/reports-shortcomings-actions';
import {ReportsQuestions} from '../_interfaces/reports/reports-questions';
import {ReportsQuestionsAnswers} from '../_interfaces/reports/reports-questions-answers';
import {ReportsBuildingsShortcomingsQuestionnaire} from '../_interfaces/reports/reports-buildings-shortcomings-questionnaire';
import {
	ReportsBuildingsShortcomingsQuestionnaireAnswers
} from '../_interfaces/reports/reports-buildings-shortcomings-questionnaire-answers';
import {Reports} from '../_interfaces/reports/reports';
import {ReportsAttachments} from '../_interfaces/reports/reports-attachments';
import {ReportsComments} from '../_interfaces/reports/reports-comments';
import {ReportsBuildings} from '../_interfaces/reports/reports-buildings';
import {ReportsBuildingsReports} from '../_interfaces/reports/reports-buildings-reports';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {InspectorsInventorySentTo} from '../_interfaces/inspectors/inspectors-inventory-sent-to';
import {PreviousUrlService} from './previous-url.service';
import {SnackbarService} from './snackbar.service';
import {AuthenticationService} from './authentication.service';

import {LoadingOverlayService} from './loading-overlay.service';
import {InspectorsInspections} from '../_interfaces/inspectors/inspectors-inspections';
import {environment} from '../../environments/environment';
import {ClientsBuildingMaps} from '../_interfaces/clients/clients-building-maps';
import {ReportsBuildingsMaps} from '../_interfaces/reports/reports-buildings-maps';
import {dbVersion} from '../../environments/version';

@Injectable()

export class IndexedDBService {

	private syncStart: number = 0;

	public database = null;
	public databaseReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public syncComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public tableDataSync: string = 'data_sync';
	public tableLanguages: string = 'languages';
	public tableCountries: string = 'countries';
	public tableClients: string = 'clients';
	public tableClientsPush: string = 'clients_push';
	public tableClientsAttachments: string = 'clients_attachments';
	public tableClientsAttachmentsPush: string = 'clients_attachments_push';
	public tableClientsContactPersons: string = 'clients_contact_persons';
	public tableClientsContactPersonsPush: string = 'clients_contact_persons_push';
	public tableClientsBuildingsMaps: string = 'clients_buildings_maps';
	public tableClientsBuildingsMapsPush: string = 'clients_buildings_maps_push';
	public tableInspectorsInventory: string = 'inspectors_inventory';
	public tableInspectorsInventoryPush: string = 'inspectors_inventory_push';
	public tableInspectorsInventorySendToClient: string = 'inspectors_inventory_send_to_client';
	public tableInspectorsCalibrations: string = 'inspectors_calibrations';
	public tableInspectorsCalibrationsPush: string = 'inspectors_calibrations_push';
	public tableInspectorsInspections: string = 'inspectors_inspections';
	public tableInspectors: string = 'inspectors';
	public tableReportsBuildingParticulars: string = 'reports_building_particulars';
	public tableReportsWarehouseCategories: string = 'reports_warehouse_categories';
	public tableReportsWarehouseSubcategories: string = 'reports_warehouse_subcategories';
	public tableReportsWarehouseActions: string = 'reports_warehouse_actions';
	public tableReportsWarehouseTypes: string = 'reports_warehouse_types';
	public tableReportsWarehouseQuestionnaire: string = 'reports_warehouse_questionnaire';
	public tableReportsWarehouseQuestionnaireAnswers: string = 'reports_warehouse_questionnaire_answers';
	public tableReportsLadderCategories: string = 'reports_ladder_categories';
	public tableReportsLadderSubcategories: string = 'reports_ladder_subcategories';
	public tableReportsLadderMaterials: string = 'reports_ladder_materials';
	public tableReportsLadderQuestionnaire: string = 'reports_ladder_questionnaire';
	public tableReportsLadderQuestionnaireAnswers: string = 'reports_ladder_questionnaire_answers';
	public tableReportsTentCategories: string = 'reports_tent_categories';
	public tableReportsTentSubcategories: string = 'reports_tent_subcategories';
	public tableReportsTentMaterials: string = 'reports_tent_materials';
	public tableReportsTentQuestionnaire: string = 'reports_tent_questionnaire';
	public tableReportsTentQuestionnaireAnswers: string = 'reports_tent_questionnaire_answers';
	public tableReportsAttachments: string = 'reports_attachments';
	public tableReportsAttachmentsPush: string = 'reports_attachments_push';
	public tableReports: string = 'reports';
	public tableReportsPush: string = 'reports_push';
	public tableReportsComments: string = 'reports_comments';
	public tableReportsCommentsPush: string = 'reports_comments_push';
	public tableReportsBuildings: string = 'reports_buildings';
	public tableReportsBuildingsPush: string = 'reports_buildings_push';
	public tableReportsBuildingsMaps: string = 'reports_buildings_maps';
	public tableReportsBuildingsMapsPush: string = 'reports_buildings_maps_push';
	public tableReportsBuildingsReports: string = 'reports_buildings_reports';
	public tableReportsBuildingsReportsPush: string = 'reports_buildings_reports_push';
	public tableReportsBuildingsReportsPhotos: string = 'reports_buildings_reports_photos';
	public tableReportsSendToClient: string = 'reports_send_to_client';
	public tableReportsReinspections: string = 'reports_reinspections';
	public tableReportsVerifyInspectorStatus: string = 'reports_verify_inspector_status';
	public tableSettings: string = 'settings';

	private dbVersion: number = dbVersion;
	private dbName: string = 'KVVM';
	private debug: boolean = !environment.production;
	private tryToCreateDatabase: number = 10;
	private databaseCreationTries: number = 0;

	// can be removed in future
	private wasUpdated: boolean = false;

	private _authenticationService: Subscription = null;
	private _authenticationServiceForClients: Subscription = null;

	constructor(private apiService: ApiService,
				private loadingOverlayService: LoadingOverlayService,
				private authenticationService: AuthenticationService,
				private activatedRoute: ActivatedRoute,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private router: Router) {
		this.openIndexedDatabase();
	}

	private openIndexedDatabase(): void {
		this.database = new NgxIndexedDB(this.dbName, this.dbVersion);
		this.database.openDatabase(this.dbVersion, (evt: IDBVersionChangeEvent) => this.updateDatabase(evt))
			.then(() => {
				if (this.validateDatabase()) {
					this.databaseReady.next(true);

					if (this.wasUpdated) {
						this.database.delete('data_sync', 'repors_ladder_questionnaire');
					}

					this.syncStart = new Date().getTime();
					this.syncAll();

					this.registerToEvents(this.apiService);

					// if online, sync data once per hour
					setInterval(() => {
						if (this.apiService.isOnline) {
							this.syncAll();
						}
					}, (60 * 60) * 1000);

					// sync clients more frequently
					setInterval(() => {
						if (this.apiService.isOnline) {
							this.syncClientsData();
						}
					}, (60 * 5) * 1000);
				}
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);

			});
	}

	private updateDatabase(evt: IDBVersionChangeEvent): boolean {
		let db = (<IDBOpenDBRequest>evt.target).result;
		if (db.objectStoreNames.contains(this.tableDataSync) === false) {
			this.createDataSyncTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableLanguages) === false) {
			this.createLanguagesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableCountries) === false) {
			this.createCountriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClients) === false) {
			this.createClientsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClientsPush) === false) {
			this.createClientsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClientsAttachments) === false) {
			this.createClientsAttachmentsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClientsAttachmentsPush) === false) {
			this.createClientsAttachmentsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClientsContactPersons) === false) {
			this.createClientsContactPersonsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClientsContactPersonsPush) === false) {
			this.createClientsContactPersonsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClientsBuildingsMaps) === false) {
			this.createClientsBuildingMapsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableClientsBuildingsMapsPush) === false) {
			this.createClientsBuildingMapsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableInspectorsInventory) === false) {
			this.createInspectorsInventoryTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableInspectorsInventoryPush) === false) {
			this.createInspectorsInventoryPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableInspectorsInventorySendToClient) === false) {
			this.createInspectorsInventorySendToClientTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableInspectorsCalibrations) === false) {
			this.createInspectorsCalibrationsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableInspectorsCalibrationsPush) === false) {
			this.createInspectorsCalibrationsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableInspectorsInspections) === false) {
			this.createInspectorsInspectionsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableInspectors) === false) {
			this.createInspectorsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingParticulars) === false) {
			this.createReportsBuildingParticularsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsWarehouseCategories) === false) {
			this.createReportsWarehouseCategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsWarehouseSubcategories) === false) {
			this.createReportsWarehouseSubcategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsLadderCategories) === false) {
			this.createReportsLadderCategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsLadderSubcategories) === false) {
			this.createReportsLadderSubcategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsWarehouseActions) === false) {
			this.createReportsWarehouseActionsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsWarehouseTypes) === false) {
			this.createReportsWarehouseTypesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaire) === false) {
			this.createReportsWarehouseQuestionnaireTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaireAnswers) === false) {
			this.createReportsWarehouseQuestionnaireAnswersTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsLadderMaterials) === false) {
			this.createReportsLadderMaterialsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsLadderQuestionnaire) === false) {
			this.createReportsLadderQuestionnaireTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsLadderQuestionnaireAnswers) === false) {
			this.createReportsLadderQuestionnaireAnswersTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsTentCategories) === false) {
			this.createReportsTentCategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsTentSubcategories) === false) {
			this.createReportsTentSubcategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsTentMaterials) === false) {
			this.createReportsTentMaterialsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsTentQuestionnaire) === false) {
			this.createReportsTentQuestionnaireTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsTentQuestionnaireAnswers) === false) {
			this.createReportsTentQuestionnaireAnswersTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsAttachments) === false) {
			this.createReportsAttachmentsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false) {
			this.createReportsAttachmentsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReports) === false) {
			this.createReportsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsPush) === false) {
			this.createReportsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsComments) === false) {
			this.createReportsCommentsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsCommentsPush) === false) {
			this.createReportsCommentsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildings) === false) {
			this.createReportsBuildingsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false) {
			this.createReportsBuildingsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false) {
			this.createReportsBuildingsReportsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false) {
			this.createReportsBuildingsReportsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false) {
			this.createReportsBuildingsReportsPhotosTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsSendToClient) === false) {
			this.createReportsSendToClientTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsReinspections) === false) {
			this.createReportsReinspectionsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsMaps) === false) {
			this.createReportsBuildingsMapsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsMapsPush) === false) {
			this.createReportsBuildingsMapsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsVerifyInspectorStatus) === false) {
			this.createReportsVerifyInspectorStatusTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableSettings) === false) {
			this.createSettingsTable(evt);
		}
		this.updateReportsLadderQuestionnaireTable(evt);

		return true;
	}

	private validateDatabase(): boolean {
		let db = this.database.dbWrapper.db;

		if (this.databaseCreationTries > this.tryToCreateDatabase) {
			this.loadingOverlayService.show();
			this.snackbarService.error('A serious error occurred with the database. Please reload the app.');
			this.database.deleteDatabase(this.dbName);
			return false;
		}

		this.databaseCreationTries++;

		if (db.objectStoreNames.contains(this.tableDataSync) === false ||
			db.objectStoreNames.contains(this.tableLanguages) === false ||
			db.objectStoreNames.contains(this.tableCountries) === false ||
			db.objectStoreNames.contains(this.tableClients) === false ||
			db.objectStoreNames.contains(this.tableClientsPush) === false ||
			db.objectStoreNames.contains(this.tableClientsAttachments) === false ||
			db.objectStoreNames.contains(this.tableClientsAttachmentsPush) === false ||
			db.objectStoreNames.contains(this.tableClientsContactPersons) === false ||
			db.objectStoreNames.contains(this.tableClientsContactPersonsPush) === false ||
			db.objectStoreNames.contains(this.tableClientsBuildingsMaps) === false ||
			db.objectStoreNames.contains(this.tableClientsBuildingsMapsPush) === false ||
			db.objectStoreNames.contains(this.tableInspectorsInventory) === false ||
			db.objectStoreNames.contains(this.tableInspectorsInventoryPush) === false ||
			db.objectStoreNames.contains(this.tableInspectorsInventorySendToClient) === false ||
			db.objectStoreNames.contains(this.tableInspectorsCalibrations) === false ||
			db.objectStoreNames.contains(this.tableInspectorsCalibrationsPush) === false ||
			db.objectStoreNames.contains(this.tableInspectorsInspections) === false ||
			db.objectStoreNames.contains(this.tableInspectors) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingParticulars) === false ||
			db.objectStoreNames.contains(this.tableReportsWarehouseCategories) === false ||
			db.objectStoreNames.contains(this.tableReportsWarehouseSubcategories) === false ||
			db.objectStoreNames.contains(this.tableReportsLadderCategories) === false ||
			db.objectStoreNames.contains(this.tableReportsLadderSubcategories) === false ||
			db.objectStoreNames.contains(this.tableReportsWarehouseActions) === false ||
			db.objectStoreNames.contains(this.tableReportsWarehouseTypes) === false ||
			db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaire) === false ||
			db.objectStoreNames.contains(this.tableReportsWarehouseQuestionnaireAnswers) === false ||
			db.objectStoreNames.contains(this.tableReportsLadderMaterials) === false ||
			db.objectStoreNames.contains(this.tableReportsLadderQuestionnaire) === false ||
			db.objectStoreNames.contains(this.tableReportsLadderQuestionnaireAnswers) === false ||
			db.objectStoreNames.contains(this.tableReportsTentCategories) === false ||
			db.objectStoreNames.contains(this.tableReportsTentSubcategories) === false ||
			db.objectStoreNames.contains(this.tableReportsTentMaterials) === false ||
			db.objectStoreNames.contains(this.tableReportsTentQuestionnaire) === false ||
			db.objectStoreNames.contains(this.tableReportsTentQuestionnaireAnswers) === false ||
			db.objectStoreNames.contains(this.tableReportsAttachments) === false ||
			db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false ||
			db.objectStoreNames.contains(this.tableReports) === false ||
			db.objectStoreNames.contains(this.tableReportsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsComments) === false ||
			db.objectStoreNames.contains(this.tableReportsCommentsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildings) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false ||
			db.objectStoreNames.contains(this.tableReportsSendToClient) === false ||
			db.objectStoreNames.contains(this.tableReportsReinspections) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsMaps) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsMapsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsVerifyInspectorStatus) === false ||
			db.objectStoreNames.contains(this.tableSettings) === false) {
			console.log('There\'s an issue with the database, we\'ll try to repair it ...');
			this.database.deleteDatabase(this.dbName);
			this.openIndexedDatabase();
			return false;
		}

		return true;
	}

	private registerToEvents(apiService: ApiService) {
		apiService.connectionChanged.subscribe(online => {
			if (online) {
				this.databaseReady.subscribe(event => {
					if (event) {
						console.log('user went online, push any and all changes to the server');
						this.apiService.offlineError.emit(false);

						this.syncStart = new Date().getTime();
						this.startUpdating();
					} else {
						console.log('user went online but database is not yet ready');
					}
				});
			}
		});
	}

	createDataSyncTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableDataSync, {
			keyPath: 'name',
			autoIncrement: false
		});
		object.createIndex('name', 'name', {unique: true});
	}

	createLanguagesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableLanguages, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('language_name', 'language_name', {unique: true});
	}

	createCountriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableCountries, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('country_name', 'country_name', {unique: true});
	}

	createClientsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClients, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('company_name', 'company_name', {unique: false});
	}

	createClientsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClientsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId');
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createClientsAttachmentsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClientsAttachments, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('client_id', 'client_id', {unique: false});
		object.createIndex('created', 'created', {unique: false});
	}

	createClientsAttachmentsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClientsAttachmentsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createClientsContactPersonsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClientsContactPersons, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('client_id', 'client_id', {unique: false});
		object.createIndex('firstname', 'firstname', {unique: false});
	}

	createClientsContactPersonsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClientsContactPersonsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId');
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createClientsBuildingMapsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClientsBuildingsMaps, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('client_id', 'client_id', {unique: false});
		object.createIndex('created', 'created', {unique: false});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createClientsBuildingMapsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableClientsBuildingsMapsPush, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createInspectorsInventoryTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInventory, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('created', 'created', {unique: false});
	}

	createInspectorsInventoryPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInventoryPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createInspectorsInventorySendToClientTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInventorySendToClient, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('created', 'created', {unique: false});
	}

	createInspectorsCalibrationsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableInspectorsCalibrations, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('next_calibration_date', 'next_calibration_date', {unique: false});
	}

	createInspectorsCalibrationsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableInspectorsCalibrationsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId');
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createInspectorsInspectionsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableInspectorsInspections, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('next_inspection_date', 'next_inspection_date', {unique: false});
	}

	createInspectorsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableInspectors, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('firstname', 'firstname', {unique: false});
	}

	createReportsBuildingParticularsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingParticulars, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('building_name', 'building_name', {unique: false});
	}

	createReportsWarehouseCategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseCategories, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('category_name', 'category_name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsWarehouseSubcategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseSubcategories, {
			keyPath: ['id', 'category_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id'], {unique: true});
		object.createIndex('subcategory_name', 'subcategory_name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsLadderCategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderCategories, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('category_name', 'category_name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsLadderSubcategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderSubcategories, {
			keyPath: ['id', 'category_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id'], {unique: true});
		object.createIndex('subcategory_name', 'subcategory_name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsWarehouseActionsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseActions, {
			keyPath: ['id', 'category_id', 'subcategory_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], {unique: true});
		object.createIndex('action_name', 'action_name', {unique: false});
	}

	createReportsWarehouseTypesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseTypes, {
			keyPath: ['id', 'category_id', 'subcategory_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], {unique: true});
		object.createIndex('type_name', 'type_name', {unique: false});
	}

	createReportsWarehouseQuestionnaireTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseQuestionnaire, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: false});
		object.createIndex('name', 'name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsWarehouseQuestionnaireAnswersTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsWarehouseQuestionnaireAnswers, {
			keyPath: 'report_id',
			autoIncrement: true
		});
		object.createIndex('report_id', 'report_id', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsLadderMaterialsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderMaterials, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('material_name', 'material_name', {unique: false});
	}

	createReportsLadderQuestionnaireTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderQuestionnaire, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: false});
		object.createIndex('name', 'name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	updateReportsLadderQuestionnaireTable(evt): void {
		evt.currentTarget.result.deleteObjectStore(this.tableReportsLadderQuestionnaire);

		let object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderQuestionnaire, {
			keyPath: ['id', 'category_id', 'subcategory_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], {unique: true});
		object.createIndex('name', 'name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
		this.wasUpdated = true;
	}

	createReportsLadderQuestionnaireAnswersTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsLadderQuestionnaireAnswers, {
			keyPath: ['report_id', 'building_report_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['report_id', 'building_report_id'], {unique: true});
		object.createIndex('building_report_id', 'building_report_id', {unique: true});
	}

	createReportsTentCategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsTentCategories, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('category_name', 'category_name', {unique: false});
		object.createIndex('star_rating', 'star_rating', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsTentSubcategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsTentSubcategories, {
			keyPath: ['id', 'category_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id'], {unique: true});
		object.createIndex('subcategory_name', 'subcategory_name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsTentMaterialsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsTentMaterials, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('material_name', 'material_name', {unique: false});
	}

	createReportsTentQuestionnaireTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsTentQuestionnaire, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: false});
		object.createIndex('name', 'name', {unique: false});
		object.createIndex('sequence', 'sequence', {unique: false});
	}

	createReportsTentQuestionnaireAnswersTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsTentQuestionnaireAnswers, {
			keyPath: ['report_id', 'building_report_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['report_id', 'building_report_id'], {unique: true});
		object.createIndex('building_report_id', 'building_report_id', {unique: true});
	}

	createReportsAttachmentsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachments, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
		object.createIndex('created', 'created', {unique: false});
	}

	createReportsAttachmentsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachmentsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReports, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('client_id', 'client_id', {unique: false});
		object.createIndex('created', 'created', {unique: false});
	}

	createReportsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsCommentsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsComments, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
		object.createIndex('created', 'created', {unique: false});
	}

	createReportsCommentsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsCommentsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsBuildingsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildings, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
		object.createIndex('name', 'name', {unique: false});
		object.createIndex('created', 'created', {unique: false});
	}

	createReportsBuildingsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsBuildingsReportsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReports, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
		object.createIndex('location_nr', 'location_nr', {unique: false});
		object.createIndex('created', 'created', {unique: false});
	}

	createReportsBuildingsReportsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsBuildingsReportsPhotosTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPhotos, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('building_report_id', 'building_report_id', {unique: false});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsSendToClientTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsSendToClient, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createReportsReinspectionsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsReinspections, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
	}

	createReportsBuildingsMapsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsMaps, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
		object.createIndex('client_id', 'client_id', {unique: false});
		object.createIndex('created', 'created', {unique: false});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsBuildingsMapsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsMapsPush, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsVerifyInspectorStatusTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsVerifyInspectorStatus, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
	}

	createSettingsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableSettings, {
			keyPath: 'name',
			autoIncrement: false
		});
		object.createIndex('name', 'name', {unique: true});
		object.createIndex('value', 'value', {unique: true});
	}

	startUpdating() {
		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}
		this._authenticationService = this.authenticationService.isAuthenticated().subscribe(event => {
			if (event) {
				this.loadingOverlayService.show();
				if (this.debug) {
					console.log((new Date().getTime() - this.syncStart), 'Starting of pushing of data to server ...');
				}
				this.snackbarService.info('Synchronisatie is gestart, dit een moment duren ...');
				let promises = [];
				promises.push(this.pushToServer_ClientsCreate().then(() => {
					return Promise.all([
						this.pushToServer_ClientsUpdate().then(() => {
							return this.pushToServer_ClientsDelete().then(() => true);
						}),
					]).then(() => {
						return Promise.all([
							this.pushToServer_ClientsAttachmentsCreate().then(() => {
								return this.pushToServer_ClientsAttachmentsUpdate().then(() => {
									return this.pushToServer_ClientsAttachmentsDelete().then(() => true);
								});
							}),
							this.pushToServer_ClientsContactPersonsCreate().then(() => {
								return this.pushToServer_ClientsContactPersonsUpdate().then(() => {
									return this.pushToServer_ClientsContactPersonsDelete().then(() => true);
								});
							}),
							this.pushToServer_InspectorsInventoryCreate().then(() => {
								return this.pushToServer_InspectorsInventoryDelete().then(() => {
									return this.pushToServer_InspectorsInventorySendToClient().then(() => true);
								});
							}),
							this.pushToServer_ReportsCreate().then(() => {
								return Promise.all([
									this.pushToServer_ReportsUpdate().then(() => {
										return this.pushToServer_ReportsDelete().then(() => true);
									})
								]).then(() => {
									return Promise.all([
										this.pushToServer_ReportsAttachmentsCopy().then(() => {
											return this.pushToServer_ReportsAttachmentsCreate().then(() => {
												return this.pushToServer_ReportsAttachmentsUpdate().then(() => {
													return this.pushToServer_ReportsAttachmentsDelete().then(() => true);
												});
											});
										}),
										this.pushToServer_ReportsCommentsCreate().then(() => {
											return this.pushToServer_ReportsCommentsUpdate().then(() => {
												return this.pushToServer_ReportsCommentsDelete().then(() => true);
											});
										}),
										this.pushToServer_ReportsWarehouseQuestionnaires().then(() => true),
										this.pushToServer_ReportsBuildingsCreate().then(() => {
											return this.pushToServer_ReportsBuildingsUpdate().then(() => {
												return this.pushToServer_ReportsBuildingsDelete().then(() => {
													return this.pushToServer_ReportsBuildingsReportsCreate().then(() => {
														return this.pushToServer_ReportsBuildingsReportsUpdate().then(() => {
															return this.pushToServer_ReportsBuildingsReportsDelete().then(() => {
																return this.pushToServer_ReportsBuildingsReportsPhotosCreate().then(() => {
																	return this.pushToServer_ReportsBuildingsReportsPhotosDelete().then(() => {
																		return this.pushToServer_ReportsSendToClient().then(() => {
																			return this.pushToServer_ReportsVerifyInspectorStatus().then(() => {
																				return this.pushToServer_ReportsBuildingsMapsCreate().then(() => {
																					return this.pushToServer_ReportsBuildingsMapsUpdate().then(() => {
																						return this.pushToServer_ReportsBuildingsMapsDelete().then(() => true);
																					});
																				});
																			});
																		});
																	});
																});
															});
														});
													});
												});
											});
										})
									]);
								});
							})
						]).then(() => {
							return true;
						}).catch(error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}));
				promises.push(this.pushToServer_InspectorsCalibrationsCreate().then(() => {
					return this.pushToServer_InspectorsCalibrationsUpdate().then(() => {
						return this.pushToServer_InspectorsCalibrationsDelete().then(() => true);
					});
				}));

				Promise.all(promises).then(() => {
					if (this.debug) {
						console.log((new Date().getTime() - this.syncStart), 'pushToServer all done, starting sync ....');
					}
					this.syncAll(true);

					return true;
				}).catch(error => {
					this.loadingOverlayService.hide();
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			}
		});
	}

	syncClientsData(): void {
		if (this._authenticationServiceForClients !== null) {
			this._authenticationServiceForClients.unsubscribe();
		}
		this._authenticationServiceForClients = this.authenticationService.isAuthenticated().subscribe(event => {
			if (event) {
				if (this.debug) {
					console.log((new Date().getTime() - this.syncStart), 'start of syncing of client data ...');
				}
				return Promise.all([
					this.syncClients(),
					this.syncClientsAttachments(),
					this.syncClientsBuildingMaps(),
					this.syncClientsContactPersons()
				]).then(() => {
					if (this.debug) {
						console.log((new Date().getTime() - this.syncStart), 'sync of client data done');
					}
					this.loadingOverlayService.hide();
				});
			}
		});
	}

	syncAll(showMessage: boolean = false) {
		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}
		this._authenticationService = this.authenticationService.isAuthenticated().subscribe(event => {
			if (event) {
				if (this.debug) {
					console.log((new Date().getTime() - this.syncStart), 'starting of syncing of data ...');
				}
				return Promise.all([
					this.syncDeletedItems().then(() => {
						return Promise.all([
							this.syncClosedReports(),
							this.syncLanguages(),
							this.syncCountries(),
							this.syncClients(),
							this.syncClientsAttachments(),
							this.syncClientsBuildingMaps(),
							this.syncClientsContactPersons(),

							this.syncInspectorCalibrations(),
							this.syncInspectorInventory(),
							this.syncInspectorInspections(),

							this.syncInspectors(),
							this.syncBuildingParticulars(),
							this.syncWarehouseCategories(),
							this.syncWarehouseSubcategories(),
							this.syncWarehouseQuestionnaires(),
							this.syncWarehouseQuestionnaireAnswers(),
							this.syncTypes(),
							this.syncActions(),
							this.syncLadderMaterials(),

							this.syncLadderCategories(),
							this.syncLadderSubcategories(),
							this.syncLadderQuestionnaires(),
							this.syncLadderQustionnaireAnswers(),

							this.syncTentCategories(),
							this.syncTentSubcategories(),
							this.syncTentQuestionnaires(),
							this.syncTentQustionnaireAnswers(),

							this.syncReinspectionReports(),
							this.syncReports(),
							this.syncReportsAttachments(),
							this.syncReportsComments(),
							this.syncReportsBuildings(),
							this.syncReportsBuildingsReports(),
							this.syncReportsBuildingsMaps(),
							this.syncSettings(),
						]);
					})
				]).then(() => {
					if (this.debug) {
						console.log((new Date().getTime() - this.syncStart), 'sync done');
					}
					this.loadingOverlayService.hide();
					if (showMessage) {
						this.snackbarService.info('Synchronisatie voltooid!');
					}
					this.syncComplete.next(true);
				}).catch(() => {
					this.loadingOverlayService.hide();
				});
			}
		});
	}

	syncSettings(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.apiService.get(this.tableSettings).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									let start = new Date().getTime(),
										settings = [];
									Object.keys(data.data).map(key => {
										settings.push({
											name: key,
											value: data.data[key]
										});
									});
									promises.push(this.database.updateBulk(this.tableSettings, settings)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										})
									);

									return Promise.all(promises).then(() => {
										if (this.debug) {
											console.log((new Date().getTime() - start), 'syncSettings done');
										}
										resolve();
									});
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					} else {
						resolve();
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncDeletedItems(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, 'deleted_items').then(user_device_date => {
					this.apiService.get('deleted-items/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('deleted-items', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime(),
																	inspector_inventories = [],
																	inspector_calibrations = [],
																	clients = [],
																	client_attachments = [],
																	client_contact_persons = [],
																	client_building_maps = [],
																	reports = [],
																	reports_attachments = [],
																	reports_buildings = [],
																	reports_buildings_reports = [],
																	reports_buildings_reports_photos = [],
																	reports_buildings_maps = [],
																	countries = [],
																	languages = [],
																	inspectors = [],
																	building_particulars = [],
																	warehouse_categories = [],
																	warehouse_subcategories = [],
																	warehouse_types = [],
																	warehouse_actions = [],
																	warehouse_category_subcategory_type = [],
																	warehouse_category_subcategory_action = [],
																	warehouse_questionnaires = [],
																	ladder_categories = [],
																	ladder_subcategories = [],
																	ladder_materials = [],
																	ladder_questionnaires = [],
																	ladder_category_subcategory_questionnaire = [],
																	tent_categories = [],
																	tent_subcategories = [],
																	tent_questionnaires = [],
																	tent_category_subcategory_questionnaire = [];

																if (this.debug) {
																	console.log((new Date().getTime() - start), 'delete: started ...');
																}
																data.data.forEach(data2 => {
																	if (data2.iiid !== null) {
																		inspector_inventories.push({iiid: data2.iiid});
																	}

																	if (data2.icid !== null) {
																		inspector_calibrations.push({icid: data2.icid});
																	}

																	if (data2.cid !== null &&
																		data2.caid === null &&
																		data2.ccpid === null &&
																		data2.cbmid === null) {
																		clients.push({cid: data2.cid});
																	}
																	if (data2.caid !== null &&
																		data2.ccpid === null &&
																		data2.cbmid === null) {
																		client_attachments.push({caid: data2.caid});
																	}
																	if (data2.cid !== null &&
																		data2.caid === null &&
																		data2.ccpid !== null &&
																		data2.cbmid === null) {
																		client_contact_persons.push({cid: data2.cid, ccpid: data2.ccpid});
																	}
																	if (data2.cid !== null &&
																		data2.caid === null &&
																		data2.ccpid === null &&
																		data2.cbmid !== null) {
																		client_building_maps.push({cid: data2.cid, cbmid: data2.cbmid});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid === null &&
																		data2.rbrid === null &&
																		data2.rbrpid === null &&
																		data2.rbmid === null) {
																		reports.push({rid: data2.rid});
																	}
																	if (data2.rid !== null &&
																		data2.raid !== null &&
																		data2.rbid === null &&
																		data2.rbrid === null &&
																		data2.rbrpid === null &&
																		data2.rbmid === null) {
																		reports_attachments.push({rid: data2.rid, raid: data2.raid});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid !== null &&
																		data2.rbrid === null &&
																		data2.rbrpid === null &&
																		data2.rbmid === null) {
																		reports_buildings.push({rid: data2.rid, rbid: data2.rbid});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid !== null &&
																		data2.rbrid !== null &&
																		data2.rbrpid === null &&
																		data2.rbmid === null) {
																		reports_buildings_reports.push({
																			rid: data2.rid,
																			rbid: data2.rbid,
																			rbrid: data2.rbrid
																		});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid !== null &&
																		data2.rbrid !== null &&
																		data2.rbrpid !== null &&
																		data2.rbmid === null) {
																		reports_buildings_reports_photos.push({
																			rid: data2.rid,
																			rbid: data2.rbid,
																			rbrid: data2.rbrid,
																			rbrpid: data2.rbrpid
																		});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid === null &&
																		data2.rbrid === null &&
																		data2.rbrpid === null &&
																		data2.rbmid !== null) {
																		reports_buildings_maps.push({
																			rid: data2.rid,
																			rbmid: data2.rbmid
																		});
																	}
																	if (data2.ctid !== null) {
																		countries.push({ctid: data2.ctid});
																	}
																	if (data2.aid !== null) {
																		inspectors.push({aid: data2.aid});
																	}
																	if (data2.lid !== null) {
																		languages.push({lid: data2.lid});
																	}
																	if (data2.bpid !== null) {
																		building_particulars.push({bpid: data2.bpid});
																	}
																	if (data2.lcid !== null &&
																		data2.lsid === null &&
																		data2.lqid === null) {
																		ladder_categories.push({lcid: data2.lcid});
																	}
																	if (data2.lcid !== null &&
																		data2.lsid !== null &&
																		data2.lqid === null) {
																		ladder_subcategories.push({lcid: data2.lcid, lsid: data2.lsid});
																	}
																	if (data2.lmid !== null) {
																		ladder_materials.push({lmid: data2.lmid});
																	}
																	if (data2.lcid === null &&
																		data2.lsid === null &&
																		data2.lqid !== null) {
																		ladder_questionnaires.push({lqid: data2.lqid});
																	}
																	if (data2.lcid !== null &&
																		data2.lsid !== null &&
																		data2.lqid !== null) {
																		ladder_category_subcategory_questionnaire.push({
																			lcid: data2.lcid,
																			lsid: data2.lsid,
																			lqid: data2.lqid
																		});
																	}
																	if (data2.wcid === null &&
																		data2.wsid === null &&
																		data2.waid !== null) {
																		warehouse_actions.push({waid: data2.waid});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid === null &&
																		data2.waid === null &&
																		data2.wtid === null) {
																		warehouse_categories.push({wcid: data2.wcid});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid !== null &&
																		data2.waid === null &&
																		data2.wtid === null) {
																		warehouse_subcategories.push({wcid: data2.wcid, wsid: data2.wsid});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid !== null &&
																		data2.waid !== null &&
																		data2.wtid === null) {
																		warehouse_category_subcategory_action.push({
																			wcid: data2.wcid,
																			wsid: data2.wsid,
																			waid: data2.waid
																		});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid !== null &&
																		data2.waid === null &&
																		data2.wtid !== null) {
																		warehouse_category_subcategory_type.push({
																			wcid: data2.wcid,
																			wsid: data2.wsid,
																			wtid: data2.wtid
																		});
																	}
																	if (data2.wqid !== null) {
																		warehouse_questionnaires.push({wqid: data2.wqid});
																	}
																	if (data2.wcid === null &&
																		data2.wsid === null &&
																		data2.wtid !== null) {
																		warehouse_types.push({wtid: data2.wtid});
																	}
																	if (data2.tcid !== null &&
																		data2.tsid === null &&
																		data2.tqid === null) {
																		tent_categories.push({tcid: data2.tcid});
																	}
																	if (data2.tcid !== null &&
																		data2.tsid !== null &&
																		data2.tqid === null) {
																		tent_subcategories.push({tcid: data2.tcid, tsid: data2.tsid});
																	}
																	if (data2.tcid === null &&
																		data2.tsid === null &&
																		data2.tqid !== null) {
																		tent_questionnaires.push({tqid: data2.tqid});
																	}
																	if (data2.tcid !== null &&
																		data2.tsid !== null &&
																		data2.tqid !== null) {
																		tent_category_subcategory_questionnaire.push({
																			tcid: data2.tcid,
																			tsid: data2.tsid,
																			tqid: data2.tqid
																		});
																	}
																});

																if (inspector_inventories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: inspector_inventories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteInspectorInventory(inspector_inventories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: inspector_inventories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (inspector_calibrations.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: inspector_calibrations start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteInspectorCalibration(inspector_calibrations)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: inspector_calibrations done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (clients.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: clients start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteClient(clients)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: clients done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (client_attachments.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: client_attachments start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteClientAttachment(null, client_attachments)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: client_attachments done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (client_building_maps.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: client_building_maps start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteClientBuildingMaps(null, client_building_maps)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: client_building_maps done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (client_contact_persons.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: client_contact_persons start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteClientContactPerson(null, client_contact_persons)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: client_contact_persons done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReport(null, reports)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_attachments.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_attachments start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportAttachment(null, reports_attachments)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_attachments done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_buildings.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_buildings start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportBuilding(null, reports_buildings)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_buildings done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_buildings_reports.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_buildings_reports start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportBuildingReport(reports_buildings_reports)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_buildings_reports done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_buildings_reports_photos.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_buildings_reports_photos start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportBuildingReportPhoto(null, reports_buildings_reports_photos)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_buildings_reports_photos done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_buildings_maps.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_buildings_maps start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportBuildingMap(null, reports_buildings_maps)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_buildings_maps done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (countries.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: countries start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteCountry(countries)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: countries done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (languages.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: languages start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteLanguage(languages)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: languages done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (inspectors.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: inspectors start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteInspector(inspectors)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: inspectors done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (building_particulars.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: building_particulars start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteBuildingParticulars(building_particulars)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: building_particulars done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (ladder_categories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: ladder_categories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteLadderCategory(ladder_categories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: ladder_categories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (ladder_subcategories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: ladder_subcategories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteLadderSubcategory(ladder_subcategories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: ladder_subcategories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (ladder_materials.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: ladder_materials start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteLadderMaterial(ladder_materials)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: ladder_materials done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (ladder_questionnaires.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: ladder_questionnaires start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteLadderQuestionnaire(ladder_questionnaires)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: ladder_questionnaires done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (ladder_category_subcategory_questionnaire.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: ladder_category_subcategory_questionnaire start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteLadderCategorySubcategoryQuestionnaire(ladder_category_subcategory_questionnaire)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: ladder_category_subcategory_questionnaire done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (warehouse_categories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: warehouse_categories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteWarehouseCategory(warehouse_categories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: warehouse_categories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (warehouse_subcategories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: warehouse_subcategories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteWarehouseSubcategory(null, warehouse_subcategories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: warehouse_subcategories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (warehouse_types.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: warehouse_types start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteWarehouseType(warehouse_types)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: warehouse_types done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (warehouse_actions.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: warehouse_actions start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteWarehouseAction(warehouse_actions)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: warehouse_actions done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (warehouse_category_subcategory_type.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: warehouse_category_subcategory_type start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteWarehouseCategorySubcategoryType(warehouse_category_subcategory_type)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: warehouse_category_subcategory_type done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (warehouse_category_subcategory_action.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: warehouse_category_subcategory_action start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteWarehouseCategorySubcategoryAction(warehouse_category_subcategory_action)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: warehouse_category_subcategory_action done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (warehouse_questionnaires.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: warehouse_questionnaires start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteWarehouseQuestionnaire(warehouse_questionnaires)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: warehouse_questionnaires done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (tent_categories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: tent_categories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteTentCategory(tent_categories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: tent_categories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (tent_subcategories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: tent_subcategories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteTentSubcategory(tent_subcategories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: tent_subcategories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (tent_questionnaires.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: tent_questionnaires start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteTentQuestionnaire(tent_questionnaires)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: tent_questionnaires done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (tent_category_subcategory_questionnaire.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: tent_category_subcategory_questionnaire start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteTentCategorySubcategoryQuestionnaire(tent_category_subcategory_questionnaire)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: tent_category_subcategory_questionnaire done');
																				}
																				resolve2();
																			});
																	}));
																}

																promises.push(new Promise(resolve2 => {
																	this.database.update(this.tableDataSync, {
																		name: 'deleted_items',
																		updated: server_date.data.updated
																	})
																		.then(() => {
																			resolve2();
																		})
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		});
																}));

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: finished');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncClosedReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [],
					report_ids = [];
				this.database.getAllFast(this.tableReports).then(reports => {
					reports.forEach(report => {
						report_ids.push(report.id);
					});

					if (report_ids.length) {
						this.apiService.post('reports/get-statuses', {report_ids: report_ids}).then((data: ServerResponse) => {
							if (typeof data !== 'undefined') {
								if (typeof data.success !== 'undefined') {
									if (data.success === true) {
										if (typeof data.data !== 'undefined') {
											data.data.forEach(report_status => {
												if (report_status.status === 'closed') {
													promises.push(this.database.delete(this.tableReports, parseInt(report_status.id, 10)).then(() => true));
												}
											});
										}
									}
								}
							}
						});
					}
					return Promise.all(promises).then(() => resolve());
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncLanguages(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableLanguages).then(user_device_date => {
					this.apiService.get('general/languages/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('general/languages', {
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncLanguages start ...');
																}
																promises.push(this.database.updateBulk(this.tableLanguages, <Languages[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableLanguages,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncLanguages done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncCountries(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableCountries).then(user_device_date => {
					this.apiService.get('general/countries/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('general/countries', {
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncCountries start ...');
																}
																promises.push(this.database.updateBulk(this.tableCountries, <Countries[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableCountries,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncCountries done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncClients(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableClients).then(user_device_date => {
					this.apiService.get('clients/clients/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('clients/clients', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncClients start ...');
																}
																promises.push(this.database.updateBulk(this.tableClients, <Client[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableClients,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncClients done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncClientsAttachments(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableClientsAttachments).then(user_device_date => {
					this.apiService.get('clients/clients/attachments/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('clients/clients/attachments', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncClientsAttachments start ...');
																}
																promises.push(this.database.updateBulk(this.tableClientsAttachments, <ClientsAttachments[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableClientsAttachments,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncClientsAttachments done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncClientsContactPersons(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableClientsContactPersons).then(user_device_date => {
					this.apiService.get('clients/clients/contacts-persons/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('clients/clients/contacts-persons', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncClientsContactPersons start ...');
																}
																promises.push(this.database.updateBulk(this.tableClientsContactPersons, <ClientsContactsPersons[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableClientsContactPersons,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncClientsContactPersons done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncClientsBuildingMaps(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableClientsBuildingsMaps).then(user_device_date => {
					this.apiService.get('clients/clients/building-maps/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('clients/clients/building-maps', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncClientsBuildingMaps start ...');
																}
																promises.push(this.database.updateBulk(this.tableClientsBuildingsMaps, <ClientsBuildingMaps[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableClientsBuildingsMaps,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncClientsBuildingMaps done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncInspectorInventory(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, 'inspectors_inventories').then(user_device_date => {
					this.apiService.get('inventory/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('inventory', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncInspectorInventory start ...');
																}
																promises.push(this.database.updateBulk(this.tableInspectorsInventory, <InspectorsInventory[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: 'inspectors_inventories',
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncInspectorInventory done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncInspectorCalibrations(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableInspectorsCalibrations).then(user_device_date => {
					this.apiService.get('calibration/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('calibration', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncInspectorCalibrations start ...');
																}
																promises.push(this.database.updateBulk(this.tableInspectorsCalibrations, <InspectorsCalibration[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableInspectorsCalibrations,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncInspectorCalibrations done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncInspectorInspections(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableInspectorsInspections).then(user_device_date => {
					this.apiService.get('inspections/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('inspections', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncInspectorInspections start ...');
																}
																promises.push(this.database.updateBulk(this.tableInspectorsInspections, <InspectorsInspections[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableInspectorsInspections,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncInspectorInspections done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	pushToServer_ClientsCreate(): Promise<void> {
		return new Promise(resolve => {
			let clientsData: Client[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate start ... ');
			}

			return this.database.getAllFast(this.tableClientsPush).then(clients => {
				if (typeof clients !== 'undefined') {
					if (clients.length) {
						clients.forEach(client => {
							if (client.indexedDBMethod === '1-create') {
								clientsData.push(client);
							}
						});

						if (clientsData.length) {
							clientsData.forEach(client => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('clients/clients', client, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];

															promises2.push(this.database.delete(this.tableClientsPush, client.entryId)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															if (data.data.id !== client.id) {
																let newId = parseInt(data.data.id, 10);

																promises2.push(this.database.delete(this.tableClients, client.id)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.add(this.tableClients, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableClientsPush)
																	.then(clients2 => {
																		let filtered = [];
																		clients2.forEach(client2 => {
																			if (client2.id === client.id) {
																				client2.id = newId;
																				filtered.push(client2);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableClientsPush, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableClientsAttachmentsPush)
																	.then(client_attachments => {
																		let filtered = [];
																		client_attachments.forEach(client_attachment => {
																			if (client_attachment.client_id === client.id) {
																				client_attachment.client_id = newId;
																				filtered.push(client_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableClientsAttachmentsPush, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableClientsAttachments)
																	.then(client_attachments => {
																		let filtered = [];
																		client_attachments.forEach(client_attachment => {
																			if (client_attachment.client_id === client.id) {
																				client_attachment.client_id = newId;
																				filtered.push(client_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableClientsAttachments, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableClientsContactPersons)
																	.then(contact_persons => {
																		let filtered = [];
																		contact_persons.forEach(contact_person => {
																			if (contact_person.client_id === client.id) {
																				contact_person.client_id = newId;
																				filtered.push(contact_person);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableClientsContactPersons, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableClientsContactPersonsPush)
																	.then(contact_persons => {
																		let filtered = [];
																		contact_persons.forEach(contact_person => {
																			if (contact_person.client_id === client.id) {
																				contact_person.client_id = newId;
																				filtered.push(contact_person);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableClientsContactPersonsPush, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableInspectorsInventorySendToClient)
																	.then(inspector_inventories => {
																		let filtered = [];
																		inspector_inventories.forEach(inspector_inventory => {
																			if (inspector_inventory.client_id === client.id) {
																				inspector_inventory.client_id = newId;
																				filtered.push(inspector_inventory);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableInspectorsInventorySendToClient, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReports)
																	.then(reports => {
																		let filtered = [];
																		reports.forEach(report => {
																			if (report.client_id === client.id) {
																				report.client_id = newId;
																				filtered.push(report);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReports, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsPush)
																	.then(reports => {
																		let filtered = [];
																		reports.forEach(report => {
																			if (report.client_id === client.id) {
																				report.client_id = newId;
																				filtered.push(report);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsPush, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsSendToClient)
																	.then(reports_send_to_client => {
																		let filtered = [];
																		reports_send_to_client.forEach(report_send_to_client => {
																			if (report_send_to_client.client_id === client.id) {
																				report_send_to_client.client_id = newId;
																				filtered.push(report_send_to_client);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsSendToClient, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);

																return Promise.all(promises2).then(() => {
																	this.redirectUser({
																		client_id: newId,
																		old_client_id: client.id
																	});
																	resolve2();
																});
															} else {
																promises2.push(this.database.update(this.tableClients, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															}

															return Promise.all(promises2).then(() => {
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let clientsData: Client[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate start ...');
			}

			return this.database.getAllFast(this.tableClientsPush).then(clients => {
				if (typeof clients !== 'undefined') {
					if (clients.length) {
						clients.forEach(client => {
							if (client.indexedDBMethod === '2-update') {
								clientsData.push(client);
							}
						});
						if (clientsData.length) {
							clientsData.forEach(client => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('clients/clients/' + client.id, client, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.update(this.tableClients, data.data)
															.then(() => {
																return this.database.delete(this.tableClientsPush, client.entryId)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	});
															}).catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsDelete(): Promise<void> {
		return new Promise(resolve => {
			let clientsData: Client[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete start ...');
			}

			return this.database.getAllFast(this.tableClientsPush).then(clients => {
				if (typeof clients !== 'undefined') {
					if (clients.length) {
						clients.forEach(client => {
							if (client.indexedDBMethod === '3-delete') {
								clientsData.push(client);
							}
						});
						if (clientsData.length) {
							clientsData.forEach(client => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let promises2 = [];

										this.apiService.delete('clients/clients/' + client.id, false).then(() => {
											promises2.push(this.database.delete(this.tableClients, client.id)
												.then(() => {
													return this.database.delete(this.tableClientsPush, client.entryId)
														.then(() => true)
														.catch(error => {
															if (this.debug) {
																console.log(error);
															}
															throw new Error(error);
														});
												})
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => resolve2());
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsAttachmentsCreate(): Promise<void> {
		return new Promise(resolve => {
			let clientsAttachments: ClientsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate start ...');
			}

			return this.database.getAllFast(this.tableClientsAttachmentsPush).then(clients_attachments => {
				if (typeof clients_attachments !== 'undefined') {
					if (clients_attachments.length) {
						clients_attachments.forEach(client_attachment => {
							if (client_attachment.indexedDBMethod === '1-create') {
								clientsAttachments.push(client_attachment);
							}
						});
						if (clientsAttachments.length) {
							clientsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let newAttachment = {
												filename: new FileInput(attachment.filename['_files']),
												title: attachment.title,
												description: attachment.description,
												append_warehouse: attachment.append_warehouse,
												append_ladder: attachment.append_ladder,
												append_tent: attachment.append_tent,
											},
											promises2 = [];

										this.apiService.post('clients/clients/' + attachment.client_id + '/attachments', newAttachment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															promises2.push(this.database.delete(this.tableClientsAttachmentsPush, attachment.entryId)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);

															if (data.data.id !== attachment.id) {
																promises2.push(this.database.delete(this.tableClientsAttachments, attachment.id)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.add(this.tableClientsAttachments, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableClientsAttachmentsPush)
																	.then(client_attachments => {
																		let filtered = [];
																		client_attachments.forEach(client_attachment => {
																			if (client_attachment.id === attachment.id) {
																				client_attachment.id = parseInt(data.data.id, 10);
																				filtered.push(client_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableClientsAttachmentsPush, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																	}).catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															} else {
																promises2.push(this.database.update(this.tableClientsAttachments, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	client_id: data.data.client_id,
																	attachment_id: data.data.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsAttachmentsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let clientsAttachments: ClientsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate start ...');
			}

			return this.database.getAllFast(this.tableClientsAttachmentsPush).then(clients_attachments => {
				if (typeof clients_attachments !== 'undefined') {
					if (clients_attachments.length) {
						clients_attachments.forEach(client_attachment => {
							if (client_attachment.indexedDBMethod === '2-update') {
								clientsAttachments.push(client_attachment);
							}
						});
						if (clientsAttachments.length) {
							clientsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('clients/clients/' + attachment.client_id + '/attachments/' + attachment.id, attachment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.update(this.tableClientsAttachments, data.data)
															.then(() => {
																return this.database.delete(this.tableClientsAttachmentsPush, attachment.entryId)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	});
															}).catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsAttachmentsDelete(): Promise<void> {
		return new Promise(resolve => {
			let clientsAttachments: ClientsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete start ...');
			}

			return this.database.getAllFast(this.tableClientsAttachmentsPush).then(clients_attachments => {
				if (typeof clients_attachments !== 'undefined') {
					if (clients_attachments.length) {
						clients_attachments.forEach(client_attachment => {
							if (client_attachment.indexedDBMethod === '3-delete') {
								clientsAttachments.push(client_attachment);
							}
						});
						if (clientsAttachments.length) {
							clientsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('clients/clients/' + attachment.client_id + '/attachments/' + attachment.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableClientsAttachments, attachment.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableClientsAttachmentsPush, attachment.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsAttachmentsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsContactPersonsCreate(): Promise<void> {
		return new Promise(resolve => {
			let clientsContactPersons: ClientsContactsPersons[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate start ...');
			}

			return this.database.getAllFast(this.tableClientsContactPersonsPush).then(clients_contact_persons => {
				if (typeof clients_contact_persons !== 'undefined') {
					if (clients_contact_persons.length) {
						clients_contact_persons.forEach(client_contact_person => {
							if (client_contact_person.indexedDBMethod === '1-create') {
								clientsContactPersons.push(client_contact_person);
							}
						});
						if (clientsContactPersons.length) {
							clientsContactPersons.forEach(clientContactPerson => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('clients/clients/' + clientContactPerson.client_id + '/contacts-persons', clientContactPerson, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];

															promises2.push(this.database.delete(this.tableClientsContactPersonsPush, clientContactPerson.entryId)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															if (data.data.id !== clientContactPerson.id) {
																promises2.push(this.database.delete(this.tableClientsContactPersons, clientContactPerson.id)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.add(this.tableClientsContactPersons, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableClientsContactPersonsPush)
																	.then(contact_persons => {
																		let filtered = [];
																		contact_persons.forEach(contact_person => {
																			if (contact_person.id === clientContactPerson.id) {
																				contact_person.id = parseInt(data.data.id, 10);
																				filtered.push(contact_person);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableClientsContactPersonsPush, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	}).catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableInspectorsInventorySendToClient)
																	.then(inventories_send_to_client => {
																		let filtered = [];
																		inventories_send_to_client.forEach(inventory_send_to_client => {
																			let updated = false;
																			if (inventory_send_to_client.contact_persons_ids.length) {
																				inventory_send_to_client.contact_persons_ids.forEach((contact_person, index) => {
																					if (contact_person === clientContactPerson.id) {
																						inventory_send_to_client.contact_persons_ids[index] = data.data.id;
																						updated = true;
																					}
																				});
																				if (updated) {
																					filtered.push(inventory_send_to_client);
																				}
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableInspectorsInventorySendToClient, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															} else {
																promises2.push(this.database.update(this.tableClientsContactPersons, clientContactPerson)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	client_id: data.data.client_id,
																	contact_person_id: data.data.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});
							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsContactPersonsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let clientsContactPersons: ClientsContactsPersons[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate start ...');
			}

			return this.database.getAllFast(this.tableClientsContactPersonsPush).then(clients_contact_persons => {
				if (typeof clients_contact_persons !== 'undefined') {
					if (clients_contact_persons.length) {
						clients_contact_persons.forEach(client_contact_person => {
							if (client_contact_person.indexedDBMethod === '2-update') {
								clientsContactPersons.push(client_contact_person);
							}
						});
						if (clientsContactPersons.length) {
							clientsContactPersons.forEach(clientContactPerson => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('clients/clients/' + clientContactPerson.client_id + '/contacts-persons/' + clientContactPerson.id, clientContactPerson, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableClientsContactPersonsPush, clientContactPerson.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.update(this.tableClientsContactPersons, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ClientsContactPersonsDelete(): Promise<void> {
		return new Promise(resolve => {
			let clientsContactPersons: ClientsContactsPersons[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete start ...');
			}

			return this.database.getAllFast(this.tableClientsContactPersonsPush).then(clients_contact_persons => {
				if (typeof clients_contact_persons !== 'undefined') {
					if (clients_contact_persons.length) {
						clients_contact_persons.forEach(client_contact_person => {
							if (client_contact_person.indexedDBMethod === '3-delete') {
								clientsContactPersons.push(client_contact_person);
							}
						});
						if (clientsContactPersons.length) {
							clientsContactPersons.forEach(clientContactPerson => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('clients/clients/' + clientContactPerson.client_id + '/contacts-persons/' + clientContactPerson.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableClientsContactPersonsPush, clientContactPerson.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableClientsContactPersons, clientContactPerson.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});
							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ClientsContactPersonsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_InspectorsCalibrationsCreate(): Promise<void> {
		return new Promise(resolve => {
			let calibrationsData: InspectorsCalibration[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate start ...');
			}

			return this.database.getAllFast(this.tableInspectorsCalibrationsPush).then(inspectors_calibrations => {
				if (typeof inspectors_calibrations !== 'undefined') {
					if (inspectors_calibrations.length) {
						inspectors_calibrations.forEach(inspector_calibration => {
							if (inspector_calibration.indexedDBMethod === '1-create') {
								calibrationsData.push(inspector_calibration);
							}
						});
						if (calibrationsData.length) {
							calibrationsData.forEach(calibration => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let pushCalibration = {...calibration};
										(<string><any>pushCalibration['date_calibrated']) = moment(calibration['date_calibrated']).format('YYYY-MM-DD');
										(<string><any>pushCalibration['next_calibration_date']) = moment(calibration['next_calibration_date']).format('YYYY-MM-DD');

										this.apiService.post('calibration', pushCalibration, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];

															promises2.push(this.database.delete(this.tableInspectorsCalibrationsPush, calibration.entryId)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															if (data.data.id !== calibration['id']) {
																promises2.push(this.database.delete(this.tableInspectorsCalibrations, calibration.id)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.add(this.tableInspectorsCalibrations, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableInspectorsCalibrationsPush)
																	.then(inspector_calibrations => {
																		let filtered = [];
																		inspector_calibrations.forEach(inspector_calibration => {
																			if (inspector_calibration.id === calibration.id) {
																				inspector_calibration.id = parseInt(data.data.id, 10);
																				filtered.push(inspector_calibration);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableInspectorsCalibrationsPush, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															} else {
																promises2.push(this.database.update(this.tableInspectorsCalibrations, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	calibration_id: data.data.id,
																	old_calibration_id: calibration.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_InspectorsCalibrationsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let calibrationsData: InspectorsCalibration[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate start ...');
			}

			return this.database.getAllFast(this.tableInspectorsCalibrationsPush).then(inspectors_calibrations => {
				if (typeof inspectors_calibrations !== 'undefined') {
					if (inspectors_calibrations.length) {
						inspectors_calibrations.forEach(inspector_calibration => {
							if (inspector_calibration.indexedDBMethod === '2-update') {
								calibrationsData.push(inspector_calibration);
							}
						});
						if (calibrationsData.length) {
							calibrationsData.forEach(calibration => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let pushCalibration = {...calibration};
										(<string><any>pushCalibration['date_calibrated']) = moment(calibration['date_calibrated']).format('YYYY-MM-DD');
										(<string><any>pushCalibration['next_calibration_date']) = moment(calibration['next_calibration_date']).format('YYYY-MM-DD');

										this.apiService.post('calibration/' + calibration.id, pushCalibration, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableInspectorsCalibrationsPush, calibration.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.update(this.tableInspectorsCalibrations, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											} else {
												resolve();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								start = new Date().getTime();
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_InspectorsCalibrationsDelete(): Promise<void> {
		return new Promise(resolve => {
			let calibrationsData: InspectorsCalibration[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete start ...');
			}

			return this.database.getAllFast(this.tableInspectorsCalibrationsPush).then(inspectors_calibrations => {
				if (typeof inspectors_calibrations !== 'undefined') {
					if (inspectors_calibrations.length) {
						inspectors_calibrations.forEach(inspector_calibration => {
							if (inspector_calibration.indexedDBMethod === '3-delete') {
								calibrationsData.push(inspector_calibration);
							}
						});
						if (calibrationsData.length) {
							calibrationsData.forEach(calibration => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('calibration/' + calibration.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableInspectorsCalibrationsPush, calibration.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableInspectorsCalibrations, calibration.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_InspectorsCalibrationsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_InspectorsInventoryCreate(): Promise<void> {
		return new Promise(resolve => {
			let inspectorInventory: InspectorsInventory[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate start ...');
			}

			return this.database.getAllFast(this.tableInspectorsInventoryPush).then(inspectors_inventories => {
				if (typeof inspectors_inventories !== 'undefined') {
					if (inspectors_inventories.length) {
						inspectors_inventories.forEach(inspector_inventory => {
							if (inspector_inventory.indexedDBMethod === '1-create') {
								inspectorInventory.push(inspector_inventory);
							}
						});
						if (inspectorInventory.length) {
							inspectorInventory.forEach(inventory => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let newInventory = {
											user_id: inventory.user_id,
											filename: new FileInput(inventory.filename['_files'])
										};

										this.apiService.post('inventory', newInventory, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];
															promises2.push(this.database.delete(this.tableInspectorsInventoryPush, inventory.entryId)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															if (data.data.id !== inventory.id) {
																promises2.push(this.database.delete(this.tableInspectorsInventory, inventory.id)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.add(this.tableInspectorsInventory, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableInspectorsInventoryPush).then(inspector_inventories => {
																	let filtered = [];
																	inspector_inventories.forEach(inspector_inventory => {
																		if (inspector_inventory.id === inventory.id) {
																			inspector_inventory.id = parseInt(data.data.id, 10);
																			filtered.push(inspector_inventory);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableInspectorsInventoryPush, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																}));
																promises2.push(this.database.getAllFast(this.tableInspectorsInventorySendToClient)
																	.then(inventories_send_to_client => {
																		let filtered = [];
																		inventories_send_to_client.forEach(inventory_send_to_client => {
																			if (inventory_send_to_client.id === inventory.id) {
																				inventory_send_to_client.id = parseInt(data.data.id, 10);
																				filtered.push(inventory_send_to_client);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableInspectorsInventorySendToClient, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															} else {
																promises2.push(this.database.update(this.tableInspectorsInventory, data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	inventory_id: data.data.id,
																	old_inventory_id: inventory.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_InspectorsInventoryDelete(): Promise<void> {
		return new Promise(resolve => {
			let inspectorInventory: InspectorsInventory[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete start ...');
			}

			return this.database.getAllFast(this.tableInspectorsInventoryPush).then(inspectors_inventories => {
				if (typeof inspectors_inventories !== 'undefined') {
					if (inspectors_inventories.length) {
						inspectors_inventories.forEach(inspector_inventory => {
							if (inspector_inventory.indexedDBMethod === '3-delete') {
								inspectorInventory.push(inspector_inventory);
							}
						});
						if (inspectorInventory.length) {
							inspectorInventory.forEach(inventory => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('inventory/' + inventory.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableInspectorsInventory, inventory.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableInspectorsInventoryPush, inventory.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventoryDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_InspectorsInventorySendToClient(): Promise<void> {
		return new Promise(resolve => {
			let inspectorInventorySendToClient: InspectorsInventorySentTo[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventorySendToClient start ...');
			}

			return this.database.getAllFast(this.tableInspectorsInventorySendToClient).then(inspectors_inventories => {
				if (typeof inspectors_inventories !== 'undefined') {
					if (inspectors_inventories.length) {
						inspectors_inventories.forEach(inspector_inventory => {
							if (inspector_inventory.indexedDBMethod === '1-create') {
								inspectorInventorySendToClient.push(inspector_inventory);
							}
						});
						if (inspectorInventorySendToClient.length) {
							inspectorInventorySendToClient.forEach(inventory => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('inventory/' + inventory.id + '/send-to-client', inventory, false)
											.then(() => {
												return this.database.delete(this.tableInspectorsInventorySendToClient, inventory.id)
													.then(() => true)
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											})
											.catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_InspectorsInventorySendToClient done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	syncInspectors(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableInspectors).then(user_device_date => {
					this.apiService.get('reports/inspectors/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/inspectors', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncInspectors start ...');
																}
																promises.push(this.database.updateBulk(this.tableInspectors, <User[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableInspectors,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncInspectors done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncBuildingParticulars(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsBuildingParticulars).then(user_device_date => {
					this.apiService.get('reports/building-particulars/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/building-particulars', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncBuildingParticulars start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsBuildingParticulars, <ReportBuildingParticulars[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsBuildingParticulars,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncBuildingParticulars done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncWarehouseCategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsWarehouseCategories).then(user_device_date => {
					this.apiService.get('reports/warehouse/elements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/elements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncWarehouseCategories start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsWarehouseCategories, <ReportsShortcomingsCategories[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsWarehouseCategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncWarehouseCategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncWarehouseSubcategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsWarehouseSubcategories).then(user_device_date => {
					this.apiService.get('reports/warehouse/elements/subelements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/elements/subelements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncWarehouseSubcategories start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsWarehouseSubcategories, <ReportsShortcomingsSubcategories[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.getAllFast(this.tableReportsWarehouseTypes)
																	.then(types => {
																		if (typeof types !== 'undefined') {
																			if (types.length) {
																				let filtered = [];
																				types.forEach(type => {
																					data.data.forEach(server_type => {
																						if (type.category_id === server_type.category_id && type.subcategory_id === server_type.id) {
																							type.amounts = server_type.amounts;
																							filtered.push(type);
																						}
																					});
																				});
																				if (filtered.length) {
																					return this.database.updateBulk(this.tableReportsWarehouseTypes, filtered)
																						.then(() => true)
																						.catch(error => {
																							if (this.debug) {
																								console.log(error);
																							}
																							throw new Error(error);
																						});
																				}
																			}
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsWarehouseSubcategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncWarehouseSubcategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncTypes(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsWarehouseTypes).then(user_device_date => {
					this.apiService.get('reports/warehouse/elements/subelements/types/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/elements/subelements/types', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncTypes start ...');
																}
																let filtered = [];
																data.data.forEach(type => {
																	type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
																	filtered.push(type);
																});
																if (filtered.length) {
																	promises.push(this.database.updateBulk(this.tableReportsWarehouseTypes, <ReportsShortcomingsTypes[]>filtered)
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																	);
																}
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsWarehouseTypes,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncTypes done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncActions(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsWarehouseActions).then(user_device_date => {
					this.apiService.get('reports/warehouse/elements/subelements/actions/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/elements/subelements/actions', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncActions start ...');
																}
																let filtered = [];
																data.data.forEach(action => {
																	action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
																	filtered.push(action);
																});
																if (filtered.length) {
																	promises.push(this.database.updateBulk(this.tableReportsWarehouseActions, <ReportsShortcomingsActions[]>filtered)
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		}));
																}
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsWarehouseActions,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncActions done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncWarehouseQuestionnaires(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsWarehouseQuestionnaire).then(user_device_date => {
					this.apiService.get('reports/warehouse/questionnaire/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/questionnaire', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncWarehouseQuestionnaires start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsWarehouseQuestionnaire, <ReportsQuestions[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsWarehouseQuestionnaire,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncWarehouseQuestionnaires done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncWarehouseQuestionnaireAnswers(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsWarehouseQuestionnaireAnswers).then(user_device_date => {
					this.apiService.get('reports/warehouse/questionnaire/answers/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/questionnaire/answers', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncWarehouseQuestionnaireAnswers start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsWarehouseQuestionnaireAnswers, <ReportsQuestionsAnswers[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsWarehouseQuestionnaireAnswers,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncWarehouseQuestionnaireAnswers done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncLadderCategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsLadderCategories).then(user_device_date => {
					this.apiService.get('reports/ladders/elements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/ladders/elements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncLadderCategories start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsLadderCategories, <ReportsShortcomingsCategories[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsLadderCategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncLadderCategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncLadderSubcategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsLadderSubcategories).then(user_device_date => {
					this.apiService.get('reports/ladders/elements/subelements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/ladders/elements/subelements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncLadderSubcategories start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsLadderSubcategories, <ReportsShortcomingsSubcategories[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsLadderSubcategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncLadderSubcategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncLadderQuestionnaires(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsLadderQuestionnaire).then(user_device_date => {
					this.apiService.get('reports/ladders/elements/subelements/questionnaires/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/ladders/elements/subelements/questionnaires', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncLadderQuestionnaires start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsLadderQuestionnaire, <ReportsBuildingsShortcomingsQuestionnaire[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsLadderQuestionnaire,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncLadderQuestionnaires done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncLadderQustionnaireAnswers(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsLadderQuestionnaireAnswers).then(user_device_date => {
					this.apiService.get('reports/ladders/elements/subelements/questionnaires/answers/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/ladders/elements/subelements/questionnaires/answers', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncLadderQustionnaireAnswers start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsLadderQuestionnaireAnswers, <ReportsBuildingsShortcomingsQuestionnaireAnswers[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsLadderQuestionnaireAnswers,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncLadderQustionnaireAnswers done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncLadderMaterials(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsLadderMaterials).then(user_device_date => {
					this.apiService.get('reports/ladders/materials/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/ladders/materials', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncLadderMaterials start ...');
																}
																let filtered = [];
																data.data.forEach(material => {
																	material.material_name = material.material_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
																	filtered.push(material);
																});
																if (filtered.length) {
																	promises.push(this.database.updateBulk(this.tableReportsLadderMaterials, <InspectorsInventory[]>filtered)
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																	);
																}
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsLadderMaterials,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncLadderMaterials done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncTentCategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsTentCategories).then(user_device_date => {
					this.apiService.get('reports/tent/elements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/tent/elements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncTentCategories start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsTentCategories, <ReportsShortcomingsCategories[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsTentCategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncTentCategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncTentSubcategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsTentSubcategories).then(user_device_date => {
					this.apiService.get('reports/tent/elements/subelements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/tent/elements/subelements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncTentSubcategories start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsTentSubcategories, <ReportsShortcomingsSubcategories[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsTentSubcategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncTentSubcategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncTentQuestionnaires(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsTentQuestionnaire).then(user_device_date => {
					this.apiService.get('reports/tent/elements/subelements/questionnaires/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/tent/elements/subelements/questionnaires', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncTentQuestionnaires start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsTentQuestionnaire, <ReportsBuildingsShortcomingsQuestionnaire[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsTentQuestionnaire,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncTentQuestionnaires done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncTentQustionnaireAnswers(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsTentQuestionnaireAnswers).then(user_device_date => {
					this.apiService.get('reports/tent/elements/subelements/questionnaires/answers/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/tent/elements/subelements/questionnaires/answers', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncTentQustionnaireAnswers start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsTentQuestionnaireAnswers, <ReportsBuildingsShortcomingsQuestionnaireAnswers[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsTentQuestionnaireAnswers,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncTentQustionnaireAnswers done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReports).then(user_device_date => {
					this.apiService.get('reports/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReports start ...');
																}
																promises.push(this.database.updateBulk(this.tableReports, <Reports[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReports,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReports done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncReinspectionReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				return this.database.clear(this.tableReportsReinspections).then(() => {
					this.apiService.get('reports/reinspections', null, false).then((data: ServerResponse) => {
						if (typeof data !== 'undefined') {
							if (typeof data.success !== 'undefined') {
								if (data.success === true) {
									if (typeof data.data !== 'undefined') {
										return this.database.updateBulk(this.tableReportsReinspections, <Reports[]>data.data)
											.then(() => resolve())
											.catch(error => {
												if (this.debug) {
													console.log(error);
												}
												resolve();
												throw new Error(error);
											});
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						resolve();
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve();
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncReportsAttachments(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsAttachments).then(user_device_date => {
					this.apiService.get('reports/warehouse/attachments/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/attachments', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsAttachments start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsAttachments, <ReportsAttachments[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsAttachments,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsAttachments done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncReportsComments(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsComments).then(user_device_date => {
					this.apiService.get('reports/warehouse/comments/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/comments', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsComments start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsComments, <ReportsComments[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsComments,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsComments done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncReportsBuildings(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsBuildings).then(user_device_date => {
					this.apiService.get('reports/warehouse/buildings/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/buildings', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsBuildings start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsBuildings, <ReportsBuildings[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsBuildings,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsBuildings done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncReportsBuildingsReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsBuildingsReports).then(user_device_date => {
					this.apiService.get('reports/warehouse/buildings/reports/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/buildings/reports', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsBuildingsReports start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsBuildingsReports, <ReportsBuildingsReports[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsBuildingsReports,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsBuildingsReports done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	syncReportsBuildingsMaps(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsBuildingsMaps).then(user_device_date => {
					this.apiService.get('reports/warehouse/buildings-maps/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/warehouse/buildings-maps', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsBuildingsMaps start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsBuildingsMaps, <ReportsBuildingsMaps[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsBuildingsMaps,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		})
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsBuildingsMaps done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											});
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	pushToServer_ReportsCreate(): Promise<void> {
		return new Promise(resolve => {
			let collectionData: Reports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsPush).then(reports => {
				if (typeof reports !== 'undefined') {
					if (reports.length) {
						reports.forEach(report => {
							if (report.indexedDBMethod === '1-create') {
								collectionData.push(report);
							}
						});
						if (collectionData.length) {
							collectionData.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + report.type, report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];
															promises2.push(this.database.delete(this.tableReportsPush, report.entryId)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															if (data.data.id !== report.id) {
																let newId = parseInt(data.data.id, 10);
																promises2.push(this.database.delete(this.tableReports, report.id)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.add(this.tableReports, <Reports[]>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsPush)
																	.then(reports2 => {
																		let filtered = [];
																		reports2.forEach(report2 => {
																			if (report2.id === report.id) {
																				report2.id = newId;
																				filtered.push(report2);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsPush, <Reports[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsAttachments)
																	.then(report_attachments => {
																		let filtered = [];
																		report_attachments.forEach(report_attachment => {
																			if (report_attachment.report_id === report.id) {
																				report_attachment.report_id = newId;
																				filtered.push(report_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsAttachments, <ReportsAttachments[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
																	.then(report_attachments => {
																		let filtered = [];
																		report_attachments.forEach(report_attachment => {
																			if (report_attachment.report_id === report.id) {
																				report_attachment.report_id = newId;
																				filtered.push(report_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsAttachmentsPush, <ReportsAttachments[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsComments)
																	.then(report_comments => {
																		let filtered = [];
																		report_comments.forEach(report_comment => {
																			if (report_comment.report_id === report.id) {
																				report_comment.report_id = newId;
																				filtered.push(report_comment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsComments, <ReportsComments[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsCommentsPush)
																	.then(report_comments => {
																		let filtered = [];
																		report_comments.forEach(report_comment => {
																			if (report_comment.report_id === report.id) {
																				report_comment.report_id = newId;
																				filtered.push(report_comment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsCommentsPush, <ReportsComments[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildings)
																	.then(report_buildings => {
																		let filtered = [];
																		report_buildings.forEach(report_building => {
																			if (report_building.report_id === report.id) {
																				report_building.report_id = newId;
																				filtered.push(report_building);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildings, <ReportsBuildings[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsPush)
																	.then(report_buildings => {
																		let filtered = [];
																		report_buildings.forEach(report_building => {
																			if (report_building.report_id === report.id) {
																				report_building.report_id = newId;
																				filtered.push(report_building);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsPush, <ReportsBuildings[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsReports)
																	.then(report_buildings_reports => {
																		let filtered = [];
																		report_buildings_reports.forEach(report_building_report => {
																			if (report_building_report.report_id === report.id) {
																				report_building_report.report_id = newId;
																				filtered.push(report_building_report);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsReports, <ReportsBuildingsReports[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
																	.then(report_buildings_reports => {
																		let filtered = [];
																		report_buildings_reports.forEach(report_building_report => {
																			if (report_building_report.report_id === report.id) {
																				report_building_report.report_id = newId;
																				filtered.push(report_building_report);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsReportsPush, <ReportsBuildingsReports[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
																	.then(report_buildings_reports_photos => {
																		let filtered = [];
																		report_buildings_reports_photos.forEach(report_building_report_photo => {
																			if (report_building_report_photo.report_id === report.id) {
																				report_building_report_photo.report_id = newId;
																				filtered.push(report_building_report_photo);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsReportsPhotos, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsSendToClient)
																	.then(reports_send_to_client => {
																		let filtered = [];
																		reports_send_to_client.forEach(report_send_to_client => {
																			if (report_send_to_client.report_id === report.id) {
																				report_send_to_client.report_id = newId;
																				filtered.push(report_send_to_client);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsSendToClient, filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsTentQuestionnaireAnswers)
																	.then(reports_tent_questionnaire_answers => {
																		let filtered = [],
																			forDeletion = [];
																		reports_tent_questionnaire_answers.forEach(report_tent_questionnaire_answer => {
																			if (report_tent_questionnaire_answer.report_id === report.id) {
																				forDeletion.push({
																					report_id: report_tent_questionnaire_answer.report_id,
																					building_report_id: report_tent_questionnaire_answer.building_report_id
																				});
																				report_tent_questionnaire_answer.report_id = newId;
																				filtered.push(report_tent_questionnaire_answer);
																			}
																		});
																		if (filtered.length) {
																			return this.database.deleteBulk(this.tableReportsTentQuestionnaireAnswers, forDeletion.map(ids => {
																				return [ids.report_id, ids.building_report_id];
																			}))
																				.then(() => {
																					return this.database.updateBulk(this.tableReportsTentQuestionnaireAnswers, filtered)
																						.then(() => true)
																						.catch(error => {
																							if (this.debug) {
																								console.log(error);
																							}
																							throw new Error(error);
																						});
																				})
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.getAllFast(this.tableReportsWarehouseQuestionnaireAnswers)
																	.then(reports_warehouse_questionnaire_answers => {
																		let filtered = [];
																		reports_warehouse_questionnaire_answers.forEach(report_warehouse_questionnaire_answer => {
																			if (report_warehouse_questionnaire_answer.report_id === report.id) {
																				report_warehouse_questionnaire_answer.report_id = newId;
																				filtered.push(report_warehouse_questionnaire_answer);
																			}
																		});
																		if (filtered.length) {
																			return this.database.delete(this.tableReportsWarehouseQuestionnaireAnswers, report.id)
																				.then(() => {
																					return this.database.updateBulk(this.tableReportsWarehouseQuestionnaireAnswers, filtered)
																						.then(() => true)
																						.catch(error => {
																							if (this.debug) {
																								console.log(error);
																							}
																							throw new Error(error);
																						});
																				})
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															} else {
																promises2.push(this.database.update(this.tableReports, <Reports>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	report_id: data.data.id,
																	old_report_id: report.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let collectionData: Reports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsPush).then(reports => {
				if (typeof reports !== 'undefined') {
					if (reports.length) {
						reports.forEach(report => {
							if (report.indexedDBMethod === '2-update') {
								collectionData.push(report);
							}
						});
						if (collectionData.length) {
							collectionData.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + report.type + '/' + report.id, report, false).then((data: ServerResponse) => {
											if (data.success === true) {
												let promises2 = [];
												promises2.push(this.database.update(this.tableReports, data.data)
													.then(() => true)
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													})
												);
												promises2.push(this.database.delete(this.tableReportsPush, report.entryId)
													.then(() => true)
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													})
												);

												return Promise.all(promises2).then(() => {
													resolve2();
												});
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsDelete(): Promise<void> {
		return new Promise(resolve => {
			let collectionData: Reports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsPush).then(reports => {
				if (typeof reports !== 'undefined') {
					if (reports.length) {
						reports.forEach(report => {
							if (report.indexedDBMethod === '3-delete') {
								collectionData.push(report);
							}
						});
						if (collectionData.length) {
							collectionData.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + report.type + '/' + report.id, false).then((data: ServerResponse) => {
											if (data.success === true) {
												let promises2 = [];

												promises2.push(this.database.delete(this.tableReports, report.id)
													.then(() => true)
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													})
												);
												promises2.push(this.database.delete(this.tableReportsPush, report.entryId)
													.then(() => true)
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													})
												);

												return Promise.all(promises2).then(() => {
													resolve2();
												});
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsAttachmentsCopy(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '0-copy') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.get('reports/' + attachment.report_type + '/' + attachment.copy_from_report_id + '/attachments/' + attachment.copy_from_attachment_id + '/copy/' + attachment.report_id, null, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.delete(this.tableReportsAttachments, attachment.id)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.add(this.tableReportsAttachments, data.data)
															.then(() => true)
														);

														let newId = parseInt(data.data.id, 10);

														promises2.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
															.then(reports_attachments2 => {
																let filtered = [];
																reports_attachments2.forEach(report_attachment => {
																	if (report_attachment.id === attachment.id) {
																		report_attachment.id = newId;
																		filtered.push(report_attachment);
																	}
																});
																if (filtered.length) {
																	return this.database.updateBulk(this.tableReportsAttachmentsPush, filtered)
																		.then(() => true)
																		.catch(error => {
																			if (this.debug) {
																				console.log(error);
																			}
																			throw new Error(error);
																		});
																}
																return true;
															})
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																attachment_id: data.data.id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsAttachmentsCreate(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '1-create') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let newAttachment = {
											filename: new FileInput(attachment.filename['_files']),
											title: attachment.title,
											description: attachment.description,
											append_warehouse: attachment.append_warehouse,
											append_ladder: attachment.append_ladder,
											append_tent: attachment.append_tent,
										};
										this.apiService.post('reports/' + attachment.report_type + '/' + attachment.report_id + '/attachments', newAttachment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];

															promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															if (data.data.id !== attachment.id) {
																promises2.push(this.database.delete(this.tableReportsAttachments, attachment.id)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
																promises2.push(this.database.add(this.tableReportsAttachments, <ReportsAttachments>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);

																let newId = parseInt(data.data.id, 10);

																promises2.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
																	.then(reports_attachments2 => {
																		let filtered = [];
																		reports_attachments2.forEach(report_attachment => {
																			if (report_attachment.id === attachment.id) {
																				report_attachment.id = newId;
																				filtered.push(report_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsAttachmentsPush, <ReportsAttachments[]>filtered)
																				.then(() => true)
																				.catch(error => {
																					if (this.debug) {
																						console.log(error);
																					}
																					throw new Error(error);
																				});
																		}
																		return true;
																	})
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															} else {
																promises2.push(this.database.update(this.tableReportsAttachments, <ReportsAttachments>data.data)
																	.then(() => true)
																	.catch(error => {
																		if (this.debug) {
																			console.log(error);
																		}
																		throw new Error(error);
																	})
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	report_id: data.data.report_id,
																	attachment_id: data.data.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsAttachmentsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '2-update') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + attachment.report_type + '/' + attachment.report_id + '/attachments/' + attachment.id, attachment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.update(this.tableReportsAttachments, <ReportsAttachments>data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsAttachmentsDelete(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '3-delete') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + attachment.report_type + '/' + attachment.report_id + '/attachments/' + attachment.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableReportsAttachments, attachment.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsCommentsCreate(): Promise<void> {
		return new Promise(resolve => {
			let commentsData: ReportsComments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsCommentsPush).then(reports_comments => {
				if (typeof reports_comments !== 'undefined') {
					if (reports_comments.length) {
						reports_comments.forEach(report_comment => {
							if (report_comment.indexedDBMethod === '1-create') {
								commentsData.push(report_comment);
							}
						});
						if (commentsData.length) {
							commentsData.forEach(comment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + comment.report_type + '/' + comment.report_id + '/comments', comment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];
														promises2.push(this.database.delete(this.tableReportsCommentsPush, comment.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														if (data.data.id !== comment['id']) {
															promises2.push(this.database.delete(this.tableReportsComments, comment.id)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.add(this.tableReportsComments, <ReportsComments>data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);

															let newId = parseInt(data.data.id, 10);

															promises2.push(this.database.getAllFast(this.tableReportsCommentsPush)
																.then(reports_comments2 => {
																	let filtered = [];
																	reports_comments2.forEach(report_comment => {
																		if (report_comment.id === comment.id) {
																			report_comment.id = newId;
																			filtered.push(report_comment);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsCommentsPush, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														} else {
															promises2.push(this.database.update(this.tableReportsComments, <ReportsComments>data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														}

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																comment_id: data.data.id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsCommentsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let commentsData: ReportsComments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsCommentsPush).then(reports_comments => {
				if (typeof reports_comments !== 'undefined') {
					if (reports_comments.length) {
						reports_comments.forEach(report_comment => {
							if (report_comment.indexedDBMethod === '2-update') {
								commentsData.push(report_comment);
							}
						});
						if (commentsData.length) {
							commentsData.forEach(comment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + comment.report_type + '/' + comment.report_id + '/comments/' + comment.id, comment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													let promises2 = [];
													promises2.push(this.database.update(this.tableReportsComments, <ReportsComments>data.data)
														.then(() => true)
														.catch(error => {
															if (this.debug) {
																console.log(error);
															}
															throw new Error(error);
														})
													);
													promises2.push(this.database.delete(this.tableReportsCommentsPush, comment.entryId)
														.then(() => true)
														.catch(error => {
															if (this.debug) {
																console.log(error);
															}
															throw new Error(error);
														})
													);

													return Promise.all(promises2).then(() => {
														resolve2();
													});
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsCommentsDelete(): Promise<void> {
		return new Promise(resolve => {
			let commentsData: ReportsComments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsCommentsPush).then(reports_comments => {
				if (typeof reports_comments !== 'undefined') {
					if (reports_comments.length) {
						reports_comments.forEach(report_comment => {
							if (report_comment.indexedDBMethod === '3-delete') {
								commentsData.push(report_comment);
							}
						});
						if (commentsData.length) {
							commentsData.forEach(comment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + comment.report_type + '/' + comment.report_id + '/comments/' + comment.id, false).then(() => {
											let promises2 = [];
											promises2.push(this.database.delete(this.tableReportsComments, comment.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableReportsCommentsPush, comment.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsWarehouseQuestionnaires(): Promise<void> {
		return new Promise(resolve => {
			let questionnaireData: ReportsQuestionsAnswers[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires start ...');
			}

			return this.database.getAllFast(this.tableReportsWarehouseQuestionnaireAnswers).then(reports_questionnaire_answers => {
				if (typeof reports_questionnaire_answers !== 'undefined') {
					if (reports_questionnaire_answers.length) {
						reports_questionnaire_answers.forEach(report_questionnaire_answer => {
							if (report_questionnaire_answer.indexedDBMethod === '2-update') {
								questionnaireData.push(report_questionnaire_answer);
							}
						});
						if (questionnaireData.length) {
							questionnaireData.forEach(questionnaire => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + questionnaire.report_type + '/' + questionnaire.report_id + '/questionnaire', questionnaire, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														return this.database.update(this.tableReportsWarehouseQuestionnaireAnswers, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													}
												} else if (data.success === false) {
													if (typeof data.error_type !== 'undefined') {
														if (data.error_type === 'report_not_found') {
															return this.database.delete(this.tableReportsWarehouseQuestionnaireAnswers, questionnaire.report_id)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																});
														}
													}
												}
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsWarehouseQuestionnaires nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsCreate(): Promise<void> {
		return new Promise(resolve => {
			let buildingsData: ReportsBuildings[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsPush).then(reports_buildings => {
				if (typeof reports_buildings !== 'undefined') {
					if (reports_buildings.length) {
						reports_buildings.forEach(report_building => {
							if (report_building.indexedDBMethod === '1-create') {
								buildingsData.push(report_building);
							}
						});
						if (buildingsData.length) {
							buildingsData.forEach(building => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + building.report_type + '/' + building.report_id + '/buildings/', building, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [],
															newId = parseInt(data.data.id, 10);

														promises2.push(this.database.delete(this.tableReportsBuildingsPush, building.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														if (data.data.id !== building.id) {
															promises2.push(this.database.delete(this.tableReportsBuildings, building.id)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.add(this.tableReportsBuildings, data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsPush)
																.then(reports_buildings2 => {
																	let filtered = [];
																	reports_buildings2.forEach(report_building => {
																		if (report_building.id === building.id) {
																			report_building.id = newId;
																			filtered.push(report_building);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsPush, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReports)
																.then(reports_buildings_reports => {
																	let filtered = [];
																	reports_buildings_reports.forEach(report_buildings_report => {
																		if (report_buildings_report.building_id === building.id) {
																			report_buildings_report.building_id = newId;
																			filtered.push(report_buildings_report);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReports, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
																.then(reports_buildings_reports => {
																	let filtered = [];
																	reports_buildings_reports.forEach(report_buildings_report => {
																		if (report_buildings_report.building_id === building.id) {
																			report_buildings_report.building_id = newId;
																			filtered.push(report_buildings_report);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPush, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
																.then(reports_buildings_reports_photos => {
																	let filtered = [];
																	reports_buildings_reports_photos.forEach(report_building_report_photo => {
																		if (report_building_report_photo.building_id === building.id) {
																			report_building_report_photo.building_id = newId;
																			filtered.push(report_building_report_photo);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPhotos, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														} else {
															promises2.push(this.database.update(this.tableReportsBuildings, data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														}

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																building_id: data.data.id,
																old_building_id: building.id,
																old_report_id: building.report_id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let buildingsData: ReportsBuildings[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsPush).then(reports_buildings => {
				if (typeof reports_buildings !== 'undefined') {
					if (reports_buildings.length) {
						reports_buildings.forEach(report_building => {
							if (report_building.indexedDBMethod === '2-update') {
								buildingsData.push(report_building);
							}
						});
						if (buildingsData.length) {
							buildingsData.forEach(building => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + building.report_type + '/' + building.report_id + '/buildings/' + building.id, building, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];
														promises2.push(this.database.delete(this.tableReportsBuildingsPush, building.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.update(this.tableReportsBuildings, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsDelete(): Promise<void> {
		return new Promise(resolve => {
			let buildingsData: ReportsBuildings[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsPush).then(reports_buildings => {
				if (typeof reports_buildings !== 'undefined') {
					if (reports_buildings.length) {
						reports_buildings.forEach(report_building => {
							if (report_building.indexedDBMethod === '3-delete') {
								buildingsData.push(report_building);
							}
						});
						if (buildingsData.length) {
							buildingsData.forEach(building => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + building.report_type + '/' + building.report_id + '/buildings/' + building.id, false).then(() => {
											let promises2 = [];
											promises2.push(this.database.delete(this.tableReportsBuildings, building.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableReportsBuildingsPush, building.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsReportsCreate(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: ReportsBuildingsReports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPush).then(reports_buildings_reports => {
				if (typeof reports_buildings_reports !== 'undefined') {
					if (reports_buildings_reports.length) {
						reports_buildings_reports.forEach(report_building_report => {
							if (report_building_report.indexedDBMethod === '1-create') {
								buildingReport.push(report_building_report);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										delete report.photos;
										this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports', report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [],
															newId = parseInt(data.data.id, 10);

														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPush, report.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														if (data.data.id !== report.id) {
															promises2.push(this.database.delete(this.tableReportsBuildingsReports, report.id)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.add(this.tableReportsBuildingsReports, data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
																.then(reports_building_reports2 => {
																	let filtered = [];
																	reports_building_reports2.forEach(report_building_report => {
																		if (report_building_report.id === report.id) {
																			report_building_report.id = newId;
																			filtered.push(report_building_report);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPush, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
																.then(reports_buildings_reports_photos => {
																	let filtered = [];
																	reports_buildings_reports_photos.forEach(report_building_report_photo => {
																		if (report_building_report_photo.building_report_id === report.id) {
																			report_building_report_photo.building_report_id = newId;
																			filtered.push(report_building_report_photo);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPhotos, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsLadderQuestionnaireAnswers)
																.then(reports_ladder_questionnaire_answers => {
																	let filtered = [];
																	reports_ladder_questionnaire_answers.forEach(report_ladder_questionnaire_answer => {
																		if (report_ladder_questionnaire_answer.building_report_id === report.id) {
																			report_ladder_questionnaire_answer.building_report_id = newId;
																			filtered.push(report_ladder_questionnaire_answer);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsLadderQuestionnaireAnswers, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsTentQuestionnaireAnswers)
																.then(reports_tent_questionnaire_answers => {
																	let filtered = [];
																	reports_tent_questionnaire_answers.forEach(report_tent_questionnaire_answer => {
																		if (report_tent_questionnaire_answer.building_report_id === report.id) {
																			report_tent_questionnaire_answer.building_report_id = newId;
																			filtered.push(report_tent_questionnaire_answer);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsTentQuestionnaireAnswers, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														} else {
															promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														}

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																building_id: data.data.building_id,
																building_report_id: data.data.id,
																old_report_id: report.report_id,
																old_building_id: report.building_id,
																old_building_report_id: report.id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsReportsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: ReportsBuildingsReports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPush).then(reports_buildings_reports => {
				if (typeof reports_buildings_reports !== 'undefined') {
					if (reports_buildings_reports.length) {
						reports_buildings_reports.forEach(report_building_report => {
							if (report_building_report.indexedDBMethod === '2-update') {
								buildingReport.push(report_building_report);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										delete report.photos;
										this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPush, report.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsReportsDelete(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: ReportsBuildingsReports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPush).then(reports_buildings_reports => {
				if (typeof reports_buildings_reports !== 'undefined') {
					if (reports_buildings_reports.length) {
						reports_buildings_reports.forEach(report_building_report => {
							if (report_building_report.indexedDBMethod === '3-delete') {
								buildingReport.push(report_building_report);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableReportsBuildingsReports, report.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableReportsBuildingsReportsPush, report.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsReportsPhotosCreate(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: any[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPhotos).then(reports_buildings_reports_photos => {
				if (typeof reports_buildings_reports_photos !== 'undefined') {
					if (reports_buildings_reports_photos.length) {
						reports_buildings_reports_photos.forEach(report_building_report_photo => {
							if (report_building_report_photo.indexedDBMethod === '1-create') {
								buildingReport.push(report_building_report_photo);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let photos = {
											photos: []
										};
										report.photos.forEach(photo => {
											photos.photos.push(photo.file);
										});

										this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/photos', photos, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPhotos, report.id)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													// was building removed already?
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.building_missing !== 'undefined') {
															this.database.delete(this.tableReportsBuildingsReportsPhotos, report.id)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																});
														}
													}

													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsReportsPhotosDelete(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: any[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPhotos).then(reports_buildings_reports_photos => {
				if (typeof reports_buildings_reports_photos !== 'undefined') {
					if (reports_buildings_reports_photos.length) {
						reports_buildings_reports_photos.forEach(report_building_report_photo => {
							if (report_building_report_photo.indexedDBMethod === '3-delete') {
								buildingReport.push(report_building_report_photo);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/delete-image/' + report.id, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPhotos, report.id)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsMapsCreate(): Promise<void> {
		return new Promise(resolve => {
			let buildingMap: ReportsBuildingsMaps[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsMapsPush).then(reports_buildings_maps => {
				if (typeof reports_buildings_maps !== 'undefined') {
					if (reports_buildings_maps.length) {
						reports_buildings_maps.forEach(reports_building_map => {
							if (reports_building_map.indexedDBMethod === '1-create') {
								buildingMap.push(reports_building_map);
							}
						});
						if (buildingMap.length) {
							buildingMap.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/maps', report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [],
															newId = parseInt(data.data.id, 10);

														promises2.push(this.database.delete(this.tableReportsBuildingsMapsPush, report.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														if (data.data.id !== report.id) {
															promises2.push(this.database.delete(this.tableReportsBuildingsMaps, report.id)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.add(this.tableReportsBuildingsMaps, data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsMapsPush)
																.then(reports_building_maps2 => {
																	let filtered = [];
																	reports_building_maps2.forEach(report_building_map => {
																		if (report_building_map.id === report.id) {
																			report_building_map.id = newId;
																			filtered.push(report_building_map);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsMapsPush, filtered)
																			.then(() => true)
																			.catch(error => {
																				if (this.debug) {
																					console.log(error);
																				}
																				throw new Error(error);
																			});
																	}
																	return true;
																})
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														} else {
															promises2.push(this.database.update(this.tableReportsBuildingsMaps, data.data)
																.then(() => true)
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																})
															);
														}

														return Promise.all(promises2).then(() => resolve2());
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsMapsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let buildingMap: ReportsBuildingsMaps[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsMapsPush).then(reports_buildings_maps => {
				if (typeof reports_buildings_maps !== 'undefined') {
					if (reports_buildings_maps.length) {
						reports_buildings_maps.forEach(report_building_map => {
							if (report_building_map.indexedDBMethod === '2-update') {
								buildingMap.push(report_building_map);
							}
						});
						if (buildingMap.length) {
							buildingMap.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/maps/' + report.id, report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.update(this.tableReportsBuildingsMaps, data.data)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);
														promises2.push(this.database.delete(this.tableReportsBuildingsMapsPush, report.entryId)
															.then(() => true)
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															})
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsBuildingsMapsDelete(): Promise<void> {
		return new Promise(resolve => {
			let buildingMap: ReportsBuildingsMaps[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsMapsPush).then(reports_buildings_maps => {
				if (typeof reports_buildings_maps !== 'undefined') {
					if (reports_buildings_maps.length) {
						reports_buildings_maps.forEach(reports_building_map => {
							if (reports_building_map.indexedDBMethod === '3-delete') {
								buildingMap.push(reports_building_map);
							}
						});
						if (buildingMap.length) {
							buildingMap.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/maps/' + report.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableReportsBuildingsMaps, report.id)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);
											promises2.push(this.database.delete(this.tableReportsBuildingsMapsPush, report.entryId)
												.then(() => true)
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												})
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsMapsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsSendToClient(): Promise<void> {
		return new Promise(resolve => {
			let promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient start ...');
			}

			return this.database.getAllFast(this.tableReportsSendToClient).then(reports_send_to_client => {
				if (typeof reports_send_to_client !== 'undefined') {
					if (reports_send_to_client.length) {
						reports_send_to_client.forEach(report => {
							promises.push(new Promise(resolve2 => {
								if (this.apiService.isOnline) {
									return this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/send-to-client', report, false)
										.then(() => {
											return this.database.delete(this.tableReportsSendToClient, report.id)
												.then(() => resolve2())
												.catch(error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												});
										})
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								} else {
									resolve2();
								}
							}));
						});

						return Promise.all(promises).then(() => {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient done');
							}
							resolve();
						});
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	pushToServer_ReportsVerifyInspectorStatus(): Promise<void> {
		return new Promise(resolve => {
			let promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus start ...');
			}

			return this.database.getAllFast(this.tableReportsVerifyInspectorStatus).then(reports_verify_inspector_status => {
				if (typeof reports_verify_inspector_status !== 'undefined') {
					if (reports_verify_inspector_status.length) {
						reports_verify_inspector_status.forEach(report => {
							promises.push(new Promise(resolve2 => {
								if (this.apiService.isOnline) {
									return this.apiService.post('reports/' + report.report_type + '/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/verify-inspector-status', report, false).then((data: ServerResponse) => {
										let promises2 = [];

										promises2.push(this.database.delete(this.tableReportsVerifyInspectorStatus, report.entryId)
											.then(() => resolve2())
											.catch(error => {
												if (this.debug) {
													console.log(error);
												}
												throw new Error(error);
											}));

										if (typeof data !== 'undefined') {
											if (data.success === true) {
												if (typeof data.data !== 'undefined') {

													promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
														.then(() => true)
														.catch(error => {
															if (this.debug) {
																console.log(error);
															}
															throw new Error(error);
														})
													);
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										} else {
											resolve2();
										}

										return Promise.all(promises2).then(() => {
											resolve2();
										});
									}).catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
								} else {
									resolve2();
								}
							}));
						});

						return Promise.all(promises).then(() => {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus done');
							}
							resolve();
						});
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsVerifyInspectorStatus nothing to do');
					}
					resolve();
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	deleteInspectorInventory(inventory_ids: Array<{ iiid: number }>): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.database.deleteBulk(this.tableInspectorsInventory, inventory_ids.map(ids => ids.iiid))
				.then(() => true)
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableInspectorsInventoryPush)
				.then(inventories => {
					if (typeof inventories !== 'undefined') {
						if (inventories.length) {
							let filtered = [];
							inventories.forEach(inventory => {
								inventory_ids.forEach(inventory_id => {
									if (inventory.id === inventory_id.iiid) {
										filtered.push(inventory.entryId);
									}
								});
							});

							if (filtered.length) {
								filtered = [...new Set(filtered)];
								return this.database.deleteBulk(this.tableInspectorsInventoryPush, filtered)
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableInspectorsInventorySendToClient)
				.then(inventories => {
					if (typeof inventories !== 'undefined') {
						if (inventories.length) {
							let filtered = [];
							inventories.forEach(inventory => {
								inventory_ids.forEach(inventory_id => {
									if (inventory.inventory_id === inventory_id.iiid) {
										filtered.push(inventory.id);
									}
								});
							});

							if (filtered.length) {
								filtered = [...new Set(filtered)];
								return this.database.deleteBulk(this.tableInspectorsInventorySendToClient, filtered)
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteInspectorCalibration(calibration_ids: Array<{ icid: number }>): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.database.deleteBulk(this.tableInspectorsCalibrations, calibration_ids.map(ids => ids.icid))
				.then(() => true)
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableInspectorsCalibrationsPush)
				.then(calibrations => {
					if (typeof calibrations !== 'undefined') {
						if (calibrations.length) {
							let filtered = [];
							calibrations.forEach(calibration => {
								calibration_ids.forEach(calibration_id => {
									if (calibration.id === calibration_id.icid) {
										filtered.push(calibration.entryId);
									}
								});
							});

							if (filtered.length) {
								filtered = [...new Set(filtered)];
								return this.database.deleteBulk(this.tableInspectorsCalibrationsPush, filtered)
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteClient(client_ids: Array<{ cid: number }>): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.database.deleteBulk(this.tableClients, client_ids.map(ids => ids.cid))
				.then(() => true)
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableClientsPush)
				.then(clients => {
					if (typeof clients !== 'undefined') {
						if (clients.length) {
							let filtered = [];
							clients.forEach(client => {
								client_ids.forEach(client_id => {
									if (client.client_id === client_id.cid) {
										filtered.push(client.entryId);
									}
								});
							});

							if (filtered.length) {
								filtered = [...new Set(filtered)];
								return this.database.deleteBulk(this.tableClientsPush, filtered)
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableInspectorsInspections)
				.then(inspections => {
					if (typeof inspections !== 'undefined') {
						if (inspections.length) {
							let filtered = [];
							inspections.forEach(inspection => {
								client_ids.forEach(client_id => {
									if (inspection.client_id === client_id.cid) {
										filtered.push(inspection.id);
									}
								});
							});

							if (filtered.length) {
								filtered = [...new Set(filtered)];
								return this.database.deleteBulk(this.tableInspectorsInspections, filtered)
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			promises.push(this.deleteClientAttachment(client_ids));
			promises.push(this.deleteClientContactPerson(client_ids));
			promises.push(this.deleteClientBuildingMaps(client_ids));

			promises.push(this.deleteReport(client_ids));

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteClientAttachment(client_ids: Array<{ cid: number }> = null, attachment_ids: Array<{ caid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (attachment_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableClientsAttachments, attachment_ids.map(ids => ids.caid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableClientsAttachmentsPush)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									attachment_ids.forEach(attachment_id => {
										if (attachment.id === attachment_id.caid) {
											filtered.push(attachment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsAttachmentsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}

							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (client_ids !== null) {
				promises.push(this.database.getAllFast(this.tableClientsAttachments)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									client_ids.forEach(client_id => {
										if (attachment.client_id === client_id.cid) {
											filtered.push(attachment.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsAttachments, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableClientsAttachmentsPush)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									client_ids.forEach(client_id => {
										if (attachment.client_id === client_id.cid) {
											filtered.push(attachment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsAttachmentsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteClientContactPerson(client_ids: Array<{ cid: number }> = null, contact_person_ids: Array<{
		cid: number,
		ccpid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (contact_person_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableClientsContactPersons, contact_person_ids.map(ids => ids.ccpid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableClientsContactPersonsPush)
					.then(contact_persons => {
						if (typeof contact_persons !== 'undefined') {
							if (contact_persons.length) {
								let filtered = [];
								contact_persons.forEach(contact_person => {
									contact_person_ids.forEach(contact_person_id => {
										if (contact_person.client_id === contact_person_id.cid) {
											filtered.push(contact_person.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsAttachmentsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (client_ids !== null) {
				promises.push(this.database.getAllFast(this.tableClientsContactPersons)
					.then(contact_persons => {
						if (typeof contact_persons !== 'undefined') {
							if (contact_persons.length) {
								let filtered = [];
								contact_persons.forEach(contact_person => {
									client_ids.forEach(client_id => {
										if (contact_person.client_id === client_id.cid) {
											filtered.push(contact_person.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsContactPersons, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableClientsContactPersonsPush)
					.then(contact_persons => {
						if (typeof contact_persons !== 'undefined') {
							if (contact_persons.length) {
								let filtered = [];
								contact_persons.forEach(contact_person => {
									client_ids.forEach(client_id => {
										if (contact_person.client_id === client_id.cid) {
											filtered.push(contact_person.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsContactPersonsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteClientBuildingMaps(client_ids: Array<{ cid: number }> = null, building_map_ids: Array<{ cbmid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (building_map_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableClientsBuildingsMaps, building_map_ids.map(ids => ids.cbmid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableClientsBuildingsMapsPush)
					.then(buildingMaps => {
						if (typeof buildingMaps !== 'undefined') {
							if (buildingMaps.length) {
								let filtered = [];
								buildingMaps.forEach(buildingMap => {
									building_map_ids.forEach(building_map_id => {
										if (buildingMap.id === building_map_id.cbmid) {
											filtered.push(buildingMap.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsBuildingsMapsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}

							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (client_ids !== null) {
				promises.push(this.database.getAllFast(this.tableClientsBuildingsMaps)
					.then(buildingMaps => {
						if (typeof buildingMaps !== 'undefined') {
							if (buildingMaps.length) {
								let filtered = [];
								buildingMaps.forEach(buildingMap => {
									client_ids.forEach(client_id => {
										if (buildingMap.client_id === client_id.cid) {
											filtered.push(buildingMap.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableClientsBuildingsMaps, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReport(client_ids: Array<{ cid: number }> = null, report_ids: Array<{ rid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (client_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReports)
					.then(reports => {
						if (typeof reports !== 'undefined') {
							if (reports.length) {
								let filtered = [];
								reports.forEach(report => {
									client_ids.forEach(client_id => {
										if (report.client_id === client_id.cid) {
											filtered.push(report.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReports, filtered)
										.then(() => {
											let promises2 = [],
												rids = filtered.map(report => {
													return {rid: report.id};
												});
											promises2.push(this.deleteReportAttachment(rids));
											promises2.push(this.deleteReportComment(rids));
											promises2.push(this.deleteReportSendToClient(client_ids));
											promises2.push(this.deleteReportBuilding(rids));
											promises2.push(this.deleteReportBuildingReport(rids));
											promises2.push(this.deleteReportBuildingReportPhoto(rids));
											promises2.push(this.deleteReportBuildingReportLadderAndTentQuestionnaire(rids));
											promises2.push(this.deleteReportBuildingReportWarehouseQuestionnaire(rids));

											return Promise.all(promises2).then(() => true);
										})
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});

								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsPush)
					.then(reports => {
						if (typeof reports !== 'undefined') {
							if (reports.length) {
								let filtered = [];
								reports.forEach(report => {
									client_ids.forEach(client_id => {
										if (report.client_id === client_id.cid) {
											filtered.push(report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsPush, filtered)
										.then(() => {
											let promises2 = [],
												rids = filtered.map(report => {
													return {rid: report.id};
												});
											promises2.push(this.deleteReportAttachment(rids));
											promises2.push(this.deleteReportComment(rids));
											promises2.push(this.deleteReportBuilding(rids));
											promises2.push(this.deleteReportBuildingReport(rids));
											promises2.push(this.deleteReportBuildingReportPhoto(rids));
											promises2.push(this.deleteReportBuildingReportLadderAndTentQuestionnaire(rids));
											promises2.push(this.deleteReportBuildingReportWarehouseQuestionnaire(rids));

											return Promise.all(promises2).then(() => true);
										})
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReports, report_ids.map(ids => ids.rid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsPush)
					.then(reports => {
						if (typeof reports !== 'undefined') {
							if (reports.length) {
								let filtered = [];
								reports.forEach(report => {
									report_ids.forEach(report_id => {
										if (report.id === report_id.rid) {
											filtered.push(report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);

				promises.push(this.deleteReportAttachment(report_ids));
				promises.push(this.deleteReportComment(report_ids));
				promises.push(this.deleteReportBuilding(report_ids));
				promises.push(this.deleteReportBuildingReport(report_ids));
				promises.push(this.deleteReportBuildingReportPhoto(report_ids));
				promises.push(this.deleteReportBuildingReportLadderAndTentQuestionnaire(report_ids));
				promises.push(this.deleteReportBuildingReportWarehouseQuestionnaire(report_ids));
				promises.push(this.deleteReportSendToClient(null, report_ids));
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportAttachment(report_ids: Array<{ rid: number }>, attachment_ids: Array<{ rid: number, raid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (attachment_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsAttachments, attachment_ids.map(ids => ids.raid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									attachment_ids.forEach(attachment_id => {
										if (attachment.report_id === attachment_id.rid && attachment.id === attachment_id.raid) {
											filtered.push(attachment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsAttachmentsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsAttachments)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									report_ids.forEach(report_id => {
										if (attachment.report_id === report_id.rid) {
											filtered.push(attachment.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsAttachments, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									report_ids.forEach(report_id => {
										if (attachment.report_id === report_id.rid) {
											filtered.push(attachment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsAttachmentsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportComment(report_ids: Array<{ rid: number }>, comment_ids: Array<{ rid: number, rcid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (comment_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsComments, comment_ids.map(ids => ids.rcid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsCommentsPush)
					.then(comments => {
						if (typeof comments !== 'undefined') {
							if (comments.length) {
								let filtered = [];
								comments.forEach(comment => {
									comment_ids.forEach(comment_id => {
										if (comment.id === comment_id.rcid) {
											filtered.push(comment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsCommentsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsComments)
					.then(comments => {
						if (typeof comments !== 'undefined') {
							if (comments.length) {
								let filtered = [];
								comments.forEach(comment => {
									report_ids.forEach(report_id => {
										if (comment.report_id === report_id.rid) {
											filtered.push(comment.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsComments, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsCommentsPush)
					.then(comments => {
						if (typeof comments !== 'undefined') {
							if (comments.length) {
								let filtered = [];
								comments.forEach(comment => {
									report_ids.forEach(report_id => {
										if (comment.report_id === report_id.rid) {
											filtered.push(comment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsCommentsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuilding(report_ids: Array<{ rid: number }>, building_ids: Array<{ rid: number, rbid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (building_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsBuildings, building_ids.map(ids => ids.rbid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsPush)
					.then(report_buildings => {
						if (typeof report_buildings !== 'undefined') {
							if (report_buildings.length) {
								let filtered = [];
								report_buildings.forEach(report_building => {
									building_ids.forEach(building_id => {
										if (report_building.id === building_id.rbid) {
											filtered.push(report_building.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
				);

				promises.push(this.deleteReportBuildingReport(null, building_ids));
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildings)
					.then(report_buildings => {
						if (typeof report_buildings !== 'undefined') {
							if (report_buildings.length) {
								let filtered = [];
								report_buildings.forEach(report_building => {
									report_ids.forEach(report_id => {
										if (report_building.report_id === report_id.rid) {
											filtered.push(report_building.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildings, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsPush)
					.then(report_buildings => {
						if (typeof report_buildings !== 'undefined') {
							if (report_buildings.length) {
								let filtered = [];
								report_buildings.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuildingReport(report_ids: Array<{ rid: number }>, building_ids: Array<{
		rid: number,
		rbid: number
	}> = null, building_report_ids: Array<{ rid: number, rbid: number, rbrid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (building_report_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsBuildingsReports, building_report_ids.map(ids => ids.rbrid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									building_report_ids.forEach(building_report_id => {
										if (building_report.id === building_report_id.rbrid) {
											filtered.push(building_report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);

				promises.push(this.deleteReportBuildingReportLadderAndTentQuestionnaire(null, building_report_ids));
			}
			if (building_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReports)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									building_ids.forEach(building_id => {
										if (building_report.building_id === building_id.rbid) {
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsBuildingsReports, filtered.map(ids => ids.id))
										.then(() => this.deleteReportBuildingReportLadderAndTentQuestionnaire(null, filtered.map(ids => {
											return {
												rid: ids.report_id,
												rbid: ids.building_id,
												rbrid: ids.id
											};
										})))
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									building_ids.forEach(building_id => {
										if (building_report.building_id === building_id.rbid) {
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPush, filtered.map(ids => ids.entryId))
										.then(() => this.deleteReportBuildingReportLadderAndTentQuestionnaire(null, filtered.map(ids => {
											return {
												rid: ids.report_id,
												rbid: ids.building_id,
												rbrid: ids.id
											};
										})))
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});

								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReports)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];

								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReports, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.deleteReportBuildingReportLadderAndTentQuestionnaire(report_ids));
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});

								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuildingReportPhoto(report_ids: Array<{ rid: number }>, photo_ids: Array<{
		rid: number,
		rbid: number,
		rbrid: number,
		rbrpid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];

			if (photo_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReports)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									photo_ids.forEach(photo_id => {
										if (building_report.id === photo_id.rbrid && building_report.building_id === photo_id.rbid && typeof building_report.photos !== 'undefined') {
											if (typeof building_report.photos.inspector !== 'undefined') {
												building_report.photos.inspector.forEach((photo, index) => {
													if (parseInt(photo.id, 10) === photo_id.rbrpid) {
														building_report.photos.inspector.splice(index, 1);
													}
												});
											}
											if (typeof building_report.photos.by_client !== 'undefined') {
												building_report.photos.inspector.forEach((photo, index) => {
													if (parseInt(photo.id, 10) === photo_id.rbrpid) {
														building_report.photos.by_client.splice(index, 1);
													}
												});
											}
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.update(this.tableReportsBuildingsReports, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
					.then(report_building_photos => {
						if (typeof report_building_photos !== 'undefined') {
							if (report_building_photos.length) {
								let filtered = [];
								report_building_photos.forEach(report_building_photo => {
									report_ids.forEach(report_id => {
										if (report_building_photo.report_id === report_id.rid) {
											filtered.push(report_building_photo.id);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPhotos, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuildingMap(report_ids: Array<{ rid: number }>, building_map_ids: Array<{
		rid: number,
		rbmid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (building_map_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsBuildingsMaps, building_map_ids.map(ids => ids.rbmid))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsMapsPush)
					.then(building_maps => {
						if (typeof building_maps !== 'undefined') {
							if (building_maps.length) {
								let filtered = [];
								building_maps.forEach(building_map => {
									building_map_ids.forEach(building_map_id => {
										if (building_map.id === building_map_id.rbmid) {
											filtered.push(building_map.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsMapsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReports)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];

								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReports, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.deleteReportBuildingReportLadderAndTentQuestionnaire(report_ids));
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPush, filtered)
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});

								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuildingReportLadderAndTentQuestionnaire(report_ids: Array<{ rid: number }>, building_report_ids: Array<{
		rid: number,
		rbid: number,
		rbrid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (building_report_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsLadderQuestionnaireAnswers, building_report_ids.map(br => {
						return [br.rid, br.rbrid];
					}))
						.then(() => true)
						.catch(error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						})
				);
				promises.push(this.database.deleteBulk(this.tableReportsTentQuestionnaireAnswers, building_report_ids.map(br => {
						return [br.rid, br.rbrid];
					}))
						.then(() => true)
						.catch(error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						})
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsLadderQuestionnaireAnswers)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsLadderQuestionnaireAnswers, filtered.map(ids => {
										return [ids.report_id, ids.building_report_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsTentQuestionnaireAnswers)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsTentQuestionnaireAnswers, filtered.map(ids => {
										return [ids.report_id, ids.building_report_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuildingReportWarehouseQuestionnaire(report_ids: Array<{ rid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsWarehouseQuestionnaireAnswers, report_ids.map(ids => ids.rid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteReportSendToClient(client_ids: Array<{ cid: number }> = null, report_ids: Array<{ rid: number }> = null): Promise<void> {
		return this.database.getAllFast(this.tableReportsSendToClient)
			.then(reports_send_to_client => {
				if (typeof reports_send_to_client !== 'undefined') {
					if (reports_send_to_client.length) {
						let filtered = [];
						if (client_ids !== null) {
							reports_send_to_client.forEach(report_send_to_client => {
								client_ids.forEach(client_id => {
									if (report_send_to_client.client_id === client_id.cid) {
										filtered.push(report_send_to_client.id);
									}
								});
							});
						}
						if (report_ids !== null) {
							reports_send_to_client.forEach(report_send_to_client => {
								report_ids.forEach(report_id => {
									if (report_send_to_client.report_id === report_id.rid) {
										filtered.push(report_send_to_client.id);
									}
								});
							});
						}

						if (filtered.length) {
							filtered = [...new Set(filtered)];
							return this.database.deleteBulk(this.tableReportsSendToClient, filtered)
								.then(() => true)
								.catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
						}
					}
				}
				return true;
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteCountry(country_ids: Array<{ cid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableCountries, country_ids.map(ids => ids.cid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteInspector(inspector_ids: Array<{ aid: number }>): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.database.deleteBulk(this.tableInspectors, inspector_ids.map(ids => ids.aid))
				.then(() => true)
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableInspectorsInspections)
				.then(inspections => {
					if (typeof inspections !== 'undefined') {
						if (inspections.length) {
							let filtered = [];
							inspections.forEach(inspection => {
								inspector_ids.forEach(inspector_id => {
									if (inspection.inspector_id === inspector_id.aid) {
										filtered.push(inspection.id);
									}
								});
							});

							if (filtered.length) {
								filtered = [...new Set(filtered)];
								return this.database.deleteBulk(this.tableInspectorsInspections, filtered)
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteLanguage(language_ids: Array<{ lid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableLanguages, language_ids.map(ids => ids.lid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteBuildingParticulars(particular_ids: Array<{ bpid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsBuildingParticulars, particular_ids.map(ids => ids.bpid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteLadderCategory(category_ids: Array<{ lcid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsLadderCategories, category_ids.map(ids => ids.lcid))
			.then(() => this.deleteLadderSubcategory(category_ids))
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteLadderSubcategory(category_ids: Array<{ lcid: number }>, subcategory_ids: Array<{
		lcid: number,
		lsid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (subcategory_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsLadderSubcategories, subcategory_ids.map(ids => {
					return [ids.lsid, ids.lcid];
				}))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					}));
			}
			if (category_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsLadderSubcategories)
					.then(subcategories => {
						if (typeof subcategories !== 'undefined') {
							if (subcategories.length) {
								let filtered = [];
								subcategories.forEach(subcategory => {
									category_ids.forEach(category_id => {
										if (subcategory.category_id === category_id.lcid) {
											filtered.push(subcategory);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsLadderSubcategories, filtered.map(ids => {
										return [ids.id, ids.category_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteLadderMaterial(material_ids: Array<{ lmid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsLadderMaterials, material_ids.map(ids => ids.lmid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteLadderQuestionnaire(questionnaire_ids: Array<{ lqid: number }>): Promise<void> {
		return this.database.delete(this.tableReportsLadderQuestionnaire, questionnaire_ids.map(ids => ids.lqid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteLadderCategorySubcategoryQuestionnaire(questionnaire_ids: Array<{ lcid: number, lsid: number, lqid: number }>): Promise<void> {
		return this.database.getAllFast(this.tableReportsLadderQuestionnaire)
			.then(questionnaires => {
				if (typeof questionnaires !== 'undefined') {
					if (questionnaires.length) {
						let filtered = [];
						questionnaires.forEach(questionnaire => {
							questionnaire_ids.forEach(questionnaire_id => {
								if (questionnaire.category_id === questionnaire_id.lcid && questionnaire.subcategory_id === questionnaire_id.lsid && questionnaire.id === questionnaire_id.lqid) {
									filtered.push(questionnaire.id);
								}
							});
						});

						if (filtered.length) {
							filtered = [...new Set(filtered)];
							return this.database.deleteBulk(this.tableReportsLadderQuestionnaire, filtered)
								.then(() => true)
								.catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
						}
					}
				}
				return true;
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteWarehouseAction(action_ids: Array<{ waid: number }>): Promise<void> {
		return this.database.getAllFast(this.tableReportsWarehouseActions)
			.then(actions => {
				if (actions !== 'undefined') {
					if (actions.length) {
						let filtered = [];
						actions.forEach(action => {
							action_ids.forEach(action_id => {
								if (action.id === action_id.waid) {
									filtered.push(action);
								}
							});
						});

						if (filtered.length) {
							return this.database.deleteBulk(this.tableReportsWarehouseActions, filtered.map(ids => {
								return [ids.id, ids.category_id, ids.subcategory_id];
							}))
								.then(() => true)
								.catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
						}
					}
				}
				return true;
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteWarehouseCategory(category_ids: Array<{ wcid: number }>): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.database.deleteBulk(this.tableReportsWarehouseCategories, category_ids.map(ids => ids.wcid))
				.then(() => true)
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableReportsWarehouseActions)
				.then(actions => {
					if (typeof actions !== 'undefined') {
						if (actions.length) {
							let filtered = [];
							actions.forEach(action => {
								category_ids.forEach(category_id => {
									if (action.category_id === category_id.wcid) {
										filtered.push(action);
									}
								});
							});

							if (filtered.length) {
								return this.database.deleteBulk(this.tableReportsWarehouseActions, filtered.map(ids => {
									return [ids.id, ids.category_id, ids.subcategory_id];
								}))
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.database.getAllFast(this.tableReportsWarehouseTypes)
				.then(types => {
					if (typeof types !== 'undefined') {
						if (types.length) {
							let filtered = [];
							types.forEach(type => {
								category_ids.forEach(category_id => {
									if (type.category_id === category_id.wcid) {
										filtered.push(type);
									}
								});
							});

							if (filtered.length) {
								return this.database.deleteBulk(this.tableReportsWarehouseTypes, filtered.map(ids => {
									return [ids.id, ids.category_id, ids.subcategory_id];
								}))
									.then(() => true)
									.catch(error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
							}
						}
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.deleteWarehouseSubcategory(category_ids));

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteWarehouseSubcategory(category_ids: Array<{ wcid: number }> = null, subcategory_ids: Array<{
		wcid: number,
		wsid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (subcategory_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsWarehouseSubcategories, subcategory_ids.map(ids => {
						return [ids.wsid, ids.wcid];
					}))
						.then(() => true)
						.catch(error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						})
				);

				promises.push(this.database.getAllFast(this.tableReportsWarehouseActions)
					.then(actions => {
						if (typeof actions !== 'undefined') {
							if (actions.length) {
								let filtered = [];
								actions.forEach(action => {
									subcategory_ids.forEach(subcategory_id => {
										if (action.subcategory_id === subcategory_id.wsid) {
											filtered.push(action);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsWarehouseActions, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);

				promises.push(this.database.getAllFast(this.tableReportsWarehouseTypes)
					.then(types => {
						if (typeof types !== 'undefined') {
							if (types.length) {
								let filtered = [];
								types.forEach(type => {
									subcategory_ids.forEach(subcategory_id => {
										if (type.subcategory_id === subcategory_id.wsid) {
											filtered.push(type);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsWarehouseTypes, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}
			if (category_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsWarehouseSubcategories)
					.then(subcategories => {
						if (typeof subcategories !== 'undefined') {
							if (subcategories.length) {
								let filtered = [];
								subcategories.forEach(subcategory => {
									category_ids.forEach(category_id => {
										if (subcategory.category_id === category_id.wcid) {
											filtered.push(subcategory);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsWarehouseSubcategories, filtered.map(ids => {
										return [ids.id, ids.category_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsWarehouseActions)
					.then(actions => {
						if (typeof actions !== 'undefined') {
							if (actions.length) {
								let filtered = [];
								actions.forEach(action => {
									category_ids.forEach(category_id => {
										if (action.category_id === category_id.wcid) {
											filtered.push(action);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsWarehouseActions, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
				promises.push(this.database.getAllFast(this.tableReportsWarehouseTypes)
					.then(types => {
						if (typeof types !== 'undefined') {
							if (types.length) {
								let filtered = [];
								types.forEach(type => {
									category_ids.forEach(category_id => {
										if (type.category_id === category_id.wcid) {
											filtered.push(type);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsWarehouseTypes, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteWarehouseQuestionnaire(questionnaire_ids: Array<{ wqid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsWarehouseQuestionnaire, questionnaire_ids.map(ids => ids.wqid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteWarehouseType(type_ids: Array<{ wtid; number }>): Promise<void> {
		return this.database.getAllFast(this.tableReportsWarehouseTypes)
			.then(types => {
				if (typeof types !== 'undefined') {
					if (types.length) {
						let filtered = [];
						types.forEach(type => {
							type_ids.forEach(type_id => {
								if (type.id === type_id.wtid) {
									filtered.push(type);
								}
							});
						});

						if (filtered.length) {
							return this.database.deleteBulk(this.tableReportsWarehouseTypes, filtered.map(ids => {
								return [ids.id, ids.category_id, ids.subcategory_id];
							}))
								.then(() => true)
								.catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
						}
					}
				}
				return true;
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteWarehouseCategorySubcategoryAction(action_ids: Array<{ wcid: number, wsid: number, waid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsWarehouseActions, action_ids.map(ids => {
			return [ids.waid, ids.wcid, ids.wsid];
		}))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteWarehouseCategorySubcategoryType(type_ids: Array<{ wcid: number, wsid: number, wtid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsWarehouseTypes, type_ids.map(ids => {
			return [ids.wtid, ids.wcid, ids.wsid];
		}))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteTentCategory(category_ids: Array<{ tcid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsTentCategories, category_ids.map(ids => ids.tcid))
			.then(() => this.deleteTentSubcategory(category_ids))
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteTentSubcategory(category_ids: Array<{ tcid: number }>, subcategory_ids: Array<{
		tcid: number,
		tsid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (subcategory_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsTentSubcategories, subcategory_ids.map(ids => {
					return [ids.tsid, ids.tcid];
				}))
					.then(() => true)
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					}));
			}
			if (category_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsTentSubcategories)
					.then(subcategories => {
						if (typeof subcategories !== 'undefined') {
							if (subcategories.length) {
								let filtered = [];
								subcategories.forEach(subcategory => {
									category_ids.forEach(category_id => {
										if (subcategory.category_id === category_id.tcid) {
											filtered.push(subcategory);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsTentSubcategories, filtered.map(ids => {
										return [ids.id, ids.category_id];
									}))
										.then(() => true)
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								}
							}
						}
						return true;
					})
					.catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					})
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteTentQuestionnaire(questionnaire_ids: Array<{ tqid: number }>): Promise<void> {
		return this.database.delete(this.tableReportsTentQuestionnaire, questionnaire_ids.map(ids => ids.tqid))
			.then(() => true)
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	deleteTentCategorySubcategoryQuestionnaire(questionnaire_ids: Array<{ tcid: number, tsid: number, tqid: number }>): Promise<void> {
		return this.database.getAllFast(this.tableReportsTentQuestionnaire)
			.then(questionnaires => {
				if (typeof questionnaires !== 'undefined') {
					if (questionnaires.length) {
						let filtered = [];
						questionnaires.forEach(questionnaire => {
							questionnaire_ids.forEach(questionnaire_id => {
								if (questionnaire.category_id === questionnaire_id.tcid && questionnaire.subcategory_id === questionnaire_id.tsid && questionnaire.id === questionnaire_id.tqid) {
									filtered.push(questionnaire.id);
								}
							});
						});

						if (filtered.length) {
							filtered = [...new Set(filtered)];
							return this.database.deleteBulk(this.tableReportsTentQuestionnaire, filtered)
								.then(() => true)
								.catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
						}
					}
				}
				return true;
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	redirectUser(dataParams) {
		let params = this.previousUrlService.routeData;
		setTimeout(() => {
			if (typeof params !== 'undefined' && typeof dataParams !== 'undefined') {
				if (params !== null && dataParams !== null) {
					if (typeof params['client_id'] !== 'undefined' && typeof dataParams['client_id'] !== 'undefined' && typeof dataParams['old_client_id'] !== 'undefined') {
						if (parseInt(dataParams['old_client_id'], 10) === parseInt(params['client_id'], 10)) {
							this.router.navigate(['/clients/' + dataParams['client_id'] + '/view']).then();
							return true;
						}
					}
					if (typeof params['calibration_id'] !== 'undefined' && typeof dataParams['calibration_id'] !== 'undefined' && typeof dataParams['old_calibration_id'] !== 'undefined') {
						if (parseInt(dataParams['old_calibration_id'], 10) === parseInt(params['calibration_id'], 10)) {
							this.router.navigate(['/inspectors/calibrations/' + dataParams['calibration_id'] + '/view']).then();
						}
					}
					if (typeof params['inventory_id'] !== 'undefined' && typeof dataParams['inventory_id'] !== 'undefined' && typeof dataParams['old_inventory_id'] !== 'undefined') {
						if (parseInt(dataParams['old_inventory_id'], 10) === parseInt(params['inventory_id'], 10)) {
							this.router.navigate(['/inspectors/inventory/' + dataParams['inventory_id'] + '/view']).then();
							return true;
						}
					}
					if (typeof params['report_id'] !== 'undefined' && typeof dataParams['report_id'] !== 'undefined') {
						if (typeof params['building_id'] !== 'undefined' && typeof dataParams['building_id'] !== 'undefined') {
							if (typeof params['building_report_id'] !== 'undefined' && typeof dataParams['building_report_id'] !== 'undefined' && typeof dataParams['old_building_id'] !== 'undefined') {
								if (parseInt(dataParams['old_building_report_id'], 10) === parseInt(params['building_id'], 10)) {
									this.router.navigate(['/reports/' + params['report_type'] + '/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports/' + dataParams['building_report_id'] + '/edit']).then();
									return true;
								}
							}
							if (typeof dataParams['old_building_id'] !== 'undefined') {
								if (parseInt(dataParams['old_building_id'], 10) === parseInt(params['building_id'], 10)) {
									this.router.navigate(['/reports/' + params['report_type'] + '/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports']).then();
									return true;
								}
							}
						}
						if (typeof dataParams['report_id'] !== 'undefined' && typeof dataParams['old_report_id'] !== 'undefined') {
							if (parseInt(dataParams['old_report_id'], 10) === parseInt(params['report_id'], 10)) {
								this.router.navigate(['/reports/' + params['report_type'] + '/' + dataParams['report_id'] + '/view']).then();
								return true;
							}
						}
					}
				}
			}
		}, 1500);
	}
}
